/**
 *
 * Navigation
 *
 */
import React, { useEffect, useState } from 'react';
import { CompanyInfo, CustType } from 'app/pages/AuthPage/slice/types';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { messages } from './messages';
import { getBuIdFromUrl, useLocalStorage, useParameter } from 'helpers';
import { selectShoppingCart } from 'app/pages/ShoppingCart/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useAccountSlice } from 'app/pages/AccountPage/slice';
import { useShoppingCartSlice } from 'app/pages/ShoppingCart/slice';
import { selectAuthUser } from 'app/pages/AuthPage/slice/selectors';
import { selectMember } from 'app/pages/AccountPage/slice/selectors';
import { LoadingProgress } from '../LoadingProgress';

import modalCloseIcon from 'img/account/icon/modalCloseIcon.svg';
import MobilePersonIcon from 'img/navigation/icon/person.svg';
import MobileAddIcon from 'img/navigation/icon/mobile_add.svg';
import MobileDashIcon from 'img/navigation/icon/mobile_dash.svg';
import MobileRightArrowLongIcon from 'img/navigation/icon/mobile_arrow_right_long.svg';
import PhoneIcon from 'img/navigation/icon/telephone.svg';
import BusinessAccountPicture from 'img/navigation/pic/corporate_banner.png';
import { Modal } from '@material-ui/core';
import { getBuConfig } from 'helpers/BuHelper';
import { paymentBu } from 'app/APIs/apiUrl';

interface Props {
  // isMobile: boolean;
  member: {
    firstName: string;
    lastName: string;
    memberType: string;
    companyInfo: CompanyInfo;
    [key: string]: any;
  };
  user: {
    accessToken: string;
    custName: string;
    custId: string;
    custType: CustType;
    [key: string]: any;
  };
  handleBeforeGoToNextPath: (path) => void;
  closeMenu: () => void;
  nextLanguage: LangProp;
  currentLanguage: LangProp;
  updateLanguage: (lang: LangProp) => void;
  LanguageList: LangProp[];
  // isAccountFetching: boolean;
}

interface LangProp {
  key: string;
  title: string;
  longTitle: string;
  flagSrc: string;
}

enum EnumMenuPage {
  DEFAULT,
  ACCOUNT,
}

export function MobileNavigationMenu(props: Props) {
  const {
    member,
    user,
    handleBeforeGoToNextPath,
    closeMenu,
    nextLanguage,
    updateLanguage,
    currentLanguage,
    LanguageList,
  } = props;
  const {
    getAsset,
    locationUrl,
    supportEmail,
    supportPhoneNumber,
    isEnableNavigationMobileQueryCard,
    isEnableNavigationMobileLocation,
    isEnableNavigationMobileContactUs,
    isEnableNavigationMobileCorporate,
    isEnableNavigationMobileProfile,
    isEnableNavigationMobileImport,
  } = getBuConfig();
  const { AccountUpdateProfileErrorIcon, NavEmailIcon } = getAsset();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { custType } = useParameter();
  const { actions } = useAccountSlice();
  const { actions: cartActions } = useShoppingCartSlice();
  const shoppingCart = useSelector(selectShoppingCart);
  const { isActivateAccount } = useSelector(selectMember);
  const { getItemLs, clearLocalStorage } = useLocalStorage();

  const [isShowLogoOutError, setIsShowLogoOutError] = useState<boolean>(false);
  const [logoOutError, setLogoOutError] = useState<string>('');
  const [isLogoutFetching, setIsLogoutFetching] = useState<boolean>(false);
  const [isMobileContactUs, setIsMobileContactUs] = useState<boolean>(false);
  const [modalLanguage, setModalLanguage] = useState<boolean>(false);
  const isOpenAccount = document
    .getElementById('mobile-navigation-toggle')
    ?.classList.contains('open-account');
  const [currentPage, setCurrentPage] = useState(
    isOpenAccount ? EnumMenuPage.ACCOUNT : EnumMenuPage.DEFAULT,
  );

  useEffect(() => {
    // ZendeskAPI('webWidget', 'hide');
    // return () => {
    //   ZendeskAPI('webWidget', 'show');
    // };
    const toggleMenuElement = document.getElementById(
      'mobile-navigation-toggle',
    );
    if (toggleMenuElement && isOpenAccount) {
      toggleMenuElement.classList.remove('open-account');
    }
  }, []);

  const clearShoppingCart = () => {
    if (shoppingCart.cartItems.length > 0) {
      dispatch(cartActions.clearAll());
    }
  };

  const logoutSuccess = () => {
    if (custType.isLogin) {
      clearShoppingCart();
    }
    clearLocalStorage();
    dispatch(actions.logout());
  };
  const logoutFailed = () => {
    setIsLogoutFetching(false);
    setLogoOutError(t(...messages.logoutError()));
    setIsShowLogoOutError(true);
  };

  useEffect(() => {
    if (!user) {
      setCurrentPage(EnumMenuPage.DEFAULT);
    }
  }, [currentPage]);

  const noNeedQueryCardList = ['Carrefour'];
  const noNeedLocationList = ['Carrefour'];
  const noNeedContactUsList = ['Carrefour'];

  const DefaultMenuPage = () => {
    return (
      <>
        {paymentBu !== "UAE"
          ? (user?.accessToken && member ? (
            <div
              className="d-flex justify-content-between align-items-center"
              onClick={() => {
                setCurrentPage(EnumMenuPage.ACCOUNT);
              }}
            >
              <h4>{t(...messages.account())}</h4>
              <img src={MobileRightArrowLongIcon} alt="arrow-right-long" />
            </div>
          ) : (
            <button
              className="btn btn-light btn-primary d-flex"
              onClick={() => {
                handleBeforeGoToNextPath('/auth/login');
              }}
            >
              <img src={MobilePersonIcon} alt="login" />
              {t(...messages.loginAndRegister())}
            </button>
          )) : null
        }
        {!(custType.isSalesman || location.pathname.includes('salesman')) &&
          isEnableNavigationMobileQueryCard && (
            <div
              className=""
              onClick={() => {
                history.push('/query');
              }}
            >
              <h4>{t(...messages.queryCard())}</h4>
            </div>
          )}
        {isEnableNavigationMobileLocation && (
          <a href={locationUrl} target="blank" onClick={() => closeMenu()}>
            {t(...messages.location())}
          </a>
        )}
        <div
          className=""
          onClick={() => {
            history.push('/faq');
          }}
        >
          <h4>{t(...messages.faqs())}</h4>
        </div>
        {isEnableNavigationMobileContactUs && (
          <div className="mobile-container-contact-us">
            <div
              className={`d-flex justify-content-between align-items-center ${
                isMobileContactUs ? 'open' : ''
              }`}
              onClick={() => setIsMobileContactUs(prev => !prev)}
            >
              <h4>{t(...messages.contactUs())}</h4>
              <img
                src={isMobileContactUs ? MobileDashIcon : MobileAddIcon}
                alt="plus-minus"
              />
            </div>
            {isMobileContactUs && (
              <div className="d-flex contact-us-content-container">
                <a
                  href={`mailto:${supportEmail}`}
                  className="contact-us-content"
                >
                  <div className="upper-content">
                    <img src={NavEmailIcon} alt="Email" />
                  </div>
                  <div className="bottom-content">{supportEmail}</div>
                </a>
                <a
                  href={`tel:${supportPhoneNumber}`}
                  className="contact-us-content"
                >
                  <div className="upper-content">
                    <img src={PhoneIcon} alt="Phone" />
                  </div>
                  <div className="bottom-content">{supportPhoneNumber}</div>
                </a>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const noNeedCorporateList = ['Carrefour'];
  const noNeedProfileList = ['Carrefour'];
  const noNeedImportList = ['Carrefour'];

  const AccountMenuPage = () => {
    return (
      <>
        <div className="account-container">
          <div
            className="d-flex navigation-back"
            onClick={() => setCurrentPage(EnumMenuPage.DEFAULT)}
          >
            <img src={MobileRightArrowLongIcon} alt="arrow-back" />
            {t(...messages.account())}
          </div>
          <div className="account-overview">
            <span>
              {custType.isB2CUser
                ? isActivateAccount
                  ? t(...messages.corporateAccount())
                  : t(...messages.individualAccount())
                : custType.isDefinitelyB2B
                ? t(...messages.businessAccount())
                : t(...messages.salesmanAccount())}
            </span>
            {custType.isB2B ? member.companyInfo.companyName : member.firstName}
          </div>
          {isEnableNavigationMobileCorporate && (
            <Link to="/account/corporate">
              <img
                className="corporate-banner"
                src={BusinessAccountPicture}
                alt="business-account-banner"
              />
            </Link>
          )}
        </div>
        {isEnableNavigationMobileProfile && (
          <Link to="/account/profile">
            <h4>{t(...messages.profileSetting())}</h4>
          </Link>
        )}
        {isEnableNavigationMobileImport && (
          <Link to="/account/import">
            <h4>{t(...messages.import())}</h4>
          </Link>
        )}
        <Link to="/account/cards">
          <h4>{t(...messages.myCards())}</h4>
        </Link>
        <Link to="/account/order">
          <h4>{t(...messages.orderHistory())}</h4>
        </Link>
        {/* <Link to="/account/inbox">
          <h4>{t(...messages.inbox())}</h4>
        </Link> */}
        <Link
          className="logout-btn"
          to={custType.isSalesman ? '/auth/salesman' : '/'}
          onClick={e => {
            setIsLogoutFetching(true);
            const oauthToken = getItemLs('refreshToken');
            if (oauthToken) {
              e.preventDefault();
              dispatch(
                actions.getOauthLogout({
                  refreshToken: oauthToken,
                  accessToken: user.accessToken,
                  successCb: () => {
                    logoutSuccess();
                    window.location.href = `${window.location.protocol}//${
                      window.location.host
                    }/${getBuIdFromUrl(true)}/`;
                  },
                  failedCb: () => {
                    logoutFailed();
                  },
                }),
              );
            } else {
              logoutSuccess();
              window.location.href = `${window.location.protocol}//${
                window.location.host
              }/${getBuIdFromUrl(true)}/`;
            }
          }}
        >
          <h4>{t(...messages.logout())}</h4>
        </Link>
      </>
    );
  };

  const handleClosePopup = () => setIsShowLogoOutError(false);

  const DisplayCurrentPage = () => {
    switch (currentPage) {
      case EnumMenuPage.ACCOUNT:
        return AccountMenuPage();
      case EnumMenuPage.DEFAULT:
      default:
        return DefaultMenuPage();
    }
  };

  return isLogoutFetching ? (
    <div className="loading-full-screen">
      <LoadingProgress />
    </div>
  ) : (
    <>
      <div className="mobile-menu-container d-flex flex-column">
        <DisplayCurrentPage />
        {/* <button
          className="btn btn-light btn-primary languageBtn d-flex mobile-login-btn"
          onClick={() => setModalLanguage(true)}
        >
          <img src={currentLanguage.flagSrc} alt="langFlag" />
          {currentLanguage.title}
        </button> */}
        {isShowLogoOutError && logoOutError && (
          <div
            className="modal-warn account-update-profile"
            onClick={handleClosePopup}
          >
            <div
              className="modal-content d-flex flex-column align-items-center modal-container"
              onClick={e => e.stopPropagation()}
            >
              <div
                className="modal-close"
                onClick={() => setIsShowLogoOutError(false)}
              >
                <img src={modalCloseIcon} alt="close modal" />
              </div>
              <div>
                <img src={AccountUpdateProfileErrorIcon} alt="icon" />
              </div>
              <h3>{t(...messages.logoutFail())}</h3>
              {<h5>{logoOutError}</h5>}

              <button
                type="button"
                className="btn btn-light a-qc-block-yes-btn"
                onClick={handleClosePopup}
              >
                {t(...messages.tryAgain())}
              </button>
            </div>
          </div>
        )}
        <Modal
          open={modalLanguage}
          onClose={() => setModalLanguage(false)}
          className="modal-warn"
          BackdropProps={{
            invisible: true,
          }}
        >
          <div className="modal-content container-languange-selector d-flex flex-column">
            <div
              className="modal-close"
              onClick={() => setModalLanguage(false)}
            >
              <img src={modalCloseIcon} alt="close modal" />
            </div>
            <div className="title">{t(...messages.chooseLanguage())}</div>
            {LanguageList.map(e => (
              <div
                className="container-selects d-flex align-items-center"
                onClick={() => updateLanguage(e)}
              >
                <div
                  className={`container-selects__radio ${
                    currentLanguage.key === e.key ? 'selected' : ''
                  }`}
                />
                <div className="container-selects__description d-flex align-items-center">
                  <img src={e.flagSrc} alt={`lang-${e.key}`} />
                  {e.longTitle} - {e.title}
                </div>
              </div>
            ))}
          </div>
        </Modal>
      </div>
    </>
  );
}
