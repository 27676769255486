import { PspConfig } from "types/common";

const VERSION = 'v2';
const BASE_URL = 'https://giftcards.mafcarrefour.com/gc-thegc-rgw';
const PAYMENT_URL = 'https://giftcards.mafcarrefour.com/gc-affinity-payment-gateway';
const locationUrl = 'https://giftcards.mafcarrefour.com';
const paymentBu: string = "UAE";
const buLanguageList = ['en', 'ar'];
const pspConfig: PspConfig = {
  upstreamPay: {
    environment: 'sandbox',
    paymentBaseUrl: 'https://api.preprod.upstreampay.com',
  },
}
const redirectUrlIfNotLogin = '';
const samlLoginUrl = '';
export {
  VERSION,
  BASE_URL,
  PAYMENT_URL,
  locationUrl,
  paymentBu,
  buLanguageList,
  pspConfig,
  redirectUrlIfNotLogin,
  samlLoginUrl,
};
