import React from 'react';

interface Props {
  __HTML: string;
}

/**
 * footer的item是由config.ts裡的moreInfoList這個array map出來的
 * 而每一個item所render出的HTML內容，是由對應的字串決定
 * 該字串儲存於html.ts裡，在切換站台時跟著切換 (指令寫在對應的script中)
 *
 * 同時_footer-pages.scss 與 對應的footerPagesImg資料夾，也會跟著站台切換內容
 */

export default function FooterPagesGeneralBg({ __HTML }: Props) {
  return (
    <div className="statement-block">
      <div
        className="statement-block__container"
        dangerouslySetInnerHTML={{ __html: __HTML }}
      ></div>
    </div>
  );
}
