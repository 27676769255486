import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { parseISO, format, Locale } from 'date-fns';
import { reduce, forOwn, map, get } from 'lodash';
import LibPhoneNumber from 'google-libphonenumber';

import { useAuthSlice } from 'app/pages/AuthPage/slice';
import { selectAuthUser } from 'app/pages/AuthPage/slice/selectors';
import { selectCustomerType } from 'app/pages/SalesmanBuyingSelfPage/slice/selectors';
import { selectDecimalDigits } from 'app/pages/HomePage/slice/selectors';
import {
  selectCurrency,
  selectIsEnableGuestMode,
  selectMemberShip,
  selectDiscountType,
  selectPaymentType,
  selectCardMadeTaxRate,
  selectShippingTaxRate,
  selectAdditionalProducts,
  selectPspProvider,
} from 'app/pages/HomePage/slice/selectors';

import { fetchOAuth, fetchPspInitToken, fetchPspInit } from 'app/APIs';
import {
  MemberShipType,
  TemplateKind,
  B2BDiscountType,
  PaymentMethodType,
  Country,
  NeedFixLinePath,
  OrderDraft,
  Coupon,
  UploadFilterFile,
  SalesmanCustomerType,
} from 'types/common';
import { encryption, decryption } from './crypto';
import { CustType } from 'app/pages/AuthPage/slice/types';
import { useTranslation } from 'react-i18next';
import { enUS, es, fr } from 'date-fns/locale';
import NumberPrompt from 'inquirer/lib/prompts/number';
import { CookiesKey, LocalStorageKey, SessionStorageKey } from './constant';
import { DefaultBu } from './buList/BuDefault';
import { buList } from './buList';
import { getBuConfig } from './BuHelper';
import { paymentBu } from 'app/APIs/apiUrl';

export const getBuIdFromUrl = isDefaultBu => {
  const defaultPath = '';
  const pathName = defaultPath
    ? window.location.pathname.split(defaultPath)[1]
    : window.location.pathname;
  const buIdUrl = pathName.split('/').filter(e => !!e)[0];
  const buIdFound = buList.find(e => e.buId === buIdUrl?.toUpperCase());
  const isUsingOldPath = buIdUrl ? isLowerCase(buIdUrl) : true;
  const verifiedBuId = !!buIdFound
    ? buIdFound.buId
    : isDefaultBu
    ? new DefaultBu().buId
    : '';

  // when buid is not found from list and buid on url exist, replace url to root
  if (!!buIdUrl && !buIdFound && !isUsingOldPath) {
    window.location.replace('/');
  } else if (!!defaultPath && !window.location.pathname.includes(defaultPath)) {
    window.location.replace(defaultPath);
  }

  return verifiedBuId.toUpperCase();
};

function isLowerCase(str: string) {
  let isLowerCase = true;
  for (let i = 0; i < str.length; i++) {
    if (str[i] !== str[i].toLowerCase() && str[i] === str[i].toUpperCase()) {
      isLowerCase = false;
      break;
    }
  }
  return isLowerCase;
}

//加法
export const floatAdd = (arg1: number, arg2: number): number => {
  let r1, r2, m;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  return (floatMultiply(arg1, m) + floatMultiply(arg2, m)) / m;
};

// fixed point arithmetic (Mod, %)
export const floatMod = (arg1: number, arg2: number): number => {
  let r1, r2, m;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  return (floatMultiply(arg1, m) % floatMultiply(arg2, m)) / m;
};

export const floatSubtraction = (arg1: number, arg2: number): number => {
  let r1, r2, m;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  return (floatMultiply(arg1, m) - floatMultiply(arg2, m)) / m;
};

const useFrSeparatorList = ['FR'];
export const getValueRegex = () => {
  const { defaultCountry } = getBuConfig();
  return useFrSeparatorList.includes(defaultCountry)
    ? /^[0-9]+[,]?[0-9]*$/
    : /^[0-9]+[.]?[0-9]*$/;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const generateOrderId = () => v4();

export const discountAmount = (amount: number, discount: number): number => {
  return Number(floatMultiply(amount, (100 - discount) / 100).toFixed(2));
};

export const backendDiscountAmount = (
  amount: number,
  discount: number,
): number => {
  return Number(
    (amount - customizeFixedFunction((amount * discount) / 100)).toFixed(2),
  );
};

export const customizeFixedFunction = (num: number) => {
  const numStr = String(num);
  if (!numStr.includes('.')) return num;
  const nowInteger = numStr.split('.')[0];
  const nowDecimal = numStr.split('.')[1];
  const newDecimal = numStr.split('.')[1].substr(0, 2);

  if (nowDecimal.length < 3) return num;

  if (Number(nowDecimal.charAt(2)) >= 5) {
    const addDecimal = Number(newDecimal) + 1;
    if (addDecimal == 100) {
      const addInteger = Number(nowInteger) + 1;
      return Number(addInteger);
    } else if (addDecimal < 10) {
      return Number(nowInteger + '.0' + addDecimal);
    } else {
      return Number(nowInteger + '.' + addDecimal);
    }
  } else {
    return Number(nowInteger + '.' + newDecimal);
  }
};

export const checkDiscountAmount = (
  amount: number,
  discount: number,
  qty: number,
): number => {
  const totalAmount = Number(
    customizeFixedFunction(floatMultiply(amount, qty)),
  );
  const totalDiscount = Number(
    customizeFixedFunction(floatMultiply(totalAmount, discount / 100)),
  );
  return floatSubtraction(totalAmount, totalDiscount);
};

const getDiscountedAmount = (
  amount: number,
  discount: number,
  qty: number,
): number => {
  return Number(
    Number(floatMultiply(amount, qty).toFixed(2)) -
      Number(
        floatMultiply(
          Number(floatMultiply(amount, qty).toFixed(2)),
          discount / 100,
        ).toFixed(2),
      ),
  );
};

export const oneOrPluralOrder = (order, discount) => {
  if (order.data.uploadFileList) {
    return oneByOneDiscountAmount(order.data.uploadFileList, discount);
  } else {
    return checkDiscountAmount(
      get(order, 'data.amount', 0),
      discount,
      get(order, 'data.qty', 0),
    );
  }
};

export const oneByOneDiscountAmount = (
  amountList: Array<UploadFilterFile> | undefined,
  discount: number,
): number => {
  if (amountList === undefined) {
    return 0;
  }
  let total = 0;
  amountList.forEach(item => {
    total += getDiscountedAmount(item.amount, discount, item.qty);
  });
  return Number(total.toFixed(2));
};

export const floatMultiply = (arg1: number, arg2: number): number => {
  if (!arg1 || !arg2) {
    return 0;
  }
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();
  try {
    m += s1.split('.')[1].length;
  } catch (e) {}
  try {
    m += s2.split('.')[1].length;
  } catch (e) {}
  return customizeFixedFunction(
    (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
      Math.pow(10, m),
  );
};

export const floatDivision = (arg1: number, arg2: number): number => {
  if (!arg1 || !arg2) {
    return 0;
  } else if (arg2 == 1) {
    return arg1;
  }
  let s1 = arg1.toString();
  let s2 = arg2.toString();
  try {
    const index1 = s1.split('.')[1].length;
    const index2 = s2.split('.')[1].length;
    const max = Math.max(index1, index2);
    s1 = floatMultiply(arg1, max).toString();
    s2 = floatMultiply(arg2, max).toString();
  } catch (e) {}
  return Number((Number(s1) / Number(s2)).toFixed(2));
};

export const formatTime = (time: string) =>
  format(parseISO(new Date(time).toISOString()), 'yyyy/MM/dd - HH:mm');

export const createMarkup = (content: string) => {
  return { __html: content };
};

export const getHomePage = (type: string) => {
  return type === CustType.B2B
    ? '/b2b'
    : type === CustType.Salesman
    ? '/salesman'
    : '/';
};
/**
 * Increment / Decrement count which will restart
 * the count after reaching 0 (decrement)
 * OR the maximum count (increment)
 *
 * @param intialCount starting count
 * @param totalCount maximum count
 * @param isDecrement if not defined, the count is incremental
 * @returns number (current count)
 */
export const handleLoopCounting = (
  intialCount: number,
  totalCount: number,
  isDecrement?: boolean,
) => {
  if (isDecrement) {
    if (intialCount <= 0) return totalCount;
    else return intialCount - 1;
  } else {
    if (intialCount >= totalCount) return 0;
    else return intialCount + 1;
  }
};

//Regex
export const isOnlyLetter = value => {
  const regex = /^(?!.*[0-9*"'~`!@#$%^&()_={}[\]:;,.<>+\\\|/?-])/;
  return regex.test(value);
};

export const isOnlyAddressFormat = value => {
  const regex = /^(?!.*[*"'~`!@#$%^&()_={}[\]:;<>+\\\|/?])/;
  return regex.test(value);
};

export const handleLetterFormatOnChange = (e, fieldName, setFieldValue) => {
  if (isOnlyLetter(e.target.value)) setFieldValue(fieldName, e.target.value);
};

export const handleAddressFormatOnChange = (e, fieldName, setFieldValue) => {
  if (isOnlyAddressFormat(e.target.value))
    setFieldValue(fieldName, e.target.value);
};

type letterPricesType = {
  name: string;
  price: number;
  qty: number;
  totalPrice: number;
  totalDiscount?: number;
};

//hook
export const useCountLetterAmount = (orders, letterTypes) => {
  const { custType } = useParameter();
  const [letterPrices, setLetterPrices] = useState<letterPricesType[]>([]);

  const countTotalAmount = amounts =>
    reduce(
      amounts,
      (acc, currentAmount, key) => {
        return acc + currentAmount.totalPrice;
      },
      0,
    );

  useEffect(() => {
    if (orders.length > 0 && letterTypes.length > 0) {
      if (orders[0].data.kind !== TemplateKind.Digital) {
        let filterLetterByType: any[] = [];
        for (let i = 0; i < letterTypes.length; i++) {
          const { id, methodName, price } = letterTypes[i];
          let addressList: any[] = [];
          if (orders[0].data.originalOrders) {
            /*
             * EXCEL/CSV上傳之後 submit表單的同時也會在form data裡面寄一份original orders的data
             * 再依照original orders裡面的資訊組好完整的address，用來計算運費
             */
            if (orders[0].data.letterTypeId.toString() === id.toString()) {
              orders[0].data.originalOrders.forEach(el => {
                let receiverFullAddress = `${el.receiverCountryCode}${el.receiverZipCode}${el.receiverCity}${el.receiverAddress}`.toUpperCase();
                if (!addressList.includes(receiverFullAddress)) {
                  addressList.push(receiverFullAddress);
                }
              });
            }
          } else {
            /*
             * 照原邏輯來看，這段的邏輯是沒辦法正確判斷letterPrice的qty
             * 因為 filterOrder.data.receiverCountryCode / filterOrder.data.receiverZipCode 等等.. 都是空值
             * 因為 用EXCEL/CSV上傳資料，會造成orders資料中只有單一地址(這部分的邏輯耦合性高)，造成信件寄送費沒辦法正確計算
             */
            if (custType.isSalesman) {
              addressList = orders.filter(order => {
                return order.data.letterTypeId.toString() === id.toString();
              });

              let totalLetterPrice = 0;
              let totalLetterDiscount = 0;
              for (let i = 0; i < addressList.length; i++) {
                const addressData = addressList[i].data;
                if (
                  addressData.salesmanLetterType &&
                  addressData.salesmanLetterValue
                ) {
                  if (addressData.salesmanLetterType === 'P') {
                    const nowLetterPrice = customizeFixedFunction(
                      addressData.letterPrice -
                        customizeFixedFunction(
                          addressData.letterPrice *
                            (addressData.salesmanLetterValue / 100),
                        ),
                    );
                    totalLetterPrice += nowLetterPrice;
                    totalLetterDiscount += customizeFixedFunction(
                      addressData.letterPrice - nowLetterPrice,
                    );
                  } else if (addressData.salesmanLetterType === 'V') {
                    const nowLetterPrice = Number(
                      addressData.salesmanLetterValue,
                    );
                    totalLetterPrice += nowLetterPrice;
                    totalLetterDiscount += customizeFixedFunction(
                      addressData.letterPrice - nowLetterPrice,
                    );
                  }
                } else {
                  totalLetterPrice += addressData.letterPrice;
                }
              }
              if (totalLetterPrice > 0) {
                filterLetterByType.push({
                  name: methodName,
                  price,
                  qty: addressList.length,
                  totalPrice: totalLetterPrice,
                  totalDiscount: totalLetterDiscount,
                });
              }
            } else {
              addressList = orders
                .filter(order => {
                  return order.data.letterTypeId.toString() === id.toString();
                })
                .map(filterOrder => {
                  return (
                    String(filterOrder.data.receiverCountryCode).trim() +
                    String(filterOrder.data.receiverZipCode).trim() +
                    String(filterOrder.data.receiverCity).trim() +
                    String(filterOrder.data.receiverAddress).trim() +
                    (filterOrder.data.receiverAddress2.trim()
                      ? ',' + String(filterOrder.data.receiverAddress2).trim()
                      : '')
                  ).toUpperCase();
                })
                .filter((element, index, arr) => {
                  return arr.indexOf(element) === index;
                });
            }
          }

          if (!custType.isSalesman) {
            const totalLetterPrice = price * addressList.length;
            if (totalLetterPrice > 0) {
              filterLetterByType.push({
                name: methodName,
                price,
                qty: addressList.length,
                totalPrice: totalLetterPrice,
              });
            }
          } else if (orders[0].data.originalOrders && custType.isSalesman) {
            // salesman upload file
            let totalLetterPrice = 0;
            if (
              orders[0].data.salesmanLetterType &&
              orders[0].data.salesmanLetterValue
            ) {
              if (orders[0].data.salesmanLetterType === 'P') {
                totalLetterPrice = customizeFixedFunction(
                  customizeFixedFunction(
                    ((100 - orders[0].data.salesmanLetterValue) / 100) * price,
                  ) * addressList.length,
                );
              } else if (orders[0].data.salesmanLetterType === 'V') {
                totalLetterPrice = customizeFixedFunction(
                  orders[0].data.salesmanLetterValue * addressList.length,
                );
              }
            } else {
              totalLetterPrice = price * addressList.length;
            }
            if (totalLetterPrice > 0) {
              filterLetterByType.push({
                name: methodName,
                price,
                qty: addressList.length,
                totalPrice: totalLetterPrice,
              });
            }
          }
        }
        const filterLetterAmount = countTotalAmount(filterLetterByType);
        const currentAmount = countTotalAmount(letterPrices);
        if (filterLetterAmount !== currentAmount || custType.isSalesman) {
          setLetterPrices(filterLetterByType);
        }
      } else {
        if (letterPrices.length > 0) {
          setLetterPrices([]);
        }
      }
    } else {
      setLetterPrices(pre => (pre = []));
    }
  }, [letterTypes, orders]);

  return {
    letterPrices,
    totalLetterAmount: countTotalAmount(letterPrices),
  };
};

export function calculateCoupon(orders: OrderDraft[], couponList: Coupon[]) {
  let currentCoupon: Coupon[] = [];
  const tempItemsBalance: {
    [key: string]: { amount: number; amountLeft: number };
  } = {};
  orders.forEach(order => {
    const total = order.data.qty * order.data.amount;
    const sameCodeAmount = tempItemsBalance[order.data.itemCode]?.amount ?? 0;
    const sameCodeAmountleft =
      tempItemsBalance[order.data.itemCode]?.amountLeft ?? 0;
    tempItemsBalance[order.data.itemCode] = {
      amount: sameCodeAmount + total,
      amountLeft: sameCodeAmountleft + total,
    };
  });
  if (couponList.length && orders.length) {
    //Sort CouponList from more specific (Cards from) to more generic card (if suitedItemCode is null then it is generic and set value to 999)
    let sortedCouponList = couponList
      .slice()
      .sort((a, b) =>
        a.suitedItemCode?.length === b.suitedItemCode?.length
          ? 0
          : ((a.suitedItemCode?.length ?? 999) >
              (b.suitedItemCode?.length ?? 999) &&
              1) ||
            -1,
      );

    //Adding Logic to calculate subpayment coupon
    const resultCoupon =
      sortedCouponList.length > 0
        ? sortedCouponList.map(coupon => {
            let remainingBalance = coupon.cardBalance; // Coupon's remaining balance
            if (!!coupon.suitedItemCode?.length) {
              // Subpayment Coupon
              coupon.suitedItemCode.forEach(itemCode => {
                // Find suitable code(s) from the subpayment coupon and calculate the amount deducted from coupon
                const item = orders.find(
                  order => order.data.itemCode === itemCode.itemCode,
                );
                if (item && tempItemsBalance[item.data.itemCode]) {
                  const currItemBalance = tempItemsBalance[item.data.itemCode]; // Find the item's balance in hashtable using itemCode
                  const result = remainingBalance - currItemBalance.amountLeft; // deduct coupon's balance with item's amount left to be paid
                  if (result < 0) {
                    /*
                     * Item's amount left is higher than the card balance:
                     * 1. card Balance become 0
                     * 2. set the amount left to be paid converted with result (convert into positive)
                     */
                    remainingBalance = 0;
                    currItemBalance.amountLeft = Math.abs(result);
                  } else {
                    remainingBalance = result;
                    currItemBalance.amountLeft = 0;
                  }
                }
              });
            } else {
              // Generic Coupon
              // itemsBalance.total -= remainingBalance;
              forOwn(tempItemsBalance, value => {
                /*
                 * Action : Iterate through hashtable
                 * Objective : find any items in the cart and deduct the amountLeft with the coupon's balance
                 */
                const result = remainingBalance - value.amountLeft;
                if (result > 0) {
                  value.amountLeft = 0;
                  remainingBalance = result;
                } else {
                  value.amountLeft = Math.abs(result);
                  remainingBalance = 0;
                }
              });
            }
            return {
              ...coupon,
              appliedAmount: Math.abs(coupon.cardBalance - remainingBalance),
              remainingBalance,
            };
          })
        : [];
    currentCoupon = resultCoupon;
    // console.log("CURRENT COUPON, ", resultCoupon);
  } else {
    currentCoupon = couponList;
  }

  return {
    cartBalance: tempItemsBalance,
    coupon: currentCoupon,
  };
}

export const useCountCouponAmount: (
  couponList: Coupon[],
  orders: OrderDraft[],
) => {
  couponList: Coupon[];
  remainingAmount: number;
  couponAmountApplied: number;
} = (couponList, orders) => {
  const [currentCoupon, setCurrentCoupon] = useState<Coupon[]>([]);
  /*
   * HashTable for Items Balance with itemcode as the key
   * Desc:
   * Store Information on each item's amount left to be paid after deducted by generic & subpayment coupon
   */
  const [itemsBalance, setItemsBalance] = useState<{
    [key: string]: { amount: number; amountLeft: number };
  }>({});
  useEffect(() => {
    // Initiate HashTable by iterating through orders
    const { coupon, cartBalance } = calculateCoupon(orders, couponList);
    setCurrentCoupon(coupon);
    setItemsBalance(cartBalance);
  }, [couponList, orders]);

  const countTotalAfterCoupon = () => {
    // Iterate through hashTable to calculate the remaining amount needed to be paid
    let remainingAmount = 0;
    forOwn(itemsBalance, value => {
      remainingAmount += value.amountLeft;
    });
    // console.log("countTotalAfterCoupon remainingAmount", remainingAmount)
    return remainingAmount;
  };

  const countTotalCouponAmount = () => {
    const result = reduce(
      currentCoupon,
      (total, coupon) => {
        return total + get(coupon, 'appliedAmount', 0);
      },
      0,
    );
    // console.log("countTotalCouponAmount", result)
    return result;
  };
  return {
    couponList: currentCoupon,
    remainingAmount: countTotalAfterCoupon(),
    couponAmountApplied: countTotalCouponAmount(),
  };
};

export const useCookie = () => {
  const buId = getBuIdFromUrl(true);

  const setCookie = (
    cookieName: string,
    cookieValue: string,
    exHour: number,
    isEncryption: boolean,
  ) => {
    if (!buId) return;
    const currentDate = new Date();
    const timeZoneOffset = currentDate.getTimezoneOffset() * 60000;
    currentDate.setTime(
      currentDate.getTime() + exHour * 60 * 60 * 1000 - timeZoneOffset,
    );
    const expires = `expires=${currentDate.toUTCString()}`;
    const localDomain = window.location.hostname;
    const cookieDomain = `domain=${localDomain}`;
    const path = `path=/`;
    document.cookie = `${buId}_${cookieName}=${
      isEncryption ? encryption(cookieValue) : cookieValue
    };${cookieDomain};${path};${exHour ? expires : ''}`;
  };

  const deleteCookie = (cookieName: string, isMigration?: boolean) => {
    if (!buId) return;
    const currentDate = new Date();
    const timeZoneOffset = currentDate.getTimezoneOffset() * 60000;
    currentDate.setTime(
      currentDate.getTime() + 0 * 60 * 60 * 1000 - timeZoneOffset,
    );
    const expires = `expires=${currentDate.toUTCString()}`;
    const localDomain = window.location.hostname;
    const cookieDomain = `domain=${localDomain}`;
    const path = `path=/`;
    document.cookie = `${
      isMigration ? cookieName : `${buId}_${cookieName}`
    }=${` ;${cookieDomain};${path};${expires}`}`;
  };

  const getCookie = (
    cookieName: string,
    isDecrypt: boolean,
    isMigration?: boolean,
  ) => {
    if (!buId) return '';
    const name = isMigration ? `${cookieName}=` : `${buId}_${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    let cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return isDecrypt
          ? decryption(cookie.substring(name.length, cookie.length))
          : cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  };

  const migrateOldFormat = () => {
    for (const key of CookiesKey) {
      const currOldCookie = getCookie(key, false, true); // get old cookies whole value (including exp date)
      deleteCookie(key, true); // deleting old format cookie
      if (!!currOldCookie) {
        setCookie(key, currOldCookie, 1, false);
      }
    }
  };

  return {
    setCookie,
    getCookie,
    migrateOldFormatCookie: migrateOldFormat,
    deleteCookie,
  };
};

export const useLoginInitialState = () => {
  const [isRememberEmail, setIsRememberEmail] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<{
    email: string;
    password: string;
    receiverMobileNo: string;
    receiverMobileAreaCode: string;
    passwordMobile: string;
  }>({
    email: '',
    password: '',
    receiverMobileNo: '',
    receiverMobileAreaCode: '',
    passwordMobile: '',
  });
  const { setCookie, getCookie } = useCookie();

  const rememberEmail = (email: string) => {
    setCookie('LE', email, 365 * 24, true);
  };

  const rememberMobile = (code: string, number: string) => {
    setCookie('LMC', code, 365 * 24, true);
    setCookie('LMN', number, 365 * 24, true);
  };

  const saveEmail = getCookie('LE', true);
  const saveMobileCode = getCookie('LMC', true);
  const saveMobileNumber = getCookie('LMN', true);

  useEffect(() => {
    if (saveEmail && !initialValues.email) {
      setInitialValues(pre => ({ ...pre, email: saveEmail }));
    }
  }, [initialValues, saveEmail]);

  useEffect(() => {
    if (
      saveMobileNumber &&
      !initialValues.receiverMobileNo &&
      !initialValues.receiverMobileAreaCode
    ) {
      setInitialValues(pre => ({
        ...pre,
        receiverMobileNo: saveMobileNumber,
        receiverMobileAreaCode: saveMobileCode,
      }));
    }
  }, [initialValues, saveMobileCode, saveMobileNumber]);

  useEffect(() => {
    return () => {
      if (saveEmail && !isRememberEmail) {
        setCookie('LE', '', 0, false);
      }
    };
  }, [saveEmail, isRememberEmail]);

  return {
    rememberEmail,
    rememberMobile,
    setIsRememberEmail,
    initialValues,
    isRememberEmail,
  };
};

export const usePspInfo = (txnCurrency?: string) => {
  const { setCookie } = useCookie();
  const { defaultCurrency } = getBuConfig();

  const FORM_DATA = {
    txnCurrency: txnCurrency || defaultCurrency,
    isVoid: 'N',
    mobileNo: '+8869123456789',
    buId: getBuIdFromUrl(true),
  };

  const getPspInitToken = async () => {
    try {
      const response = await fetchPspInitToken();
      if (!response.success) {
        throw response.data;
      }
      setCookie('csrfToken', response.data.token, 0, false);
      console.log('CSRFTOKEN USEPSPHOOK', response.data.token);
    } catch (error) {
      console.warn(error);
    }
  };

  const getPspRedirectUrl = async ({
    hostName,
    paymentMethod,
    txnAmount,
    email = '',
  }) => {
    try {
      await getPspInitToken();
      const response = await fetchPspInit({
        ...FORM_DATA,
        email,
        hostName,
        paymentMethod,
        txnAmount: txnAmount || '',
      });
      if (!response.success) {
        throw response.data;
      }

      if (response.data.error?.message) {
        throw response.data.error;
      }

      window.location.href = response.data.redirectUrl;
    } catch (error) {
      console.warn(error);
    }
  };

  return { getPspRedirectUrl };
};

export const useCountDownTime = (time, timeEndCallBack?) => {
  const [isStartCount, setIsStartCount] = useState<boolean>(false);
  const [countTime, setCountTime] = useState<number>(time);

  const toggleIsStartCount = () =>
    setIsStartCount(preIsStartCount => !preIsStartCount);

  useEffect(() => {
    if (isStartCount) {
      const startCountTime = setTimeout(
        () => setCountTime(preCountTime => preCountTime - 1),
        1000,
      );
      if (countTime === 0) {
        clearTimeout(startCountTime);
        timeEndCallBack && timeEndCallBack();
        toggleIsStartCount();
        setCountTime(time);
      }
    }

    if (!isStartCount && countTime !== time) {
      setCountTime(time);
    }
  }, [isStartCount, countTime]);

  return {
    countTime,
    toggleIsStartCount,
    isStartCount,
  };
};

export const useCountDownWithDate = time => {
  const [countDown, setCountDown] = useState<number>(time);
  const [isStart, setIsStart] = useState<boolean>(false);

  const setCountDownWithDate: (timeStamp: number) => void = timeStamp => {
    const currentDate = new Date().getTime();
    const currentCountDown = Math.floor((timeStamp - currentDate) / 1000);
    setCountDown(currentCountDown);
  };

  const toggleIsStart = () => setIsStart(preIsStart => !preIsStart);

  useEffect(() => {
    if (countDown > 0 && isStart) {
      setTimeout(() => {
        setCountDown(0);
      }, countDown * 1000);
    }
    if (countDown === 0 && isStart) {
      toggleIsStart();
    }
  }, [countDown, isStart]);

  return {
    countDown,
    isStart,
    setCountDownWithDate,
    toggleIsStart,
  };
};

export const customLocalStorage = (buId: string) => {
  const getBuLocalStorage = () => {
    const buLs = localStorage.getItem(buId);
    if (buId) {
      return buLs ? (JSON.parse(buLs) as object) : {};
    }
  };

  const getItem = (key: string): string | undefined => {
    const items = getBuLocalStorage();
    return items?.[key];
  };

  const setItem = (key: string, data: string) => {
    const items = getBuLocalStorage();
    if (items) {
      items[key] = data;
      localStorage.setItem(buId, JSON.stringify(items));
    }
  };

  const removeItem = (key: string) => {
    const items = getBuLocalStorage();
    if (items) {
      delete items[key];
      localStorage.setItem(buId, JSON.stringify(items));
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem(buId);
  };

  const migrateOldFormat = () => {
    const items = getBuLocalStorage();
    if (items) {
      for (const key of LocalStorageKey) {
        const localStorageValue = localStorage.getItem(key);
        if (localStorageValue) {
          items[key] = localStorageValue;
          localStorage.removeItem(key);
        }
      }
      localStorage.setItem(buId, JSON.stringify(items));
    }
  };

  return {
    getItemLs: getItem,
    setItemLs: setItem,
    removeItemLs: removeItem,
    clearLocalStorage,
    migrateOldFormatLs: migrateOldFormat,
  };
};

// initialized local storage hook with buid called within the hook
export const useLocalStorage = () => {
  const buId = getBuIdFromUrl(true);
  return customLocalStorage(buId);
};

export const customSessionStorage = (buId: string) => {
  const getBuSessionStorage = () => {
    const buSessionStorage = sessionStorage.getItem(buId);
    if (buId) {
      return buSessionStorage ? (JSON.parse(buSessionStorage) as object) : {};
    }
  };

  const getItem = (key: string): string | undefined => {
    const items = getBuSessionStorage();
    return items?.[key];
  };

  const setItem = (key: string, data: string) => {
    const items = getBuSessionStorage();
    if (items) {
      items[key] = data;
      sessionStorage.setItem(buId, JSON.stringify(items));
    }
  };

  const removeItem = (key: string) => {
    const items = getBuSessionStorage();
    if (items) {
      delete items[key];
      sessionStorage.setItem(buId, JSON.stringify(items));
    }
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem(buId);
  };

  const migrateOldFormat = () => {
    const items = getBuSessionStorage();
    if (!!items) {
      for (const key of SessionStorageKey) {
        const sessionStorageValue = sessionStorage.getItem(key);
        if (sessionStorageValue) {
          items[key] = sessionStorageValue;
          sessionStorage.removeItem(key);
        }
      }
      sessionStorage.setItem(buId, JSON.stringify(items));
    }
  };

  return {
    getItemSs: getItem,
    setItemSs: setItem,
    removeItemSs: removeItem,
    clearSessionStorage,
    migrateOldFormatSs: migrateOldFormat,
  };
};

// initialized session storage hook with buid called within the hook
export const useSessionStorage = () => {
  const buId = getBuIdFromUrl(true);
  return customSessionStorage(buId);
};

interface TouchEndParameter {
  handleSwipeRight?: () => void;
  handleSwipeLeft?: () => void;
  handleSwipeUp?: () => void;
  handleSwipeDown?: () => void;
}

export const useDetectSwipe = () => {
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchEndX, setTouchEndX] = useState<number | null>(null);
  const [touchStartY, setTouchStartY] = useState<number | null>(null);
  const [touchEndY, setTouchEndY] = useState<number | null>(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 100;

  const onTouchStart = (e: React.TouchEvent) => {
    setTouchEndX(null); // otherwise the swipe horizontal is fired even with usual touch events
    setTouchEndY(null); // otherwise the swipe vertical is fired even with usual touch events
    setTouchStartX(e.targetTouches[0].clientX);
    setTouchStartY(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e: React.TouchEvent) => {
    setTouchEndX(e.targetTouches[0].clientX);
    setTouchEndY(e.targetTouches[0].clientY);
  };

  const onTouchEnd = ({
    handleSwipeRight,
    handleSwipeLeft,
    handleSwipeUp,
    handleSwipeDown,
  }: TouchEndParameter) => {
    let isSwiping = {
      vertical: false,
      horizontal: false,
    };
    if (handleSwipeRight && handleSwipeLeft) {
      if (touchStartX && touchEndX) {
        const distanceX = touchStartX - touchEndX;
        const isLeftSwipe = distanceX > minSwipeDistance;
        const isRightSwipe = distanceX < -minSwipeDistance;
        if (isLeftSwipe) {
          isSwiping.horizontal = true;
          handleSwipeLeft();
        } else if (isRightSwipe) {
          isSwiping.horizontal = true;
          handleSwipeRight();
        }
      }
    }
    if (handleSwipeUp && handleSwipeDown) {
      if (touchStartY && touchEndY) {
        const distanceY = touchStartY - touchEndY;
        const isUpSwipe = distanceY > minSwipeDistance;
        const isDownSwipe = distanceY < -minSwipeDistance;
        if (isDownSwipe) {
          isSwiping.vertical = true;
          handleSwipeDown();
        } else if (isUpSwipe) {
          isSwiping.vertical = true;
          handleSwipeUp();
        }
      }
    }

    return isSwiping;
  };

  return {
    onTouchEnd,
    onTouchMove,
    onTouchStart,
  };
};

export function useOutsideClick(ref, outsideClickFunction) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        outsideClickFunction();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

const getCurrentDigit = (amount: number) => {
  const [, digit = ''] = String(amount).split('.');
  return digit.length;
};

export const useParameter = () => {
  const decimalDigits = useSelector(selectDecimalDigits);
  const pspProvider = useSelector(selectPspProvider);
  const currency = useSelector(selectCurrency);
  const memberShip = useSelector(selectMemberShip);
  const b2bdDiscountType = useSelector(selectDiscountType);
  const paymentType = useSelector(selectPaymentType);
  const cardMadeTaxRate = useSelector(selectCardMadeTaxRate);
  const shippingTaxRate = useSelector(selectShippingTaxRate);
  const customerType = useSelector(selectCustomerType);
  const authUser = useSelector(selectAuthUser);
  const currentHref = window.location.href;
  const navigationLanguage = window.navigator.language || '';
  const {
    defaultCurrency,
    isShowCurrencyCode,
    isShowDecimal,
    currentDefaultLanguage,
  } = getBuConfig();
  const amountFormate: (
    amount: number | null,
    isShowDigit?: boolean,
    isShowCurrency?: boolean,
    isFlexibleDigit?: boolean,
  ) => string = (
    amount = 0,
    isShowDigit = true,
    isShowCurrency = true,
    isFixedMinDigit = true,
  ) => {
    if (amount === null) {
      amount = 0;
    }
    const MAX_DIGIT = Number(decimalDigits);
    const currentDigit = getCurrentDigit(amount);
    const defaultMinDigit =
      isFixedMinDigit || currentDigit > MAX_DIGIT ? MAX_DIGIT : currentDigit;
    const options = {
      style: 'currency',
      currency: currency ? currency : defaultCurrency,
      currencyDisplay: isShowCurrencyCode ? 'code' : 'narrowSymbol',
      minimumFractionDigits:
        isShowDigit && isShowDecimal ? defaultMinDigit || 0 : 0,
      maximumFractionDigits: isShowDigit && isShowDecimal ? MAX_DIGIT || 0 : 0,
    };
    const selectedLang =
      sessionStorage.getItem('lang') ||
      currentDefaultLanguage ||
      navigationLanguage;
    const lang = selectedLang === 'es' ? 'en' : selectedLang;
    const numberFormat = new Intl.NumberFormat(lang, options);

    const {
      minimumFractionDigits,
      maximumFractionDigits,
    } = numberFormat.resolvedOptions();
    const numberFormatWithOutCurrency = amount.toLocaleString(lang, {
      minimumFractionDigits,
      maximumFractionDigits,
    });

    return isShowCurrency
      ? numberFormat.format(amount)
      : numberFormatWithOutCurrency;
  };
  /**
   * if the reloadAmtInterval of the product is equal to 1, then the amount could have two decimal places at most which is decided by the decimalDigits in getBuParameter.
   * otherwise, the amount should be integers.
   */
  const amountDigitController: (
    amount: string,
    decimalDigits: number,
  ) => string = (amount, decimalDigits) => {
    if (!amount) {
      return amount.toString();
    }
    const numAmount = Number(amount);
    const currentDigit = getCurrentDigit(numAmount);
    return currentDigit >= decimalDigits
      ? (
          Math.round(numAmount * Math.pow(10, decimalDigits)) /
          Math.pow(10, decimalDigits)
        ).toFixed(decimalDigits)
      : amount;
  };
  const isShowFacebook = memberShip?.includes(MemberShipType.FaceBook);
  const isShowApple = memberShip?.includes(MemberShipType.Apple);
  const isShowGoogle = memberShip?.includes(MemberShipType.Google);
  const isShowSSO = memberShip?.includes(MemberShipType.SSO);
  const isShowNormal = memberShip?.includes(MemberShipType.Normal);

  const isShowBonus = b2bdDiscountType?.includes(B2BDiscountType.Bonus);
  const isShowDiscount =
    b2bdDiscountType?.includes(B2BDiscountType.Discount) &&
    b2bdDiscountType?.length > 1;
  const isShowFreeGiftCard = b2bdDiscountType?.includes(
    B2BDiscountType.FreeGiftCard,
  );

  const _paymentType =
    customerType === SalesmanCustomerType.EnCompte
      ? PaymentMethodType.MyWallet
      : authUser && authUser.paymentType
      ? authUser.paymentType
      : paymentType;
  const isShowCreditCard = _paymentType.includes(PaymentMethodType.CreditCard);
  const isShowBankTransfer = _paymentType.includes(
    PaymentMethodType.BankTransfer,
  );
  const isShowBankCheck = _paymentType.includes(PaymentMethodType.BankCheck);
  const isShowDebitCard = _paymentType.includes(PaymentMethodType.DebitCard);
  const isShowGiftCard = _paymentType.includes(PaymentMethodType.GiftCard);
  const isShowMyWallet = _paymentType.includes(PaymentMethodType.MyWallet);
  const isShowPaypal = _paymentType.includes(PaymentMethodType.Paypal);
  const isShowINP = _paymentType.includes(PaymentMethodType.InternalUse);
  const isLogin = authUser;
  const isB2B = authUser && authUser.custType !== CustType.B2C;
  const isB2C = !authUser || authUser.custType === CustType.B2C;
  const isSalesman = authUser && authUser.custType === CustType.Salesman;
  const isB2CUser = authUser && authUser.custType === CustType.B2C;
  const isDefinitelyB2B = authUser && authUser.custType === CustType.B2B;
  const basePath = isB2C ? '' : isSalesman ? '/salesman' : '/b2b';

  // only if the user have logged in, custType.isB2B = true;
  const isNeedFixLine =
    (isB2B && currentHref.includes(NeedFixLinePath.B2BProfileContact)) ||
    (isB2B && currentHref.includes(NeedFixLinePath.B2BProfileDelivery)) ||
    (isB2B && currentHref.includes(NeedFixLinePath.B2BProfileInvoice)) ||
    (isB2B && currentHref.includes(NeedFixLinePath.B2BProfileCompany)) ||
    currentHref.includes(NeedFixLinePath.B2BRegister);
  return {
    currency,
    cardMadeTaxRate,
    shippingTaxRate,
    memberShip: {
      isShowFacebook,
      isShowApple,
      isShowGoogle,
      isShowSSO,
      isShowNormal,
      isShowOr:
        (isShowApple || isShowFacebook || isShowGoogle || isShowSSO) &&
        isShowNormal,
    },
    b2bDisCount: {
      isShowDiscount,
      isShowBonus,
      isShowFreeGiftCard,
    },
    payment: {
      isShowCreditCard,
      isShowDebitCard,
      isShowBankTransfer,
      isShowMyWallet,
      isShowGiftCard,
      isShowPaypal,
      isShowBankCheck,
      isShowINP,
    },
    custType: {
      isB2B,
      isB2C,
      isSalesman,
      isB2CUser,
      isDefinitelyB2B,
      isLogin,
      basePath,
      isNeedFixLine,
    },
    amountFormate,
    amountDigitController,
    pspProvider,
  };
};

export const formatDateByBu = dateStr => {
  // Unexpected format
  if (!dateStr?.includes('/')) return dateStr;
  const { buName } = getBuConfig();
  switch (buName as any) {
    case 'Carrefour':
      // DD/MM/YYYY
      const dateList = dateStr.split('/');
      return dateList[2] + '/' + dateList[1] + '/' + dateList[0];

    default:
      return dateStr;
  }
};

export const useStopPageEvent = () => {
  const stopPageEvent = event => {
    event.preventDefault();
    window.history.pushState(null, document.title, window.location.href);
  };

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', stopPageEvent, false);

    return () => {
      window.history.scrollRestoration = 'auto';
      window.removeEventListener('popstate', stopPageEvent, false);
    };
  }, [window.location.href]);
};

export const useSSOLogin = () => {
  const dispatch = useDispatch();
  const { actions: AuthActions } = useAuthSlice();
  const { setItemLs } = useLocalStorage();
  const { ssoName } = getBuConfig();

  const baseLoginWithOAuth = (redirectPath?: string) => async () => {
    try {
      const redirectUri = redirectPath
        ? `${window.location.origin}${redirectPath}`
        : window.location.origin;
      const response = await fetchOAuth(redirectUri);
      if (!response.success) {
        return;
      }
      setItemLs('socialMediaName', ssoName);
      setItemLs('redirectUrl', redirectUri);
      window.location.href = response.data.returnUrl;
    } catch (error) {
      dispatch(AuthActions.getErrorWithOAuth(error));
    }
  };

  const loginWithOAuth = baseLoginWithOAuth('');
  const loginWithOAuthAndRedirectUrl = apiRedirectPath =>
    baseLoginWithOAuth(apiRedirectPath)();

  return { loginWithOAuth, loginWithOAuthAndRedirectUrl };
};

export const usePhoneFormatWithGoogle = (countries: Country[]) => {
  const PhoneNumberUtil = LibPhoneNumber.PhoneNumberUtil.getInstance();
  const phoneFormat: (phone: string, areaCode: string) => string = (
    phone,
    areaCode,
  ) => {
    if (areaCode && phone) {
      const countryCode = countries.find(
        country => country.phoneCode.replace('+', '') === areaCode,
      );
      if (countryCode) {
        const phoneObject = PhoneNumberUtil.parse(phone, countryCode.id);
        return phoneObject.values_[2].toString();
      } else {
        return '';
      }
    } else {
      return '';
    }
  };
  return { phoneFormat };
};

export const useGuestModeCheck = () => {
  const { setItemSs } = useSessionStorage();
  const isEnableGuestMode = useSelector(selectIsEnableGuestMode);
  const memberShip = useSelector(selectMemberShip);
  const authUser = useSelector(selectAuthUser);
  const isOnlySSO = memberShip === MemberShipType.SSO;
  const isLogin = authUser;
  const checkAuthBeforeCheckout = () => {
    let isAllowToContinueAsGuest =
      sessionStorage.getItem('isAllowToContinueAsGuest') === '1';
      // UAE only guest
      if (paymentBu === "UAE") {
      setItemSs('isAllowToContinueAsGuest', '1');
      isAllowToContinueAsGuest = true;
    }
    if (isLogin || isAllowToContinueAsGuest) return 'checkout';
    if (isEnableGuestMode) return 'login';
    if (isOnlySSO) return 'SSO';
    return 'login';
  };
  return { isEnableGuestMode, isOnlySSO, checkAuthBeforeCheckout };
};

export const useDateFnsLocale = () => {
  const { i18n } = useTranslation();

  interface Locales {
    [key: string]: Locale;
  }

  const locales: Locales = {
    en: enUS,
    fr,
    es,
  };

  return { currentLocale: locales[i18n.language] };
};

export const isNoSpaceRegex = /^\S+$/;
export const isNoSpace = value => {
  return isNoSpaceRegex.test(value);
};

export const isIncludeUppercaseRegex = /^(?=.*[A-Z])/;
export const isIncludeUppercase = value => {
  return isIncludeUppercaseRegex.test(value);
};

export const isIncludeLowercaseRegex = /^(?=.*[a-z])/;
export const isIncludeLowercase = value => {
  return isIncludeLowercaseRegex.test(value);
};

export const isEqualObject = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || obj1 === null || 
      typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !isEqualObject(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}