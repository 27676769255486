import { call, put, select, takeLatest } from 'redux-saga/effects';
import { authActions as actions } from '.';
import {
  authLogin,
  authB2BLogin,
  salesmanLogin,
  register,
  registerConfirm,
  fetchQueryCompany,
  fetchB2bRegister,
  fetchSendOtp,
  fetchConfirmOtp,
  fetchB2bRegisterAccount,
  fetchB2bRegisterConfirmToken,
  fetchB2bConfirmRegister,
  fetchSNameFetcher,
  forgotPassword,
  verifyResetTokenApi,
  confirmReset,
  fetchB2BResendToken,
} from 'app/APIs';
import { get } from 'lodash';
import { CustType, LoginType } from 'app/pages/AuthPage/slice/types';
import { encryption } from 'helpers/crypto';
import { customLocalStorage, getBuIdFromUrl } from 'helpers';

function* loginSaga(action) {
  const {
    account,
    password,
    history,
    accessToken,
    mobileNo,
    loginType = LoginType.Normal,
    redirectUrl,
    afterLoginCallback,
  } = action.payload;

  try {
    const resp = yield call(authLogin, {
      aggregatorBuId: getBuIdFromUrl(true),
      account,
      password,
      accessToken,
      mobileNo,
      loginType,
      redirectUrl,
    });

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error) {
      throw resp.data.error;
    }
    const { setItemLs } = customLocalStorage(getBuIdFromUrl(true));
    setItemLs('accessToken', encryption(get(resp, 'data.accessToken', null)));
    setItemLs('custName', get(resp, 'data.custName', null));
    setItemLs('custId', encryption(get(resp, 'data.custId', null)));
    setItemLs('custType', encryption(get(resp, 'data.custType', null)));
    setItemLs('refreshToken', get(resp, 'data.refreshToken', ''));
    setItemLs('paymentType', get(resp, 'data.paymentType', null));

    yield put(actions.loginSuccess(resp.data));
    afterLoginCallback && afterLoginCallback();
  } catch (error) {
    console.warn('loginSaga', error);
    yield put(actions.loginFailure(error));
  }
}

function* forgotPasswordSaga(action) {
  const { email } = action.payload;
  try {
    const resp = yield call(forgotPassword, {
      email,
      aggregatorBuId: getBuIdFromUrl(true),
    });
    if (resp.data.error) {
      yield put(
        actions.forgotPasswordResponse({
          responseState: false,
          email: email,
          errorMessage: resp.data.error.message,
        }),
      );
    } else if (resp.status !== 200) {
      throw resp.data;
    } else {
      yield put(
        actions.forgotPasswordResponse({ responseState: true, email: email }),
      );
    }
  } catch (error) {
    console.log('forgotPasswordSaga', error);
    yield put(
      actions.forgotPasswordResponse({
        responseState: false,
        email: email,
        errorMessage: 'error',
      }),
    );
  }
}

function* verifyResetTokenSaga(action) {
  const { tokenId } = action.payload;
  try {
    const resp = yield call(verifyResetTokenApi, {
      tokenId,
    });
    if (resp.data.error) {
      throw resp.data;
    }
    yield put(
      actions.verifyResetTokenResponse({
        state: true,
        token: resp.data.token,
        memberType: resp.data.memberType,
      }),
    );
  } catch (error) {
    console.log('verifyResetTokenSaga', error);
    yield put(
      actions.verifyResetTokenResponse({
        state: false,
        token: '',
        memberType: '',
      }),
    );
  }
}

function* confirmResetPasswordSaga(action) {
  const { tokenId, password, accountId, memberType } = action.payload;
  try {
    const resp = yield call(confirmReset, {
      aggregatorBuId: getBuIdFromUrl(true),
      tokenId,
      password,
      accountId,
      memberType,
    });
    if (resp.data.error) {
      throw resp.data;
    }
    yield put(actions.confirmResetPasswordResponse(true));
  } catch (error) {
    console.log('confirmResetPasswordSaga', error);
    yield put(actions.confirmResetPasswordResponse(false));
  }
}

function* b2bLoginSaga(action) {
  const {
    account,
    password,
    history,
    accessToken,
    loginType = LoginType.Normal,
    afterLoginCallback,
  } = action.payload;

  try {
    const resp = yield call(authB2BLogin, {
      aggregatorBuId: getBuIdFromUrl(true),
      account,
      password,
      accessToken,
      loginType,
    });

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error) {
      throw resp.data.error;
    }

    const { setItemLs } = customLocalStorage(getBuIdFromUrl(true));
    setItemLs('accessToken', encryption(get(resp, 'data.accessToken', null)));
    setItemLs('custName', get(resp, 'data.custName', null));
    setItemLs('custId', encryption(get(resp, 'data.custId', null)));
    setItemLs('custType', encryption(get(resp, 'data.custType', null)));

    if (resp.data.paymentType) {
      setItemLs('paymentType', resp.data.paymentType);
    }

    yield put(actions.b2bLoginSuccess(resp.data));
    afterLoginCallback && afterLoginCallback();
  } catch (error) {
    console.warn('loginSaga', error);
    yield put(actions.b2bLoginFailure(error));
  }
}

function* salesmanLoginSaga(action) {
  const {
    account,
    password,
    history,
    accessToken,
    loginType = LoginType.Normal,
    afterLoginCallback,
  } = action.payload;

  try {
    const resp = yield call(salesmanLogin, {
      aggregatorBuId: getBuIdFromUrl(true),
      account,
      password,
      accessToken,
      loginType,
    });

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error) {
      throw resp.data.error;
    }

    const { setItemLs } = customLocalStorage(getBuIdFromUrl(true));
    setItemLs('accessToken', encryption(get(resp, 'data.accessToken', null)));
    setItemLs('custName', get(resp, 'data.custName', null));
    setItemLs('custId', encryption(get(resp, 'data.custId', null)));
    setItemLs('custType', encryption(get(resp, 'data.custType', null)));
    setItemLs('paymentType', get(resp, 'data.paymentType', null));

    yield put(actions.salesmanLoginSuccess(resp.data));
    afterLoginCallback && afterLoginCallback();
  } catch (error) {
    console.warn('salesmanLoginSaga', error);
    yield put(actions.salesmanLoginFailure(error));
  }
}

function* registerConfirmSaga(action) {
  const {
    accountId,
    registerType,
    loginIp,
    verifyToken,
    callback,
  } = action.payload;

  try {
    const resp = yield call(registerConfirm, {
      aggregatorBuId: getBuIdFromUrl(true),
      accountId,
      registerType,
      loginIp,
      verifyToken,
    });

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error?.code) {
      throw resp.data.error;
    }

    yield put(actions.registerConfirmSuccess(resp.data));
    const custType = encryption(
      get(resp, 'data.custType', null) || CustType.B2C,
    );

    const { setItemLs, removeItemLs } = customLocalStorage(
      getBuIdFromUrl(true),
    );
    setItemLs('accessToken', encryption(get(resp, 'data.accessToken', null)));
    setItemLs('custName', get(resp, 'data.custName', null));
    setItemLs('custId', encryption(get(resp, 'data.custId', null)));

    setItemLs('custType', custType);
    removeItemLs('socialMediaName');
    callback && callback(custType);
  } catch (error) {
    console.warn('registerConfirmSaga', error);
    yield put(actions.registerConfirmFailure(error));
  }
}

function* registerSaga(action) {
  const {
    lastName,
    firstName,
    email,
    password,
    documentType,
    idCardNo,
    mobileNo,
    accessToken,
    registerType,
    history,
    callback,
  } = action.payload;

  try {
    const resp = yield call(register, {
      aggregatorBuId: getBuIdFromUrl(true),
      lastName,
      firstName,
      email,
      password,
      documentType,
      idCardNo,
      mobileNo,
      accessToken,
      registerType,
    });

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error) {
      throw resp.data.error;
    }

    if (resp.data.emailValid) {
      history.push('/auth/login');
    }

    yield put(actions.registerSuccess(resp.data));
    callback && callback();
  } catch (error) {
    console.warn('registerSaga', error);
    yield put(actions.registerFailure(error));
  }
}

function* fetchB2bRegisterSaga(action) {
  const { b2bRegisterInfo, history } = action.payload;
  try {
    const resp = yield call(fetchB2bRegister, b2bRegisterInfo);

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error?.code) {
      throw resp.data.error;
    }

    yield put(actions.getB2bRegisterSuccess(resp.data));
    if (resp.data?.returnUrl) {
      window.location.replace(resp.data?.returnUrl);
    }
  } catch (error) {
    console.warn('fetchB2bRegisterSaga', error);
    yield put(actions.getB2bRegisterFailed(error));
  }
}

function* fetchB2bRegisterAccountSaga(action) {
  const { b2bRegisterInfo, successCb, email } = action.payload;
  try {
    const resp = yield call(fetchB2bRegisterAccount, b2bRegisterInfo);

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error?.code) {
      throw resp.data.error;
    }
    yield put(
      actions.getB2bRegisterAccountSuccess({ token: resp.data.tokenId, email }),
    );
    const { setItemLs } = customLocalStorage(getBuIdFromUrl(true));
    setItemLs('b2bRegisterToken', encryption(get(resp, 'data.tokenId', null)));
    setItemLs('b2bRegisterEmail', encryption(email));
    successCb();
  } catch (error) {
    console.warn('fetchB2bRegisterAccountSaga', error);
    yield put(actions.getB2bRegisterAccountFailed(error));
  }
}

function* fetchB2bRegisterConfirmTokenSaga(action) {
  const { b2bRegisterInfo, successCb } = action.payload;
  try {
    const resp = yield call(fetchB2bRegisterConfirmToken, b2bRegisterInfo);

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error?.code) {
      throw resp.data.error;
    }
    yield put(
      actions.getB2bRegisterConfirmTokenSuccess({ token: resp.data.tempId }),
    );
    const { setItemLs, removeItemLs } = customLocalStorage(
      getBuIdFromUrl(true),
    );
    removeItemLs('b2bRegisterToken');
    removeItemLs('b2bRegisterEmail');
    setItemLs('b2bRegisterTempId', encryption(get(resp, 'data.tempId', null)));
    removeItemLs('socialMediaName');
    successCb();
  } catch (error) {
    console.warn('fetchB2bRegisterSaga', error);
    yield put(actions.getB2bRegisterConfirmTokenFailed(error));
  }
}

function* fetchB2bConfirmRegisterSaga(action) {
  const { b2bRegisterInfo, successCb } = action.payload;
  try {
    const resp = yield call(fetchB2bConfirmRegister, b2bRegisterInfo);

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error?.code) {
      throw resp.data.error;
    }
    yield put(actions.getB2bConfirmRegisterSuccess({}));
    const { removeItemLs } = customLocalStorage(getBuIdFromUrl(true));
    removeItemLs('b2bRegisterTempId');
    removeItemLs('contactEmail');
    successCb();
  } catch (error) {
    console.warn('fetchB2bRegisterSaga', error);
    yield put(actions.getB2bConfirmRegisterFailed(error));
  }
}

function* fetchQueryCompanySaga(action) {
  try {
    const resp = yield call(fetchQueryCompany, action.payload);

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error?.code) {
      throw resp.data.error;
    }

    yield put(actions.getQueryCompanySuccess(resp.data));
  } catch (error) {
    console.warn('fetchQueryCompanySaga', error);
    yield put(actions.getQueryCompanyFailed(error));
  }
}

function* fetchSendOtpSaga(action) {
  try {
    const resp = yield call(fetchSendOtp, action.payload);

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error?.code) {
      throw resp.data.error;
    }

    yield put(actions.sendOtpSuccess(resp.data));
  } catch (error) {
    console.warn('fetchSendOtpSaga', error);
    yield put(actions.sendOtpFailed(error));
  }
}

function* fetchConfirmOtpSaga(action) {
  try {
    const resp = yield call(fetchConfirmOtp, action.payload);

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error?.code) {
      action.payload.callback(false);
      throw resp.data.error;
    }

    action.payload.callback(resp.data.result);
    yield put(actions.confirmOtpSuccess(resp.data));
  } catch (error) {
    console.warn('fetchConfirmOtpSaga', error);
    yield put(actions.confirmOtpFailed(error));
  }
}

function* fetchAccountCategorySaga() {
  try {
    const resp = yield call(fetchSNameFetcher, { mID: 'IND' });

    if (!resp.success) {
      throw resp.data;
    }
    yield put(
      actions.fetchAccountCategorySuccess({
        accountCategoryList: resp.data.scodeList,
      }),
    );
  } catch (error) {
    console.warn('fetchAccountCategorySaga', error);
    yield put(actions.fetchAccountCategoryFailed(error));
  }
}

function* fetchB2BResendTokenSaga(action) {
  try {
    const resp = yield call(
      fetchB2BResendToken,
      action.payload.accountId,
      getBuIdFromUrl(true),
    );

    if (!resp.success) {
      throw resp.data;
    }
    yield put(
      actions.fetchB2BResendTokenSuccess({
        email: action.payload.accountId,
        tokenId: resp.data.tokenId,
      }),
    );
  } catch (error) {
    console.warn('fetchB2BResendTokenSaga', error);
    yield put(actions.fetchB2BResendTokenFailed(error));
  }
}

export function* authSaga() {
  yield takeLatest(actions.register.type, registerSaga);
  yield takeLatest(actions.registerConfirm.type, registerConfirmSaga);
  yield takeLatest(actions.forgotPassword.type, forgotPasswordSaga);
  yield takeLatest(actions.verifyResetToken.type, verifyResetTokenSaga);
  yield takeLatest(actions.confirmResetPassword.type, confirmResetPasswordSaga);
  yield takeLatest(actions.login.type, loginSaga);
  yield takeLatest(actions.b2bLogin.type, b2bLoginSaga);
  yield takeLatest(actions.salesmanLogin.type, salesmanLoginSaga);
  yield takeLatest(actions.getQueryCompany.type, fetchQueryCompanySaga);
  yield takeLatest(actions.getB2bRegister.type, fetchB2bRegisterSaga);
  yield takeLatest(actions.sendOtp.type, fetchSendOtpSaga);
  yield takeLatest(actions.confirmOtp.type, fetchConfirmOtpSaga);
  yield takeLatest(
    actions.getB2bRegisterAccount.type,
    fetchB2bRegisterAccountSaga,
  );
  yield takeLatest(
    actions.getB2bRegisterConfirmToken.type,
    fetchB2bRegisterConfirmTokenSaga,
  );
  yield takeLatest(
    actions.getB2bConfirmRegister.type,
    fetchB2bConfirmRegisterSaga,
  );
  yield takeLatest(actions.fetchAccountCategory.type, fetchAccountCategorySaga);
  yield takeLatest(actions.fetchB2BResendToken.type, fetchB2BResendTokenSaga);
}
