export enum BlockStatus {
  UnBlock = '0',
  Block = '1',
}

export enum BlockAction {
  Block = 'B',
  UnBlock = 'UB',
}

export enum DeliverType {
  Physical = '0',
  SMS = '1',
  PDF = '2',
  Email = '3',
  BOTH = '4',
  Zip = '5',
  CardFile = '6',
  URLFile = '7',
}

export enum BarcodeType {
  BarCode = '0',
  BarCode2 = '11',
  QRCode = '1',
  PDF = '13',
  None = '9527',
}

export enum HistoryType {
  Order = 'O',
  Wallet = 'M',
  Transfer = 'T',
  Receive = 'R',
}

export enum CardTabType {
  Pay = 'pay',
  TermsAndConditions = 'tnc',
  Policy = 'policy',
  Transfer = 'transfer',
  History = 'history',
}

export enum TransferDeliverType {
  Email = 'E',
  Sms = 'S',
  Both = 'B',
  Other = '',
}

export enum MemberShipType {
  Normal = 'NL',
  SSO = 'SSO',
  FaceBook = 'FB',
  Google = 'GOOGLE',
  Apple = 'APPLE',
}

export enum DeliverOption {
  Email = 'E',
  Sms = 'S',
  Physical = 'P',
  Print = 'PAH',
}

export enum PaymentType {
  CreditCard = 'C04',
  Wallet = '14',
  BankTransfer = '03',
  BankCheck = '05',
  GiftCard = '15',
  Debit = '16',
  Paypal = 'PP',
  InternalUse = 'INP',
}

export enum PaymentMean {
  MyWallet = 'MW',
  CreditCard = 'CC',
}

export enum PaymentMethodType {
  CreditCard = 'CC',
  MyWallet = 'PC',
  DebitCard = 'DC',
  GiftCard = 'GC',
  BankTransfer = 'BA',
  BankCheck = 'BC',
  Paypal = 'PP',
  InternalUse = 'INP',
}

export enum SalesmanCustomerType {
  EnCompte = 'EnCompte',
}

export enum B2BDiscountType {
  Discount = 'D',
  Bonus = 'B',
  FreeGiftCard = 'FG',
}

export type Payment = {
  paymentAmt: number;
  paymentIdentify: string;
  paymentType: string;
};

export enum PaymentMethod {
  CreditCard = 'credit',
  Debit = 'debit',
  Paypal = 'paypal',
}

export enum PaymentMethodCode {
  CreditCard = 'C04',
  Coupon = '15',
}

export enum CommonStatus {
  init = 0,
  success = 1,
  error = 2,
}

export type ProductDiscount = {
  amountFrom: number;
  amountTo: number;
  discountRate: number;
};

export interface ProductsDiscount {
  itemCode: string;
  accumulateAmt: number;
  isAccuSales: boolean;
  discountList: ProductDiscount[];
}

export interface SalesmanProductsDiscount extends ProductsDiscount {
  custId: string;
}

export interface GiftCard {
  balance: number;
  barcodeType: BarcodeType;
  blocked: boolean;
  cardNumber: string;
  expiryDate: string;
  isBlocked: boolean;
}

export interface Transaction {
  deliverMethod: string;
  paymentMean: string;
  productImageUrl: string;
  receiverName: string;
  senderName: string;
  status: string;
  transAmt: number;
  transNo: string;
  transTime: string;
  transType: string;
}

export enum TopUpMethod {
  CreditCard = '0',
  Paypal = '1',
  BankTransfer = '2',
  ImportBalance = '3',
  DebitCard = '4',
}

export enum TemplateKind {
  CustomizePhysical = 'CP',
  Digital = 'D',
  Physical = 'P',
}

export interface TemplateCategoryData {
  templateId: string;
  templateName: string;
}

export interface TemplateData {
  id: string;
  images: TemplateImage[];
}

export interface TemplateImage {
  iconImageUrl: string;
  imagesId: string;
  leftImageUrl: string;
  rightImageUrl: string;
  kind: string;
  cardMadeFee: number;
  description?: string;
  discount?: number;
  itemCode?: string;
  taxRate?: number;
  termsAndConditions: string;
  shelf?: Shelf[];
  productName: string;
}

export interface ProductDetail {
  adsImageUrl: string;
  currency: string;
  imageUrl: string;
  itemCode: string;
  name: string;
  productInstructions: string;
  reloadAmtInterval: number;
  reloadAmtLists: string;
  reloadMaxAmt: number;
  reloadMinAmt: number;
  taxRate: number;
  termsAndConditions: string;
  transferMode: string;
  validMonths: string;
  discount?: number;
  deliverOption: string;
  redeemPlace: string;
  isFixValue: number;
  isThirdPartyProduct?: boolean;
  childProductList?: {
    amount: number;
    itemCode: string;
    taxRate: number;
  }[];
  maxDiscountRate: number;
  shelf?: Shelf[];
}

export interface Shelf {
  shelfId: string;
  shelfValue: string;
}

export interface OrderDraft {
  id: string;
  data: OrderDetail;
}

export interface UploadFile {
  amount: number;
  qty: number;
  receiverAddress?: string;
  receiverCity?: string;
  receiverCountryCode?: string;
  receiverEmail?: string;
  receiverMobile?: string;
  receiverName?: string;
  receiverVatNumber?: string;
  receiverZipCode?: string;
}

export interface UploadFilterFile {
  amount: number;
  qty: number;
  deliverType: string;
  emails: string[];
  mobiles: string[];
}

export interface OrderDetail {
  draftPath: OrderDraftPath;
  productImgUrl: string;
  productName: string;
  productDiscount: number;
  additionalTaxRate: number;
  productTaxRate: number;
  categoryId: string;
  customerId: string;
  amount: number;
  qty: number;
  discount: string;
  deliverDate: string;
  deliverType: DeliverType;
  deliverTimeZone: string;
  itemCode: string;
  message: string;
  personalImageUrl: string;
  receiverCivility: string;
  receiverAddress: string;
  receiverAddress2: string;
  receiverCity: string;
  receiverCountryCode: string;
  receiverEmail: string;
  receiverMobileAreaCode: string;
  receiverMobileNo: string;
  receiverName: string;
  receiverFirstName: string;
  receiverLastName: string;
  receiverVatNumber: string;
  receiverZipCode: string;
  senderEmail: string;
  senderName: string;
  templateId: string;
  templateUrl: string;
  letterTypeId: string;
  letterPrice: number;
  salesmanLetterType?: string | null;
  salesmanLetterValue?: number | null;
  message2: string;
  extraItemCode: string;
  extraItemImgUrl: string;
  extraProductName: string;
  extraProductAmount: number;
  kind: string;
  cardMadeFee: number;
  tempId?: string;
  fileType?: B2BUploadFileType | null;
  fileName?: string;
  uploadFileList?: UploadFilterFile[];
  isUseAnotherName?: boolean;
  discountItemCode?: string;
  parentItemCode?: string;
  urssafId?: number;
  customerName?: string;
  shelfId?: string;
  customizeShelf?: string;
  validMonths?: number;
  invoicingAddressType?: string;
  invoicingCivility?: string;
  invoicingFirstName?: string;
  invoicingLastName?: string;
  invoicingCountryCode?: string;
  invoicingCity?: string;
  invoicingAddress?: string;
  invoicingAddress2?: string;
  invoicingCode?: string;
  invoicingMobileNo?: string;
  invoicingMobileAreaCode?: string;
  invoicingEmail?: string;
  isUploadDigitalFile?: boolean;
  originalOrders?: UploadFile[];
  salesmanSetDiscount?: number | null;
}

export type Country = {
  id: string;
  name: string;
  phoneCode: string;
};

export interface Product {
  itemCode: string;
  name: string;
  frontImageUrl: string;
  backImageUrl: string;
  backImageUrl2: string;
  backImageUrl3: string;
  hashTag: string;
  discount: number | null;
  redeemPlace: string;
}

export type categoryType = {
  id: string;
  name: string;
};

export type LetterType = {
  id: number;
  methodName: string;
  price: number;
  oriPrice: number;
};

export type LetterPricesType = {
  name: string;
  price: number;
  qty: number;
  totalPrice: number;
  totalDiscount?: number;
};

export enum B2BUploadFileType {
  Digital = 'D',
  Physical = 'P',
}

export type AdditionalProductType = {
  faceAmount: number;
  imageUrl: string;
  itemCode: string;
  productName: string;
  taxRate: number;
};

export type Coupon = {
  cardBalance: number;
  cardNumber: string;
  isAllowPartialRedeem: number;
  remainingBalance?: number;
  appliedAmount?: number;
  subPayDesc?: string; //SUBPAYMENT UPDATE DATA TYPE
  suitedItemCode?: { itemCode: string }[]; //SUBPAYMENT UPDATE DATA TYPE
};

export type Scode = {
  id: string;
  name: string;
};

export enum CouponAllowPartialRedeem {
  True = 1,
  false = 0,
}

export enum RewardType {
  Discount = '1',
  Bonus = '2',
  FreeGiftCard = '3',
}

export enum ErrorCode {
  UploadWrongFormat = 63004,
}

export enum ShelfType {
  CustomizeType = '2',
}

export enum InvoicingAddressType {
  Default = 'default',
  Other = 'other',
}

export enum ButtonVariant {
  Primary = 'primary',
  PrimaryOutline = 'primary-outline',
}

export enum ButtonSize {
  Full = 'full',
  Medium = 'md',
}

export enum PopupMode {
  Ask,
  Alert,
  Buttonless, // Hide Button OK & Cancel
}

export enum PopupImgType {
  None,
  Success,
  Error,
}

export enum PopupContentType {
  Full = 'full',
  Medium = 'md',
  Small = 'sm',
  SmallCenter = 'sm-center',
}

export enum NotificationType {
  Success = 'success',
  Error = 'error',
}

const commonTypes = {
  DeliverType,
};

export enum OrderDraftPath {
  B2BToFriend = '/b2b/buying',
  B2BToSelf = '/b2b/buying-self',
  B2CToFriend = '/buying-friend',
  B2CToSelf = '/buying-self',
  SalesmanToFriend = '/salesman/buying',
  SalesmanToSelf = '/salesman/buying-self',
}

export default commonTypes;

export enum NeedFixLinePath {
  B2BRegister = '/auth/b2b/register',
  B2BProfileCompany = '/account/profile?pT=cI',
  B2BProfileContact = '/account/profile?pT=cD',
  B2BProfileInvoice = '/account/profile?pT=ad&eT=I',
  B2BProfileDelivery = '/account/profile?pT=ad&eT=D',
}

export enum UploadError {
  FileSizeTooBig = '1',
  SupportTypeError = '2',
}

export interface PspConfig {
  upstreamPay?: {
    environment: string;
    paymentBaseUrl: string;
  };
  ingenicoPay?: { paymentBaseUrl: string };
  marketPay?: { paymentBaseUrl: string };
}
