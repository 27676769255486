/**
 *
 * Asynchronously loads the component for B2BBuyingSelfPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SalesmanBuyingSelfPage = lazyLoad(
  () => import('./index'),
  module => module.SalesmanBuyingSelfPage,
);
