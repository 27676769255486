import ReactGA from 'react-ga4';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { shoppingCartSaga } from './saga';
import { ShoppingCartState } from './types';
import { cloneDeep, find, reject, concat, findIndex } from 'lodash';
import { decryption } from 'helpers/crypto';
import { Coupon, OrderDetail, OrderDraft } from 'types/common';
import { store } from 'store';
import {
  customLocalStorage,
  customSessionStorage,
  getBuIdFromUrl,
} from 'helpers';

const getOrdersFromLocalStorage = () => {
  try {
    const buId = getBuIdFromUrl(true);
    const { getItemLs } = customLocalStorage(buId);

    const buInitTokenLimit = getItemLs('initTokeLimit');

    const persistedState = decryption(
      buInitTokenLimit ? getItemLs('orders') : localStorage.getItem('orders'),
    );
    if (!persistedState) {
      return [];
    }
    const persistedOrders = JSON.parse(persistedState);
    return persistedOrders ? persistedOrders : [];
  } catch (error) {
    console.warn(error);
    return [];
  }
};

const getCouponListFromLocalStorage = () => {
  try {
    const buId = getBuIdFromUrl(true);
    const { getItemSs } = customSessionStorage(buId);
    const { getItemLs } = customLocalStorage(buId);

    const buInitTokenLimit = getItemLs('initTokeLimit');

    const persistedState = decryption(
      buInitTokenLimit ? getItemSs('cl') : sessionStorage.getItem('cl'),
    );
    if (!persistedState) {
      return [];
    }
    const persistedOrders = JSON.parse(persistedState);
    return persistedOrders ? persistedOrders : [];
  } catch (error) {
    console.warn(error);
    return [];
  }
};

const getCurrencyFromLocalStorage = () => {
  try {
    const buId = getBuIdFromUrl(true);
    const { getItemLs } = customLocalStorage(buId);
    const buCurrency = getItemLs('currency');
    if (!buCurrency) {
      return '';
    }
    return buCurrency;
  } catch (error) {
    console.warn(error);
    return '';
  }
};

export const initialState: ShoppingCartState = {
  cartItems: getOrdersFromLocalStorage(),
  couponList: getCouponListFromLocalStorage(),
  currency: getCurrencyFromLocalStorage(),
  isFetching: false,
  error: null,
  salesmanSetDiscount: 0,
};

const updateDiscount = (
  orders: OrderDraft[],
  itemCode: string,
  discount: number,
  isDiscountItemCode: boolean = false,
) => {
  let targetIndex = findIndex(
    orders,
    item =>
      (isDiscountItemCode
        ? item.data.discountItemCode === itemCode
        : item.data.itemCode === itemCode) &&
      item.data.productDiscount !== discount,
  );
  while (targetIndex !== -1) {
    const order = orders[targetIndex];
    const updatedOrder = {
      id: order.id,
      data: {
        ...order.data,
        productDiscount: discount,
      },
    };

    orders[targetIndex] = updatedOrder;

    targetIndex = findIndex(
      orders,
      item =>
        item.data.itemCode === itemCode &&
        item.data.productDiscount !== discount,
    );
  }
};

const slice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    updateOrders(
      state,
      action: PayloadAction<{ newOrders: Array<OrderDraft> }>,
    ) {
      ReactGA.event('add_to_cart', {
        currency: state.currency,
        value: action.payload.newOrders.reduce(
          (acc, curr) => acc + curr.data.amount * curr.data.qty,
          0,
        ),
        items: action.payload.newOrders.map(order => ({
          item_id: order.data.itemCode,
          item_name: order.data.productName,
          item_category: order.data.categoryId,
          price: order.data.amount,
          quantity: order.data.qty,
        })),
      });
      state.cartItems = action.payload.newOrders;
    },
    removeAdditionalProduct(state, action: PayloadAction<{ orderId: string }>) {
      const targetIndex = findIndex(
        state.cartItems,
        item => item.id === action.payload.orderId,
      );

      if (targetIndex !== -1) {
        const updatedOrder = {
          id: action.payload.orderId,
          data: {
            ...state.cartItems[targetIndex].data,
            extraItemCode: '',
            extraItemImgUrl: '',
            extraProductName: '',
            extraProductAmount: 0,
          },
        };

        state.cartItems[targetIndex] = updatedOrder;
      }
    },
    updateOrderSalesmanSetDiscount(
      state,
      action: PayloadAction<{ orderId: string; salesmanSetDiscount: number }>,
    ) {
      const targetIndex = findIndex(
        state.cartItems,
        item => item.id === action.payload.orderId,
      );

      if (targetIndex !== -1) {
        const updatedOrder = {
          id: action.payload.orderId,
          data: {
            ...state.cartItems[targetIndex].data,
            salesmanSetDiscount: action.payload.salesmanSetDiscount,
          },
        };
        state.cartItems[targetIndex] = updatedOrder;
      }
    },
    updateOrderReceiverEmail(
      state,
      action: PayloadAction<{ orderId: string; receiverEmail: string }>,
    ) {
      const targetIndex = findIndex(
        state.cartItems,
        item => item.id === action.payload.orderId,
      );

      if (targetIndex !== -1) {
        const updatedOrder = {
          id: action.payload.orderId,
          data: {
            ...state.cartItems[targetIndex].data,
            receiverEmail: action.payload.receiverEmail,
          },
        };
        state.cartItems[targetIndex] = updatedOrder;
      }
    },
    updateOrderQuantity(
      state,
      action: PayloadAction<{ orderId: string; qty: number }>,
    ) {
      const targetIndex = findIndex(
        state.cartItems,
        item => item.id === action.payload.orderId,
      );

      if (targetIndex !== -1) {
        const updatedOrder = {
          id: action.payload.orderId,
          data: {
            ...state.cartItems[targetIndex].data,
            qty: action.payload.qty,
          },
        };
        state.cartItems[targetIndex] = updatedOrder;
      }
    },
    addNewOrder(
      state,
      action: PayloadAction<{ orderId: string; data: OrderDetail }>,
    ) {
      ReactGA.event('add_to_cart', {
        currency: state.currency,
        value: action.payload.data.amount * action.payload.data.qty,
        items: {
          item_id: action.payload.data.itemCode,
          item_name: action.payload.data.productName,
          item_category: action.payload.data.categoryId,
          price: action.payload.data.amount,
          quantity: action.payload.data.qty,
        },
      });

      const existTarget = findIndex(state.cartItems, [
        'id',
        action.payload.orderId,
      ]);

      if (existTarget === -1) {
        state.cartItems = concat(state.cartItems, {
          id: action.payload.orderId,
          data: action.payload.data,
        });
      } else {
        state.cartItems[existTarget] = {
          id: action.payload.orderId,
          data: action.payload.data,
        };
      }
    },
    duplicateOrder(
      state,
      action: PayloadAction<{
        orderId: string;
        newOrderId: string;
      }>,
    ) {
      const targetOrder = find(
        state.cartItems,
        order => order.id === action.payload.orderId,
      );
      if (targetOrder) {
        state.cartItems = concat(state.cartItems, {
          id: action.payload.newOrderId,
          data: cloneDeep(targetOrder.data),
        });
      }
    },
    deleteDraftOrder(_state, _action) {},
    deleteOrder(
      state,
      action: PayloadAction<{
        orderId: string;
        discountList?: any;
        dontShowToast?: boolean;
      }>,
    ) {
      let discountItemCode;
      state.cartItems = reject(state.cartItems, order => {
        if (order.id === action.payload.orderId) {
          discountItemCode = order.data.discountItemCode;
          ReactGA.event('remove_from_cart', {
            currency: state.currency,
            value: order.data.amount * order.data.qty,
            items: [
              {
                item_id: order.data.itemCode,
                item_name: order.data.productName,
                item_category: order.data.categoryId,
                price: order.data.amount,
                quantity: order.data.qty,
              },
            ],
          });
          return true;
        }
        return false;
      });
      if (discountItemCode && action.payload.discountList) {
        let orderAmount = 0;
        const itemCodeDiscountList = action.payload.discountList.find(
          discountListItem => discountListItem.itemCode === discountItemCode,
        );
        const list = itemCodeDiscountList?.discountList || [];
        state.cartItems.forEach(order => {
          if (order.data.discountItemCode === discountItemCode) {
            orderAmount += order.data.amount * order.data.qty;
          }
        });
        const discount =
          find(
            list,
            discount =>
              discount.amountFrom <= orderAmount &&
              orderAmount <= discount.amountTo,
          )?.discountRate || 0;
        updateDiscount(state.cartItems, discountItemCode, discount, true);
      }
    },
    clearAll(state) {
      state.cartItems = [];
    },
    applyCouponCalculation(
      state,
      action: PayloadAction<{
        couponList: Coupon[];
      }>,
    ) {
      state.couponList = action.payload.couponList;
    },
    getCouponList(
      state,
      action: PayloadAction<{
        cardNumber: string;
        pinCode: string;
        totalAmount: number;
        successCallback: () => void;
        warnCallback: () => void;
        captchaToken: string;
      }>,
    ) {
      state.isFetching = true;
    },
    getCouponListSuccess(
      state,
      action: PayloadAction<{
        coupon: Coupon;
        totalAmount: number;
        warnCallback: () => void;
      }>,
    ) {
      ReactGA.event('select_promotion');
      state.isFetching = false;
      const { coupon, totalAmount, warnCallback } = action.payload;
      const currentCouponList = [coupon, ...state.couponList];
      state.couponList = currentCouponList;
      const totalCouponAmount = currentCouponList
        .map(coupon => coupon.cardBalance)
        .reduce((acc, curr) => acc + curr);
      if (!coupon.isAllowPartialRedeem && totalCouponAmount > totalAmount) {
        warnCallback();
      }
    },
    getCouponListFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
      console.warn(action.payload);
      state.error = action.payload;
    },
    removeCoupon(state, action: PayloadAction<{ cardNumber: string }>) {
      const { cardNumber } = action.payload;
      state.couponList = state.couponList.filter(
        coupon => coupon.cardNumber !== cardNumber,
      );
    },
    resetError(state) {
      state.error = null;
    },
    requerySubPaymentCouponList(state) {
      state.isFetching = true;
    },
    requeryCouponListSuccess(
      state,
      action: PayloadAction<{
        couponList: Coupon[];
        error: string[]; // when there is 1 or more error when querying
      }>,
    ) {
      state.isFetching = false;
      state.couponList = action.payload.couponList;
      state.error = action.payload.error.map(message => message).join('; ');
    },
    resetCouponList(state) {
      state.couponList = [];
    },
    updateDiscount(
      state,
      action: PayloadAction<{
        itemCode: string;
        discount: number;
        isDiscountItemCode?: boolean;
      }>,
    ) {
      const { itemCode, discount, isDiscountItemCode } = action.payload;
      updateDiscount(state.cartItems, itemCode, discount, isDiscountItemCode);
    },
    updateOrderDiscount(
      state,
      action: PayloadAction<{
        orderId: string;
        discount: number;
      }>,
    ) {
      const { orderId, discount } = action.payload;
      const orders = state.cartItems;
      let targetIndex = findIndex(
        orders,
        item => item.id === orderId && item.data.productDiscount !== discount,
      );

      if (targetIndex > -1) {
        const order = orders[targetIndex];
        const updatedOrder = {
          id: order.id,
          data: {
            ...order.data,
            productDiscount: discount,
          },
        };

        orders[targetIndex] = updatedOrder;
      }
    },
    updateSalesmanSetDiscount(
      state,
      action: PayloadAction<{
        discount: number;
      }>,
    ) {
      state.salesmanSetDiscount = action.payload.discount;
    },
    resetSalesmanSetDiscount(state) {
      state.salesmanSetDiscount = 0;
    },
    updateOrderSalesmanLetter(
      state,
      action: PayloadAction<{
        orderId: string;
        data: OrderDetail;
      }>,
    ) {
      const { orderId, data } = action.payload;
      const orders = state.cartItems;
      let targetIndex = findIndex(orders, item => item.id === orderId);

      if (targetIndex > -1) {
        const order = orders[targetIndex];
        const updatedOrder = {
          id: order.id,
          data: data,
        };

        orders[targetIndex] = updatedOrder;
      }
    },
  },
});

export const { actions: shoppingCartActions } = slice;

export const useShoppingCartSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: shoppingCartSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useShoppingCartSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
