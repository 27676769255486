import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import { addHours, getUnixTime } from 'date-fns';
// import JWT from 'expo-jwt';
// import { SupportedAlgorithms } from 'expo-jwt/dist/types/algorithms';

const jwt = require('jsonwebtoken');
const CryptoJS = require('crypto-js');

const secretKey = 'oglobaGift';
const jwtKey = process.env.REACT_APP_JWT_SECRET || '';

let privateKey = '';
let publicKey = '';

export const encryption = originalText => {
  const ciphertext = CryptoAES.encrypt(originalText, secretKey).toString();
  return ciphertext;
};

export const decryption = ciphertext => {
  const bytes = CryptoAES.decrypt(ciphertext.toString(), secretKey);
  const originalText = bytes.toString(CryptoENC);
  return originalText;
};

export const decryptionDES = (ciphertext, key) => {
  const originalText = CryptoJS.DES.decrypt(
    { ciphertext: CryptoJS.enc.Base64.parse(ciphertext) },
    CryptoJS.enc.Utf8.parse(key),
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    },
  ).toString(CryptoJS.enc.Utf8);
  return originalText;
};

export const setJwtKey = (_privateKey, _publicKey) => {
  privateKey = _privateKey;
  publicKey = _publicKey;
};

const getAudValue = () => {
  return Math.floor(1000000000 + Math.random() * 9000000000);
};

export const jwtEncode = object => {
  const token = jwt.sign(
    {
      aud: getAudValue(),
      exp: getUnixTime(addHours(new Date(), 1)),
      object,
    },
    privateKey,
    {
      algorithm: 'RS256',
    },
  );
  return token;
};

export function jwtDecode(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

// export const jwtEncode = object =>
//   JWT.encode(
//     {
//       aud: getAudValue(),
//       exp: getUnixTime(addHours(new Date(), 1)),
//       object,
//     },
//     jwtKey,
//     {
//       algorithm: SupportedAlgorithms.HS256,
//     },
//   );

// export const jwtDecode = token => JWT.decode(token, jwtKey);
