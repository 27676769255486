import { call, put, takeLatest, select } from 'redux-saga/effects';
import { accountActions as actions } from '.';
import {
  fetchMemberProfile,
  updateMemberProfile,
  getMemberPreviousFile,
  getMemberInbox,
  getMemberHistory,
  getMemberWallet,
  getMemberGiftCardsWithProduct,
  getMemberProducts,
  queryTransaction,
  updateCard,
  fetchTransactionTopUp,
  fetchCardTransfer,
  fetchCardResend,
  fetchCardPrint,
  fetchImportCard,
  authLogout,
  getCustomerList,
  fetchCardValidation,
} from 'app/APIs';
import { selectHistory } from './selectors';
import { selectAuthUser } from 'app/pages/AuthPage/slice/selectors';
import { AddressType, MemberType, ProfileTabType } from './types';
import { BlockAction } from 'types/common';
import { customLocalStorage, getBuIdFromUrl } from 'helpers';

function* fetchMemberPreviousFileSaga(action) {
  const { custId, accessToken, download } = action.payload;
  try {
    const resp = yield call(getMemberPreviousFile, {
      custId,
      accessToken,
    });

    if (!resp.success) {
      throw resp.data;
    }
    if (download) {
      window.open(resp.data.fileLink);
    }
    yield put(actions.fetchMemberPreviousFileSuccess(resp.data));
  } catch (error) {
    console.warn('fetchMemberPreviousFileSaga', error);
    yield put(actions.fetchMemberPreviousFileFailure());
  }
}

function* fetchMemberProfileSaga(action) {
  const { custId, accessToken, isForCustomer, successCb } = action.payload;
  try {
    const buId = getBuIdFromUrl(true);
    const { getItemLs, setItemLs } = customLocalStorage(buId);
    const resp = yield call(fetchMemberProfile, {
      custId,
      accessToken,
    });
    const {
      error,
      custId: respCustId,
      email,
      firstName,
      lastName,
      contractTypeId,
      isActivateAccount,
      memberType,
      b2BMemberProfile,
      allowUpdateProfile,
    } = resp.data;

    if (!resp.success) {
      throw error;
    }

    if (resp.data?.error?.code) {
      throw resp.data.error;
    }

    if (isForCustomer) {
      yield put(
        actions.fetchCustomerMemberProfileSuccess({
          memberType,
          companyInfo: b2BMemberProfile.companyRequest,
          contactInfo: b2BMemberProfile.contactRequest,
          invoiceInfo: b2BMemberProfile.invoiceRequest,
          deliverInfo: b2BMemberProfile.deliveryRequest,
          custId: b2BMemberProfile.custId,
          allowUpdateProfile: allowUpdateProfile,
        }),
      );
    } else if (memberType && memberType === MemberType.B2B) {
      yield put(
        actions.fetchMemberProfileB2BSuccess({
          memberType,
          companyInfo: b2BMemberProfile.companyRequest,
          contactInfo: b2BMemberProfile.contactRequest,
          invoiceInfo: b2BMemberProfile.invoiceRequest,
          deliverInfo: b2BMemberProfile.deliveryRequest,
          custId: b2BMemberProfile.custId,
          allowUpdateProfile: allowUpdateProfile,
        }),
      );
    } else {
      yield put(
        actions.fetchMemberProfileSuccess({
          custId: respCustId,
          email,
          firstName,
          lastName,
          contractTypeId,
          isActivateAccount,
          memberType,
          allowUpdateProfile: allowUpdateProfile,
        }),
      );
    }
    const custName = getItemLs('custName');
    if (custName === 'null' || !custName) {
      setItemLs(
        'custName',
        memberType === MemberType.B2B
          ? resp.data.b2BMemberProfile.companyRequest.companyName
          : `${resp.data.firstName} ${resp.data.lastName}`,
      );
    }
    successCb && successCb();
  } catch (error) {
    console.warn('fetchMemberProfileSaga', error);
    if (!isForCustomer) {
      yield put(actions.fetchMemberProfileFailure(error));
    }
  }
}

function* updateMemberProfileSaga(action) {
  const {
    custId,
    accessToken,
    firstName,
    lastName,
    password,
    memberType,
    contactInfo,
    companyInfo,
    deliveryInfo,
    invoiceInfo,
    profileTab,
    editType,
    callback,
  } = action.payload;
  try {
    let memberUpdate: any = {
      custId,
      firstName,
      lastName,
    };
    if (memberType === MemberType.B2B) {
      if (
        profileTab === ProfileTabType.Company ||
        (profileTab === ProfileTabType.Address &&
          editType === AddressType.Company)
      ) {
        memberUpdate = {
          companyInfo,
          custId,
        };
      }
      if (profileTab === ProfileTabType.Contact) {
        memberUpdate = {
          contactInfo,
          custId,
        };
      }
      if (
        profileTab === ProfileTabType.Address &&
        editType === AddressType.Delivery
      ) {
        memberUpdate = {
          deliveryInfo,
          custId,
        };
      }
      if (
        profileTab === ProfileTabType.Address &&
        editType === AddressType.Invoicing
      ) {
        memberUpdate = {
          invoiceInfo,
          custId,
        };
      }
    }

    const resp = yield call(updateMemberProfile, {
      accessToken,
      memberUpdate,
      memberType,
      password,
    });

    if (!resp.success) {
      throw resp.data.error;
    }

    if (resp.data?.error?.code) {
      throw resp.data.error;
    }
    if (memberType === MemberType.B2B) {
      yield put(
        actions.updateB2BMemberProfileSuccess({
          companyInfo,
          contactInfo,
          deliveryInfo,
          invoiceInfo,
        }),
      );
    } else {
      yield put(
        actions.updateMemberProfileSuccess({
          firstName,
          lastName,
        }),
      );
    }
    if (callback) {
      callback();
    }
  } catch (error) {
    console.warn('updateMemberProfileSaga', error);
    yield put(actions.updateMemberProfileFailure(error));
  }
}

function* fetchImportCardSaga(action) {
  const {
    accessToken,
    email,
    memberBu,
    mobileNo,
    token,
    successCb,
    failedCb,
    pinCode,
    importType,
    captchaToken,
  } = action.payload;
  const resp = yield call(
    fetchImportCard,
    accessToken,
    email,
    memberBu,
    mobileNo,
    token,
    pinCode,
    importType,
    captchaToken,
  );
  try {
    if (!resp.success) {
      throw '';
    }

    if (resp.data?.error?.code) {
      throw resp.data?.error?.message;
    }

    yield put(actions.getImportCardSuccess(resp.data));
    successCb(resp.data);
  } catch (error) {
    console.warn('fetchImportCardSaga', error);
    yield put(actions.getImportCardFailed(error));
    failedCb(error);
  }
}

function* fetchCardValidationSaga(action) {
  const { cardNumber, failedCb, captchaToken } = action.payload;
  try {
    const resp = yield call(fetchCardValidation, cardNumber, captchaToken);
    if (!resp.success) {
      throw '';
    }

    if (resp.data?.error?.code) {
      throw resp.data?.error?.message;
    }
    yield put(actions.fetchCardValidationSuccess(resp.data.isNeedPincode));
  } catch (error) {
    yield put(actions.fetchCardValidationFailed(error));
    failedCb(error);
  }
}

function* fetchInboxMessagesSaga(action) {
  const { custId, accessToken } = action.payload;
  try {
    const resp = yield call(getMemberInbox, {
      custId,
      accessToken,
    });

    if (!resp.success) {
      throw resp.data;
    }

    yield put(actions.fetchInboxMessagesSuccess(resp.data.inboxMessage));
  } catch (error) {
    console.warn('fetchInboxMessagesSaga', error);
    yield put(actions.fetchInboxMessagesFailure(error));
  }
}

function* fetchMemberHistorySaga(action) {
  const {
    historyType = 'O',
    page = 1,
    cardNumber = '',
    dateBegin = null,
    dateEnd = null,
    status = null,
    productName = null,
  } = action.payload;
  try {
    const { custId, accessToken } = yield select(selectAuthUser);

    if (!custId || !accessToken) {
      throw new Error('no authorized');
    }

    const resp = yield call(getMemberHistory, {
      custId,
      accessToken,
      historyType,
      page,
      cardNumber,
      dateBegin,
      dateEnd,
      status,
      productName,
    });

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error?.message) {
      throw resp.data;
    }

    if (page !== 1) {
      const { totalCount, transactionRecords: currRecords } = yield select(
        selectHistory,
      );
      yield put(
        actions.fetchMemberHistorySuccess({
          historyType: resp.data.historyType,
          totalCount,
          transactionRecords: currRecords.concat(resp.data.transactionRecords),
        }),
      );
    } else {
      yield put(actions.fetchMemberHistorySuccess(resp.data));
    }
  } catch (error) {
    console.warn('fetchMemberHistorySaga', error);
    yield put(actions.fetchMemberHistoryFailure(error));
  }
}

function* fetchMemberWalletSaga(action) {
  const { custId, accessToken } = action.payload;
  try {
    const resp = yield call(getMemberWallet, {
      custId,
      accessToken,
    });

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error?.message) {
      throw resp.data;
    } else {
      yield put(
        actions.fetchMemberWalletSuccess({
          balance: resp.data.balance,
          orderCount: resp.data.orderCount,
          isReloadable: resp.data.isReloadable,
          cardNumber: resp.data.cardNumber,
          processAmount: resp.data.processAmount,
        }),
      );
    }
  } catch (error) {
    console.warn('fetchMemberWalletSaga', error);
    yield put(actions.fetchMemberWalletFailure(error));
  }
}

function* fetchMemberProductsSaga(action) {
  const { custId, accessToken, productName, categoryId } = action.payload;
  try {
    const resp = yield call(getMemberProducts, {
      custId,
      accessToken,
      productName,
      categoryId,
    });

    if (!resp.success) {
      throw resp.data;
    }

    yield put(actions.fetchMemberProductsSuccess(resp.data.productLists));
  } catch (error) {
    console.warn('fetchMemberProductsSaga', error);
    yield put(actions.fetchMemberProductsFailure(error));
  }
}

function* fetchGiftCardsSaga(action) {
  const { custId, accessToken, itemCode } = action.payload;
  try {
    const resp = yield call(getMemberGiftCardsWithProduct, {
      custId,
      accessToken,
      itemCode,
    });

    if (!resp.success) {
      throw resp.data;
    }
    yield put(
      actions.fetchGiftCardsSuccess({
        isReloadable: resp.data.isReloadable,
        productName: resp.data.productName,
        howToUse: resp.data.howToUse,
        productImageUrl: resp.data.productImageUrl,
        termOfService: resp.data.termOfService,
        cardList: resp.data.cardList,
        itemCode: resp.data.itemCode,
        transferMode: resp.data.transferMode,
      }),
    );
  } catch (error) {
    console.warn('fetchGiftCardsSaga', error);
    yield put(actions.fetchGiftCardsFailure(error));
  }
}

function* fetchTransactionSaga(action) {
  const { cardNumber, pageNo } = action.payload;

  try {
    const resp = yield call(queryTransaction, cardNumber, pageNo);

    if (resp.data.error) {
      throw resp.data.error;
    }

    yield put(
      actions.fetchTransactionSuccess({
        totalCount: resp.data.totalCount,
        transactionList: resp.data.transactionList,
      }),
    );
  } catch (error) {
    console.warn(error);
    yield put(actions.fetchTransactionFailure(error));
  }
}

function* updateCardSaga(action) {
  const { cardNumber, actionType, callback, captchaToken } = action.payload;
  try {
    const resp = yield call(updateCard, cardNumber, actionType, captchaToken);

    if (!resp.success) {
      throw resp.data.error;
    }

    yield put(
      actions.updateCardSuccess({
        cardNumber,
        isBlock: actionType === BlockAction.Block,
      }),
    );
    if (callback) {
      callback();
    }
  } catch (error) {
    console.warn(error);
    yield put(actions.updateCardFailure(error));
  }
}

function* fetchTransactionTopUpSaga(action) {
  const { paymentInfo, accessToken, successCb, failedCb } = action.payload;

  try {
    const resp = yield call(
      fetchTransactionTopUp,
      paymentInfo,
      accessToken,
      getBuIdFromUrl(true),
    );
    if (!resp.success) {
      throw resp.data.error;
    }
    if (resp.data.error && resp.data.error?.message) {
      throw resp.data.error;
    }
    successCb();
    yield put(actions.getTransactionTopUpSuccess(resp.data));
  } catch (error) {
    console.warn(error);
    failedCb();
    yield put(actions.getTransactionTopUpFailed(error));
  }
}

function* fetchCardTopUpSaga(action) {
  const { paymentInfo, accessToken, successCb, failedCb } = action.payload;
  try {
    const resp = yield call(
      fetchTransactionTopUp,
      paymentInfo,
      accessToken,
      getBuIdFromUrl(true),
    );
    if (!resp.success) {
      throw resp.data.error;
    }
    if (resp.data.error) {
      throw resp.data.error;
    }
    yield put(actions.getCardTopUpSuccess(resp.data));
    successCb();
  } catch (error) {
    console.warn(error);
    failedCb();
    yield put(actions.getCardTopUpFailed(error));
    failedCb();
  }
}

function* fetchCardTransferSaga(action) {
  try {
    const resp = yield call(fetchCardTransfer, action.payload);

    if (!resp.success) {
      throw resp.data.error;
    }

    if (resp.data?.error?.message) {
      throw resp.data?.error;
    }

    yield put(actions.fetchTransferCardSuccess(resp.data));
  } catch (error) {
    console.warn(error);
    yield put(actions.fetchTransferCardFailed(error));
  }
}

function* fetchCardResendSaga(action) {
  const { cardNumber, captchaToken } = action.payload;
  try {
    const resp = yield call(fetchCardResend, cardNumber, captchaToken);

    if (!resp.success) {
      throw resp.data.error;
    }

    if (resp.data?.error?.message) {
      throw resp.data?.error;
    }

    yield put(actions.getCardResendSuccess());
  } catch (error) {
    console.warn(error);
    yield put(actions.getCardResendFailed(error));
  }
}

function* fetchCardPrintSaga(action) {
  const { cardNumber, captchaToken } = action.payload;
  try {
    const resp = yield call(fetchCardPrint, cardNumber, captchaToken);

    if (!resp.success) {
      throw resp.data.error;
    }

    if (resp.data?.error?.message) {
      yield put(actions.getCardPrintSuccess(resp.data));
      throw resp.data?.error;
    }

    yield put(actions.getCardPrintSuccess(resp.data));
  } catch (error) {
    console.warn(error);
    yield put(actions.getCardPrintFailed(error));
  }
}

function* authLogoutSaga(action) {
  const { refreshToken, accessToken, successCb, failedCb } = action.payload;
  try {
    const resp = yield call(authLogout, {
      refreshToken,
      accessToken,
    });

    if (!resp.success || !resp.data.isSuccess) {
      failedCb && failedCb();
      throw resp.data.error;
    }

    successCb && successCb();
    yield put(actions.getOauthLogoutSuccess(resp.data));
  } catch (error) {
    console.warn('authLogoutSaga', error);
    yield put(actions.getOauthLogoutFailed(error));
  }
}

function* getchGetCustomerListSaga(action) {
  const { custId, accessToken } = action.payload;
  try {
    const resp = yield call(getCustomerList, custId);

    if (!resp.success) {
      throw resp.data.error;
    }

    if (resp.data?.error?.message) {
      throw resp.data?.error;
    }

    yield put(actions.getCustomerListSuccess(resp.data.customerList));
  } catch (error) {
    console.warn(error);
    yield put(actions.getCustomerListFailure(error));
  }
}

export function* accountSaga() {
  yield takeLatest(
    actions.fetchMemberPreviousFile.type,
    fetchMemberPreviousFileSaga,
  );
  yield takeLatest(actions.fetchMemberProfile.type, fetchMemberProfileSaga);
  yield takeLatest(actions.updateMemberProfile.type, updateMemberProfileSaga);
  yield takeLatest(actions.fetchInboxMessages.type, fetchInboxMessagesSaga);
  yield takeLatest(actions.fetchMemberHistory.type, fetchMemberHistorySaga);
  yield takeLatest(actions.fetchMemberWallet.type, fetchMemberWalletSaga);
  yield takeLatest(actions.fetchMemberProducts.type, fetchMemberProductsSaga);
  yield takeLatest(actions.fetchGiftCards.type, fetchGiftCardsSaga);
  yield takeLatest(actions.fetchTransaction.type, fetchTransactionSaga);
  yield takeLatest(actions.updateCard.type, updateCardSaga);
  yield takeLatest(actions.getTransactionTopUp.type, fetchTransactionTopUpSaga);
  yield takeLatest(actions.fetchTransferCard.type, fetchCardTransferSaga);
  yield takeLatest(actions.getCardTopUp.type, fetchCardTopUpSaga);
  yield takeLatest(actions.getCardResend.type, fetchCardResendSaga);
  yield takeLatest(actions.getCardPrint.type, fetchCardPrintSaga);
  yield takeLatest(actions.getImportCard.type, fetchImportCardSaga);
  yield takeLatest(actions.getOauthLogout.type, authLogoutSaga);
  yield takeLatest(actions.getCustomerList.type, getchGetCustomerListSaga);
  yield takeLatest(actions.fetchCardValidation.type, fetchCardValidationSaga);
}
