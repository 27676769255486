import axios from 'axios';
import { jwtEncode, jwtDecode } from 'helpers/crypto';

export const encryptedCallApi = async ({
  url,
  payload,
  method = 'post',
}: {
  url: string;
  payload: any;
  method?: string;
}) => {
  try {
    const resp = await axios[method](url, { token: jwtEncode(payload) });
    if (resp.status !== 200) {
      console.warn(resp);
      return {
        success: false,
        status: resp.status,
        data: resp.data,
      };
    }
    return {
      success: true,
      status: resp.status,
      data: jwtDecode(resp.data.token).object,
    };
  } catch (error) {
    console.warn(error);
    return {
      success: false,
      status: null,
      data: error,
    };
  }
};
