import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { salesmanBuyingSelfSaga } from './saga';
import { SalesmanBuyingSelfState } from './types';
import { OrderDetail, OrderDraft, ProductDiscount } from 'types/common';
import { cloneDeep, merge } from 'lodash';
import { ORDER_TEMPLATE } from './constants';

export const initialState: SalesmanBuyingSelfState = {
  isFetching: false,
  draftOrder: null,
  customerType: '',
  product: {},
  countryList: [],
  discountList: [],
};

const slice = createSlice({
  name: 'salesmanBuyingSelf',
  initialState,
  reducers: {
    fetchProductDiscount(
      state,
      action: PayloadAction<{
        custId: string;
        itemCode: string;
      }>,
    ) {
      state.isFetching = true;
    },
    fetchProductDiscountSuccess(
      state,
      action: PayloadAction<{
        custId: string;
        itemCode: string;
        accumulateAmt: number;
        isAccuSales: boolean;
        discountList: ProductDiscount[];
      }>,
    ) {
      state.discountList = [...state.discountList, action.payload];
      state.isFetching = false;
    },
    fetchProductDiscountFail(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    fetchProductsDiscount(
      state,
      action: PayloadAction<{
        custId: string;
        itemCodeList: string[];
      }>,
    ) {
      state.isFetching = true;
    },
    fetchProductsDiscountSuccess(
      state,
      action: PayloadAction<
        {
          custId: string;
          itemCode: string;
          accumulateAmt: number;
          isAccuSales: boolean;
          discountList: ProductDiscount[];
        }[]
      >,
    ) {
      state.isFetching = false;
      state.discountList = [...state.discountList, ...action.payload];
    },
    fetchProductsDiscountFail(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    removeDiscount(state, action: PayloadAction) {
      state.discountList = [];
    },
    resetDraft(state) {
      state.draftOrder = null;
    },
    createDraft(state, action: PayloadAction<{ id: string; data: any }>) {
      state.draftOrder = {
        id: action.payload.id,
        data: {
          ...cloneDeep(ORDER_TEMPLATE),
          ...action.payload.data,
        },
      };
    },
    updateOrder(
      state,
      action: PayloadAction<{
        orderId: string;
        data: OrderDetail;
      }>,
    ) {
      if (state.draftOrder) {
        state.draftOrder = {
          id: action.payload.orderId,
          data: merge(state.draftOrder.data, action.payload.data),
        };
      }
    },
    fetchProductDetail(
      state,
      action: PayloadAction<{
        itemCode: string;
        custId: string;
      }>,
    ) {
      state.isFetching = true;
    },
    fetchProductDetailSuccess(
      state,
      action: PayloadAction<{
        itemCode: string;
        data: any;
      }>,
    ) {
      state.isFetching = false;
      state.product = {
        ...state.product,
        [action.payload.itemCode]: action.payload.data,
      };
    },
    fetchProductDetailFailure(
      state,
      action: PayloadAction<{
        itemCode: string;
      }>,
    ) {
      state.isFetching = false;
    },
    checkout(
      state,
      action: PayloadAction<{
        custId?: string;
        orderImageUrl?: string;
        freeItem?: string;
        freeItemQty?: string;
        paymentMean?: string;
        rewardType?: string;
        tempId?: string;
        orders: OrderDraft[];
        callback: any;
      }>,
    ) {
      state.isFetching = true;
    },
    checkoutSuccess(state, action: PayloadAction<{ orderNo: string }>) {
      state.isFetching = false;
    },
    checkoutFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    setCustomerType(state, action: PayloadAction<{ customerType: string }>) {
      state.customerType = action.payload.customerType;
    },
  },
});

export const { actions: salesmanBuyingSelfActions } = slice;

export const useSalesmanBuyingSelfSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: salesmanBuyingSelfSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSalesmanBuyingSelfSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
