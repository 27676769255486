/**
 *
 * LoadingProgress
 *
 */
import * as React from 'react';
import LoadingIcon from 'img/all_page/icon/loading.svg';
import { getBuConfig } from 'helpers/BuHelper';
interface Props {
  isFullScreen?: boolean;
}

export function LoadingProgress(props: Props) {
  const { isFullScreen = false } = props;
  const { getAsset, buId } = getBuConfig();
  const { Loading: LoadingBu } = getAsset();
  return (
    <>
      {isFullScreen ? (
        <div className="loading-full-screen">
          <div className="a-load-container">
            <div className="a-load">
              <img src={!!buId ? LoadingBu : LoadingIcon} alt="loading" />
            </div>
          </div>
        </div>
      ) : (
        <div className="a-load-container">
          <div className="a-load">
            <img src={LoadingBu} alt="" />
          </div>
        </div>
      )}
    </>
  );
}
