import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.unlock || initialState;

export const selectUnlockState = createSelector([selectSlice], state => state);

export const selectIsFetching = createSelector(
  [selectSlice],
  state => state.isFetching,
);

export const selectOrderNo = createSelector(
  [selectSlice],
  state => state.orderNo,
);
