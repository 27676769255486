/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  title: () => _t(translations.Banner.title, 'After buy {{buName}} card'),
  subTitle: () =>
    _t(translations.Banner.subTitle, 'You can add cards in our APP'),
  b2bTitle: () =>
    _t(translations.Banner.b2bTitle, 'We have a whole team service for you'),
  b2bSubTitle: () =>
    _t(translations.Banner.b2bSubTitle, 'Need help? Contact us!'),
  sendEmail: () => _t(translations.Banner.sendEmail, 'Send Email'),
  callUs: () => _t(translations.Banner.callUs, 'Call Us'),
  googlePlay: () => _t(translations.Banner.googlePlay, 'Google Play'),
  appStore: () => _t(translations.Banner.appStore, 'App Store'),
};
