import { History } from 'history';

/* --- STATE --- */
export interface AuthState {
  isFetching: boolean;
  user: any;
  error: {
    message: string;
    code: number;
    [key: string]: any;
  } | null;
  accountCategoryList: AccountCategory[];
  queryCompanyList: queryCompany[];
  queryTemplateId: string;
  otpId: string;
  b2bRegisterEmail: string;
  b2bRegisterToken: string;
  b2bRegisterTempId: string;
  forgetPasswordInfo: ForgetPasswordInfo;
  verifyResetTokenInfo: VerifyResetTokenInfo;
  confirmResetState: boolean | null;
}

export enum LoginType {
  Employee = 'EM',
  Normal = 'NL',
  B2C = 'B2C',
  FB = 'FB',
  GOOGLE = 'GOOGLE',
}

export enum CustType {
  B2B = 'B2B',
  B2C = 'B2C',
  SSO = 'SSO',
  Salesman = 'SALESMAN',
}

export enum SocietyType {
  PrivateCompany = 0,
  PublicCompany = 1,
  Association = 2,
}

export enum RegisterStep {
  Step1 = '1',
  Step2 = '2',
  Step3 = '3',
  Verify = 'Verify',
}

export enum AddressSelectType {
  Default = 'default',
  Other = 'other',
}

export enum CivilityType {
  Mr = '0',
  Mrs = '1',
  Other = '2',
}

export interface queryCompany {
  address: string;
  ape: string;
  apeText: string;
  city: string;
  companyName: string;
  country: string;
  postalCode: string;
  registrationDate: string;
  sign: string;
  siren: string;
  siret: string;
}

export interface RegisterInput {
  lastName: string;
  firstName: string;
  email: string;
  password: string;
  documentType: string;
  idCardNo: string;
  mobileNo: string;
  accessToken?: string;
  registerType?: string;
  callback: (email) => void;
}

export interface LoginInput {
  account: string;
  password: string;
  accessToken: string;
  mobileNo?: string;
  loginType?: LoginType | string;
  redirectUrl?: string;
  afterLoginCallback?: () => void;
}

export interface ForgetPasswordInfo {
  responseState: boolean;
  email: string;
  errorMessage?: string;
}

export interface VerifyResetTokenInfo {
  state: boolean | null;
  token: string;
  memberType: string;
}

export const contactInitialValue = {
  accountCategory: '',
  societyType: SocietyType.PrivateCompany,
  siren: '',
  companyPhoneNumber: '',
  companyPhoneAreaCode: '',
  companyCountry: '',
  numberOfEmployees: '',
  contactStoreAdvisor: '',
  companyName: '',
  commercialName: '',
  isFoundCompany: true,
  vat: '',
  companyCity: '',
  companyAddress: '',
  companyAddress2: '',
  companyCode: '',
  contactPassword: '',
  contactPhoneNumber: '',
  contactPhoneAreaCode: '',
  agreement: false,
  invoicingAddressType: AddressSelectType.Default,
  deliveryAddressType: AddressSelectType.Default,
  contactCivility: '',
  contactLastName: '',
  contactFirstName: '',
  contactEmail: '',
  invoicingCivility: '',
  invoicingLastName: '',
  invoicingFirstName: '',
  invoicingPhoneAreaCode: '',
  invoicingPhoneNumber: '',
  invoicingCountry: '',
  invoicingCity: '',
  invoicingAddress: '',
  invoicingAddress2: '',
  invoicingCode: '',
  invoiceEmail: '',
  deliveryCivility: '',
  deliveryLastName: '',
  deliveryFirstName: '',
  deliveryPhoneAreaCode: '',
  deliveryPhoneNumber: '',
  deliveryCountry: '',
  deliveryCity: '',
  deliveryAddress: '',
  deliveryAddress2: '',
  deliveryCode: '',
  isSubscribe: true,
  uploadId: '',
  uploadSiret: '',
};

export type Step1Info = {
  accountCategory: string;
  societyType: number;
  siren: string;
  companyPhoneNumber: string;
  companyPhoneAreaCode: string;
  companyCountry: string;
  numberOfEmployees: string;
  contactStoreAdvisor: number;
  companyName: string;
  commercialName: string;
  vat: string;
  companyCity: string;
  companyAddress: string;
  companyAddress2: string;
  companyCode: string;
  isFoundCompany: boolean;
  queryCompany?: queryCompany;
  agreement: boolean;
};

export interface Step2Info extends Step1Info {
  contactPassword: string;
  contactPhoneNumber: string;
  contactPhoneAreaCode: string;
  invoicingAddressType: string;
  deliveryAddressType: string;
  contactCivility: string;
  contactLastName: string;
  contactFirstName: string;
  contactEmail: string;
  invoicingCivility: string;
  invoicingLastName: string;
  invoicingFirstName: string;
  invoicingPhoneAreaCode: string;
  invoicingPhoneNumber: string;
  invoicingCountry: string;
  invoicingCity: string;
  invoicingAddress: string;
  invoicingAddress2: string;
  invoicingCode: string;
  deliveryCivility: string;
  deliveryLastName: string;
  deliveryFirstName: string;
  deliveryPhoneAreaCode: string;
  deliveryPhoneNumber: string;
  deliveryCountry: string;
  deliveryCity: string;
  deliveryAddress: string;
  deliveryAddress2: string;
  deliveryCode: string;
}

export type Step3Info = {
  uploadId: string;
  uploadSiret: string;
};

export type AccountCategory = {
  id: string;
  name: string;
};

export type CompanyInfo = {
  accountCategory: string;
  city: string;
  commercialName: string;
  companyName: string;
  companyPhoneNumber: string;
  country: string;
  firstAddress: string;
  isContactWithStore: number;
  numberOfEmployees: string;
  secondAddress: string;
  sirenNumber: string;
  societyType: number;
  vatNumber: string;
  zipCode: string;
};

export type ContactInfo = {
  civillity: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: string;
};

export type AddressInfo = {
  city: string;
  civillity: string;
  country: string;
  firstAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  secondAddress: string;
  zipCode: string;
  invoiceEmail?: string;
};
