import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.auth || initialState;

export const selectAuth = createSelector([selectSlice], state => state);

export const selectIsFetching = createSelector(
  [selectSlice],
  state => state.isFetching,
);

export const selectForgetPasswordInfo = createSelector(
  [selectSlice],
  state => state.forgetPasswordInfo,
);

export const selectVerifyResetTokenState = createSelector(
  [selectSlice],
  state => state.verifyResetTokenInfo,
);

export const selectConfirmResetState = createSelector(
  [selectSlice],
  state => state.confirmResetState,
);

export const selectAuthUser = createSelector(
  [selectSlice],
  state => state.user,
);

export const selectQueryCompanyList = createSelector(
  [selectSlice],
  state => state.queryCompanyList,
);

export const selectQueryTemplateId = createSelector(
  [selectSlice],
  state => state.queryTemplateId,
);

export const selectB2bRegisterEmail = createSelector(
  [selectSlice],
  state => state.b2bRegisterEmail,
);

export const selectB2bRegisterToken = createSelector(
  [selectSlice],
  state => state.b2bRegisterToken,
);

export const selectB2bRegisterTempId = createSelector(
  [selectSlice],
  state => state.b2bRegisterTempId,
);

export const selectAccountCategoryList = createSelector(
  [selectSlice],
  state => state.accountCategoryList,
);
export const selectError = createSelector([selectSlice], state => state.error);
