/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  product: () => _t(translations.Footer.product, 'Product'),
  features: () => _t(translations.Footer.product, 'Features'),
  resources: () => _t(translations.Footer.resources, 'Resources'),
  about: () => _t(translations.Footer.about, 'About'),
  help: () => _t(translations.Footer.help, 'Help'),
  contactUs: () => _t(translations.Footer.contactUs, 'Contact us'),
  privacyPolicy: () => _t(translations.Footer.privacyPolicy, 'Privacy Policy'),
  termsConditions: () =>
    _t(translations.Footer.termsConditions, 'Terms & Conditions'),
  generalConditions: () =>
    _t(translations.Footer.generalConditions, 'General conditions of sale'),
  personalData: () => _t(translations.Footer.personalData, 'Personal data'),
  legalNotices: () => _t(translations.Footer.legalNotices, 'Legal notices'),
  deliveryInfo: () => _t(translations.Footer.deliveryInfo, 'Delivery info'),
  faq: () => _t(translations.Footer.faq, 'FAQ'),
};
