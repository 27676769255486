/**
 *
 * Asynchronously loads the component for PaymentForm
 *
 */

import { lazyLoad } from 'utils/loadable';

export const FaqCard = lazyLoad(
  () => import('./index'),
  module => module.FaqCard,
);

export const MobileFaqCard = lazyLoad(
  () => import('./index-mobile'),
  module => module.MobileFaqCard,
);
