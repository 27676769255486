import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { mainPageSaga } from './saga';
import { MainPageState } from './types';
import { Product, AdditionalProductType, Country, Scode } from 'types/common';

export const initialState: MainPageState = {
  isFetching: false,
  isFetchingBuParam: true,
  isWaitingCategory: true,
  isWaitingProducts: true,
  isWaitingProductDetail: true,
  category: [],
  products: [],
  totalCount: 0,
  page: 1,
  productDetail: null,
  templateCategory: null,
  templates: null,
  letterTypeList: null,
  additionalProducts: null,
  countryList: null,
  currency: '',
  error: null,
  memberShip: '',
  discountType: '',
  paymentType: '',
  cardMadeTaxRate: 0,
  shippingTaxRate: 0,
  isEnableGuestMode: 1,
  isAllowMultipleProduct: 0,
  scodeList: [],
  b2bMaxQty: 0,
  b2cMaxQty: 0,
  decimalDigits: 0,
  pspProvider: '',
};

const slice = createSlice({
  name: 'mainPage',
  initialState,
  reducers: {
    fetchTimestamp(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    fetchTimestampSuccess(state, action: PayloadAction<any>) {
      state.category = action.payload;
      state.isFetching = false;
    },
    fetchTimestampFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    fetchCategory(state, action: PayloadAction<any>) {
      state.isFetching = true;
      state.isWaitingCategory = true;
      state.category = [];
      state.products = [];
      state.totalCount = 0;
      state.page = 1;
    },
    fetchCategorySuccess(state, action: PayloadAction<any>) {
      state.category = action.payload;
      state.isFetching = false;
      state.isWaitingCategory = false;
    },
    fetchCategoryFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.isWaitingCategory = false;
    },
    fetchProduct(
      state,
      action: PayloadAction<{
        categoryId: string;
        page: number;
        productName?: string;
        custId?: string;
      }>,
    ) {
      state.isFetching = true;
      state.isWaitingProducts = true;
    },
    fetchProductSuccess(
      state,
      action: PayloadAction<{
        items: Product[];
        totalCount: number;
        page: number;
      }>,
    ) {
      state.isFetching = false;
      state.isWaitingProducts = false;
      state.products = action.payload.items;
      state.totalCount = action.payload.totalCount;
      state.page = action.payload.page;
    },
    resetGiftCards(state) {
      state.products = [];
    },
    fetchProductFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.isWaitingProducts = false;
    },
    fetchProductDetail(state, action: PayloadAction<any>) {
      state.isFetching = true;
      state.isWaitingProductDetail = true;
    },
    fetchProductDetailSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.isWaitingProductDetail = false;
      state.productDetail = {
        ...state.productDetail,
        [action.payload.itemCode]: action.payload.data,
      };
    },
    fetchProductDetailFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.isWaitingProductDetail = false;
    },
    fetchTemplateCategory(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    fetchTemplateCategorySuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.templateCategory = action.payload;
    },
    fetchTemplateCategoryFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    fetchTemplates(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    fetchTemplatesSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.templates = action.payload;
    },
    fetchTemplatesFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    clearTemplateAndCategory(state) {
      state.templateCategory = null;
      state.templates = null;
    },
    fetchLetterType(state) {
      state.isFetching = true;
    },
    fetchLetterTypeSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.letterTypeList = action.payload;
    },
    fetchLetterTypeFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.letterTypeList = null;
    },
    fetchAdditionalProduct(state) {
      state.isFetching = true;
    },
    fetchAdditionalProductSuccess(
      state,
      action: PayloadAction<{ additionalProducts: AdditionalProductType[] }>,
    ) {
      state.isFetching = false;
      state.additionalProducts = action.payload.additionalProducts;
    },
    fetchAdditionalProductFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    fetchCountryList(state) {
      state.isFetching = true;
    },
    fetchCountryListSuccess(
      state,
      action: PayloadAction<{ countryList: Country[] }>,
    ) {
      state.countryList = action.payload.countryList;
      state.isFetching = false;
    },
    fetchCountryListFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    fetchSNameFetcher(state) {
      state.isFetching = true;
    },
    fetchSNameFetcherSuccess(
      state,
      action: PayloadAction<{ scodeList: Scode[] }>,
    ) {
      state.scodeList = action.payload.scodeList;
      state.isFetching = false;
    },
    fetchSNameFetcherFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    getBuParameter(state, action: PayloadAction<{ buId: string }>) {
      state.isFetching = true;
      state.isFetchingBuParam = true;
    },
    getBuParameterSuccess(
      state,
      action: PayloadAction<{
        currency: string;
        memberShip: string;
        discountType: string;
        paymentType: string;
        cardMadeTaxRate: number;
        shippingTaxRate: number;
        isEnableGuestMode: number;
        isAllowMultipleProduct: number;
        b2bMaxQty: number;
        b2cMaxQty: number;
        decimalDigits: number;
        pspProvider: string;
      }>,
    ) {
      state.isFetching = false;
      state.isFetchingBuParam = false;
      const {
        currency,
        memberShip,
        discountType,
        paymentType,
        cardMadeTaxRate,
        shippingTaxRate,
        isAllowMultipleProduct,
        isEnableGuestMode,
        b2bMaxQty,
        b2cMaxQty,
        decimalDigits,
        pspProvider,
      } = action.payload;
      state.currency = currency;
      state.memberShip = memberShip;
      state.discountType = discountType;
      state.paymentType = paymentType;
      state.cardMadeTaxRate = cardMadeTaxRate;
      state.shippingTaxRate = shippingTaxRate;
      state.isAllowMultipleProduct = isAllowMultipleProduct;
      state.isEnableGuestMode = isEnableGuestMode;
      state.b2bMaxQty = b2bMaxQty;
      state.b2cMaxQty = b2cMaxQty;
      state.decimalDigits = decimalDigits;
      state.pspProvider = pspProvider;
    },
    getBuParameterFailed(state, action: PayloadAction<any>) {
      state.isFetchingBuParam = false;
      state.isFetching = false;
      console.warn(action.payload);
    },
    getTemplateDiscount(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    getTemplateDiscountSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      if (state.templates) {
        state.templates = state.templates.map(template => {
          if (template.id === action.payload.id) {
            return action.payload;
          } else {
            return template;
          }
        });
      }
    },
    getTemplateDiscountFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
      console.warn(action.payload);
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
});

export const { actions: mainPageActions } = slice;

export const useMainPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: mainPageSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useMainPageSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
