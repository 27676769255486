import { fetchUnlockOrder } from 'app/APIs';
import { call, put, takeLatest } from 'redux-saga/effects';
import { unlockActions } from '.';

function* fetchUnlockOrderSaga(action) {
  try {
    const resp = yield call(fetchUnlockOrder, action.payload);
    if (!resp.success) {
      throw resp.data;
    }
    yield put(unlockActions.fetchUnlockOrderSuccess(resp.data));
  } catch (error) {
    console.warn('fetchProductDiscountSaga', error);
    yield put(unlockActions.fetchUnlockOrderFailed(error));
  }
}

export function* unlockSaga() {
  yield takeLatest(unlockActions.fetchUnlockOrder, fetchUnlockOrderSaga);
}
