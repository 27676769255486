/* --- STATE --- */
export interface OrderState {
  isFetching: boolean;
  orderNo: string;
  isConfirm: boolean | null;
  isCanceled: boolean;
  orderAmtTot?: number;
  isPayProcessing: boolean;
  isUnlock: boolean | null;
  unlockOrders: string[];
}

export const OrderDetailModal = {
  amount: 0,
  deliverDate: 'string',
  deliverType: 'string',
  extraItemCode: 'string',
  itemCode: 'string',
  letterTypeId: 'string',
  message: 'string',
  message2: 'string',
  personalImageUrl: 'string',
  qty: 0,
  receiverAddress: 'string',
  receiverCity: 'string',
  receiverCountryCode: 'string',
  receiverEmail: 'string',
  receiverMobileNo: 'string',
  receiverName: 'string',
  receiverVatNumber: 'string',
  receiverZipCode: 'string',
  senderEmail: 'string',
  senderName: 'string',
  templateId: 0,
};
