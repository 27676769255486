/**
 *
 * Asynchronously loads the component for B2BBuyingPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SalesmanBuyingPage = lazyLoad(
  () => import('./index'),
  module => module.SalesmanBuyingPage,
);
