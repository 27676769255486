import ReactGA from 'react-ga4';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { authSaga } from './saga';
import {
  AccountCategory,
  AuthState,
  RegisterInput,
  LoginInput,
  CustType,
  ForgetPasswordInfo,
} from './types';
import { decryption } from 'helpers/crypto';
import { customLocalStorage, getBuIdFromUrl } from 'helpers';

const getUserFromLocalStorage = () => {
  try {
    const url = window.location.href;
    if (url.includes('autoLogin') && url.includes('token')) {
      return null; // if IP is changed, autoLogin will get 401 and redirect when getMemberProfile
    }

    const buId = getBuIdFromUrl(true);
    const { getItemLs } = customLocalStorage(buId);

    const buInitTokenLimit = getItemLs('initTokeLimit');

    const localAccessToken = decryption(
      buInitTokenLimit
        ? getItemLs('accessToken')
        : localStorage.getItem('accessToken'),
    );
    const localCustName = buInitTokenLimit
      ? getItemLs('custName')
      : localStorage.getItem('custName');
    const localCustId = decryption(
      buInitTokenLimit ? getItemLs('custId') : localStorage.getItem('custId'),
    );
    const localCustType = decryption(
      buInitTokenLimit
        ? getItemLs('custType')
        : localStorage.getItem('custType'),
    );
    const localPaymentType = buInitTokenLimit
      ? getItemLs('paymentType')
      : localStorage.getItem('paymentType');

    return localAccessToken && localCustName && localCustId
      ? {
          accessToken: localAccessToken || '',
          custName: localCustName || '',
          custId: localCustId || '',
          custType: localCustType || '',
          paymentType: localPaymentType || '',
        }
      : null;
  } catch (error) {
    return null;
  }
};

const getb2bRegisterTokenStorage = () => {
  try {
    const buId = getBuIdFromUrl(true);
    const { getItemLs } = customLocalStorage(buId);

    const token = decryption(getItemLs('b2bRegisterToken'));
    return token || '';
  } catch (error) {
    return '';
  }
};

const getb2bRegisterEmailStorage = () => {
  try {
    const buId = getBuIdFromUrl(true);
    const { getItemLs } = customLocalStorage(buId);

    const token = decryption(getItemLs('b2bRegisterEmail'));
    return token || '';
  } catch (error) {
    return '';
  }
};

const getb2bRegisterTempIdStorage = () => {
  try {
    const buId = getBuIdFromUrl(true);
    const { getItemLs } = customLocalStorage(buId);

    const token = decryption(getItemLs('b2bRegisterTempId'));
    return token || '';
  } catch (error) {
    return '';
  }
};

export const initialState: AuthState = {
  isFetching: false,
  error: null,
  user: getUserFromLocalStorage(),
  queryCompanyList: [],
  accountCategoryList: [],
  queryTemplateId: '',
  otpId: '',
  forgetPasswordInfo: { email: '', responseState: false },
  verifyResetTokenInfo: { state: null, token: '', memberType: '' },
  confirmResetState: null,
  b2bRegisterEmail: getb2bRegisterEmailStorage(),
  b2bRegisterToken: getb2bRegisterTokenStorage(),
  b2bRegisterTempId: getb2bRegisterTempIdStorage(),
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    register(state, action: PayloadAction<RegisterInput>) {
      if (state.error) {
        state.error = null;
      }
      state.isFetching = true;
    },
    registerSuccess(state, action: PayloadAction<{ emailValid: boolean }>) {
      state.isFetching = false;
      state.user = {
        emailValid: action.payload.emailValid,
      };
      state.error = null;
      ReactGA.event('sign_up', { method: 'email' });
    },
    registerFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    registerConfirm(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    registerConfirmSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.user = action.payload;
    },
    registerConfirmFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    login(state, action: PayloadAction<LoginInput>) {
      state.isFetching = true;
    },
    loginSuccess(
      state,
      action: PayloadAction<{
        accessToken: string;
        custId: string;
        custName: string;
        paymentType: string;
        custType: CustType;
      }>,
    ) {
      state.isFetching = false;
      state.user = {
        custId: action.payload.custId,
        custName: action.payload.custName,
        accessToken: action.payload.accessToken,
        paymentType: action.payload.paymentType,
        custType: action.payload.custType,
      };
    },
    loginFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    forgotPassword(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    forgotPasswordResponse(state, action: PayloadAction<ForgetPasswordInfo>) {
      state.isFetching = false;
      state.forgetPasswordInfo = action.payload;
    },
    verifyResetToken(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    verifyResetTokenResponse(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.verifyResetTokenInfo = action.payload;
    },
    confirmResetPassword(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    confirmResetPasswordResponse(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.confirmResetState = action.payload;
    },
    b2bLogin(state, action: PayloadAction<LoginInput>) {
      state.isFetching = true;
    },
    b2bLoginSuccess(
      state,
      action: PayloadAction<{
        accessToken: string;
        custId: string;
        custName: string;
        paymentType: string;
        custType: CustType;
      }>,
    ) {
      state.isFetching = false;
      state.user = {
        custId: action.payload.custId,
        custName: action.payload.custName,
        accessToken: action.payload.accessToken,
        paymentType: action.payload.paymentType,
        custType: action.payload.custType,
      };
    },
    b2bLoginFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    salesmanLogin(state, action: PayloadAction<LoginInput>) {
      state.isFetching = true;
    },
    salesmanLoginSuccess(
      state,
      action: PayloadAction<{
        accessToken: string;
        custId: string;
        custName: string;
        paymentType: string;
        custType: CustType;
      }>,
    ) {
      state.isFetching = false;
      state.user = {
        custId: action.payload.custId,
        custName: action.payload.custName,
        accessToken: action.payload.accessToken,
        paymentType: action.payload.paymentType,
        custType: action.payload.custType,
      };
    },
    salesmanLoginFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    getErrorWithOAuth(state, action: PayloadAction<any>) {
      state.error = action.payload;
    },
    getQueryCompany(state, actions: PayloadAction<any>) {
      if (state.error) {
        state.error = null;
      }
      state.isFetching = true;
    },
    getQueryCompanySuccess(state, actions: PayloadAction<any>) {
      state.isFetching = false;
      state.queryCompanyList = actions.payload.companyList;
      state.queryTemplateId = actions.payload.tempId;
    },
    getQueryCompanyFailed(state, actions: PayloadAction<any>) {
      if (state.queryCompanyList.length > 0) {
        state.queryCompanyList = [];
      }
      state.isFetching = false;
      state.error = actions.payload;
    },
    getB2bRegister(state, actions: PayloadAction<any>) {
      state.isFetching = true;
    },
    getB2bRegisterSuccess(state, actions: PayloadAction<any>) {
      state.isFetching = false;
    },
    getB2bRegisterFailed(state, actions: PayloadAction<any>) {
      state.isFetching = false;
      state.error = actions.payload;
    },
    sendOtp(state, actions: PayloadAction<any>) {
      state.isFetching = true;
    },
    sendOtpSuccess(state, actions: PayloadAction<any>) {
      state.isFetching = false;
      state.otpId = actions.payload.otpId;
    },
    sendOtpFailed(state, actions: PayloadAction<any>) {
      state.isFetching = false;
      state.error = actions.payload;
    },
    confirmOtp(state, actions: PayloadAction<any>) {
      state.isFetching = true;
      actions.payload.otpId = state.otpId;
    },
    confirmOtpSuccess(state, actions: PayloadAction<any>) {
      state.isFetching = false;
    },
    confirmOtpFailed(state, actions: PayloadAction<any>) {
      state.isFetching = false;
      state.error = actions.payload;
    },
    getB2bRegisterAccount(state, actions: PayloadAction<any>) {
      state.isFetching = true;
    },
    getB2bRegisterAccountSuccess(
      state,
      actions: PayloadAction<{ token: string; email: string }>,
    ) {
      const { token, email } = actions.payload;
      state.isFetching = false;
      state.b2bRegisterEmail = email;
      state.b2bRegisterToken = token;
    },
    getB2bRegisterAccountFailed(state, actions: PayloadAction<any>) {
      state.isFetching = false;
      state.error = actions.payload;
    },
    getB2bRegisterConfirmToken(state, actions: PayloadAction<any>) {
      state.isFetching = true;
    },
    getB2bRegisterConfirmTokenSuccess(
      state,
      actions: PayloadAction<{ token: string }>,
    ) {
      const { token } = actions.payload;
      state.isFetching = false;
      state.b2bRegisterEmail = '';
      state.b2bRegisterToken = '';
      state.b2bRegisterTempId = token;
    },
    getB2bRegisterConfirmTokenFailed(state, actions: PayloadAction<any>) {
      state.isFetching = false;
      state.error = actions.payload;
    },
    getB2bConfirmRegister(state, actions: PayloadAction<any>) {
      state.isFetching = true;
    },
    getB2bConfirmRegisterSuccess(state, actions: PayloadAction<any>) {
      state.isFetching = false;
      state.b2bRegisterTempId = '';
    },
    getB2bConfirmRegisterFailed(state, actions: PayloadAction<any>) {
      state.isFetching = false;
      state.error = actions.payload;
    },
    clearError(state) {
      state.error = null;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = {
        code: 1234,
        message: action.payload,
      };
    },
    clearQueryCompany(state) {
      state.queryCompanyList = [];
    },
    resetB2bRegisterDate(state) {
      state.queryCompanyList = [];
      state.queryTemplateId = '';
    },
    fetchStart(state) {
      state.isFetching = true;
    },
    fetchEnd(state) {
      state.isFetching = false;
    },
    fetchAccountCategory(state) {
      state.isFetching = true;
    },
    fetchAccountCategorySuccess(
      state,
      action: PayloadAction<{ accountCategoryList: AccountCategory[] }>,
    ) {
      state.accountCategoryList = action.payload.accountCategoryList;
      state.isFetching = false;
    },
    fetchAccountCategoryFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    fetchB2BResendToken(state, action: PayloadAction<{ accountId: string }>) {
      state.isFetching = true;
    },
    fetchB2BResendTokenSuccess(
      state,
      action: PayloadAction<{
        email: string;
        tokenId: string;
      }>,
    ) {
      state.isFetching = false;
      state.b2bRegisterEmail = action.payload.email;
      state.b2bRegisterToken = action.payload.tokenId;
    },
    fetchB2BResendTokenFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
  },
});

export const { actions: authActions } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAuthSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
