import { getBuIdFromUrl } from 'helpers';
import { BuConstant } from 'types/BuConstant';
import { buList } from './buList';

var bu: BuConstant | undefined = undefined;

export const getBuConfig = () => {
  if (!bu) {
    bu =
      buList?.find(data => data.buId === getBuIdFromUrl(true).toUpperCase()) ??
      buList[0];
  }
  return bu;
};
