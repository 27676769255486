export const SessionStorageKey = [
  'isAllowToContinueAsGuest',
  'guestName',
  'guestEmail',
  'guestDocType',
  'guestIdCardNo',
  'lang',
  'cl',
];

export const LocalStorageKey = [
  'initTokeLimit',
  'oauthCode',
  'walletBalance',
  'tempWalletBalance',
  'companyInfo',
  'contactInfo',
  'eliveryInfo',
  'invoiceInfo',
  'contactEmail',
  'accessToken',
  'custName',
  'custId',
  'custType',
  'paymentType',
  'socialMediaName',
  'accessToken',
  'selectCardNo',
  'topupBalance',
  'itemCode',
  'isWidgetFormOpen',
  'flag',
  'refreshToken',
  'b2bRegisterToken',
  'b2bRegisterEmail',
  'b2bRegisterTempId',
  'timeRequest',
  'timeResponse',
  'orders',
];

export const CookiesKey = [
  'initToken',
  'initTokeLimit',
  'csrfToken',
  'paymentLogId',
  'LE',
  'LMC',
  'LMN',
];
