/**
 *
 * Asynchronously loads the component for B2BBuyingSelfPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const B2BBuyingSelfPage = lazyLoad(
  () => import('./index'),
  module => module.B2BBuyingSelfPage,
);
