import aboutBanner from './footerPagesImg/about/kv-banner.png';

const about = `
<div class="about">
  <a href="/" class="home">
    <img alt="" />
    Home
  </a>
  <div class="title">À propos de</div>
  <img src="${aboutBanner}" alt="" />
  <div class="subtitle"><h4>Qui sommes nous ?</h4></div>
  <div class="content">
    <p>
      Désormais Auchan et toute l’équipe Carte Cadeau sont heureux de vous
      proposer ce site de vente en ligne de Cartes Cadeaux personnalisées.
    </p>
    <p>
      Vous aurez ainsi la possibilité de mettre un petit message personnalisé au
      dos de votre Carte Cadeau !
    </p>
    <p>
      Nous vous proposons un service Carte Cadeau totalement personnalisable où
      vous pourrez insérer la photo de votre choix ainsi qu’un espace texte pour
      le bénéficiaire de la carte. Les photos téléchargées ne seront en aucun cas
      gardées dans la base de données Auchan.
    </p>
    <p>
      L’équipe Carte Cadeau fait tout son possible pour que les Cartes Cadeaux
      soient utilisables sur nos sites internet Auchan.fr, Auchandirect.fr et
      Auchandrive.fr très prochainement. Nous mettons tout en œuvre également pour
      vous proposer des offres les plus attractives possibles et de faire évoluer
      régulièrement nos collections afin de satisfaire toutes vos envies !
    </p>
    <p>
      Auchan vous a sélectionné le meilleur façonnier de Cartes Cadeaux afin de
      vous garantir un maximum de professionnalisme dans la fabrication,
      l’impression et l’envoi de vos cartes.
    </p>
    <p>
      Vous retrouverez sur le site carte-cadeau.auchan.fr, les Cartes Cadeaux
      utilisables dans tous les rayons de plus de 400 magasins hypermarchés et
      supermarchés Auchan ainsi que dans les caisses des Drives Auchan. Vous
      pourrez ainsi toujours avoir une idée de cadeau que ce soit pour des
      vêtements, des spectacles, tout ce qui concerne le multimédia ou encore le
      rayon vin !
    </p>
    <p>
      Venez vite découvrir toutes nos gammes de Cartes Cadeaux pour le plus grand
      plaisir de vos proches !
    </p>
    <p>
      Vous êtes une entreprise ? Une collectivité ? Et vous souhaitez offrir des
      Cartes Cadeaux pour vos collaborateurs ? Rendez-vous sur notre site
      www.Auchanpro.fr pour plus d’informations.
    </p>
    <br /><br />
    <p>
      "Auchan France émet des cartes cadeaux dans le cadre des dispositions des
      articles L525-1 et suivants du Code monétaire et financier sous la
      surveillance de la Banque de France."
    </p>
  </div>
</div>
`;

const generalCondition = `
<div class="general-condition">
  <a href="/" class="home">
    <img alt="" />
    Home
  </a>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <div class="article0">
    <div class="subtitle">Majid Al Futtaim - Hypermarket LLC Gift Card Terms and Conditions</div>
  </div>
  <div class="article1">
    <div class="subtitle">1. Issuance and Redemption:</div>
    <p>1.1 The Majid Al Futtaim - Hypermarket LLC Gift Card is issued by Majid Al Futtaim - Hypermarket LLC.</p>
    <p>1.2 The Gift Card can be redeemed for eligible purchases at participating Majid Al Futtaim - Hypermarket, supermarkets and city store locations or online.</p>
    <p>1.3 The Gift Card is not redeemable for cash and cannot be returned for a cash refund.</p>
  </div>
  <div class="article2">
    <div class="subtitle">2. Activation and Expiry:</div>
    <p>2.1 The Gift Card must be activated at the time of purchase.</p>
    <p>2.2 The Gift Card expires 12 months from the date of activation.</p>
    <p>2.3 Expired Gift Cards cannot be reactivated or used for purchases.</p>
  </div>
  <div class="article3">
    <div class="subtitle">3. Balance and Reload:</div>
    <p>3.1 The remaining balance on the Gift Card can be checked at any Majid Al Futtaim - Carrefour location or online.</p>
    <p>3.2 The Gift Card is not reloadable.</p>
  </div>
  <div class="article4">
    <div class="subtitle">4. Lost, Stolen, or Damaged Cards:</div>
    <p>4.1 Majid Al Futtaim - Hypermarket LLC is not responsible for lost, stolen, or damaged Gift Cards.</p>
    <p>4.2 Lost, stolen, or damaged Gift Cards will not be replaced.</p>
  </div>
  <div class="article5">
    <div class="subtitle">5. Fraud Prevention:</div>
    <p>5.1 Majid Al Futtaim - Hypermarket LLC reserves the right to refuse to honor a Gift Card if it believes the card was obtained fraudulently or through unauthorized means.</p>
  </div>
  <div class="article6">
    <div class="subtitle">6. Restrictions:</div>
    <p>6.1 The Gift Card cannot be used to purchase other gift cards.</p>
    <p>6.2 The Gift Card cannot be used for unauthorized or illegal purchases.</p>
  </div>
  <div class="article7">
    <div class="subtitle">7. Contact Information:</div>
    <p>7.1 For inquiries or assistance regarding the Gift Card, please contact</p>
    <p>Carrefouruae.com LLC  P.O Box No: 22797</p>
    <p>Tel : 80073232</p>
  </div>
  <div class="article8">
    <p>By purchasing and/or using the Gift Card, the cardholder agrees to abide by these terms and conditions. Majid Al Futtaim - Hypermarket LLC reserves the right to change or modify these terms at any time.</p>
  </div>
  <hr>
</div>`;

const personalData = `
<div class="personal-data">
   <a href="/" class="home">
     <img alt="" />
     Home
   </a>
   <div class="title">Données personnelles</div>
   <p>AuchanPro.fr utilise des cookies, déposés par Auchan et/ou ses partenaires :</p>
   <p class="subtitle">Les cookies Tiers :</p>
   <p>En fonction des choix que vous avez pu exercer concernant les cookies (§ Vos choix concernant l'utilisation des cookies), des cookies sont émis/lus par des tiers (prestataires de publicité, agences de communication, sociétés de mesure d'audience, …) lorsque vous naviguez sur notre site.</p>
   <p>Les cookies permettent à ces sociétés, pendant la durée de validité de ces cookies, de déterminer, en vue de vous les adresser, les contenus publicitaires susceptibles de correspondre à vos centres d'intérêts grâce à la connaissance de votre navigation sur notre site</p>
   <p>L'utilisation des cookies par ces sociétés est régie par leurs propres politiques de protection de la vie privée.</p>
   <p class="subtitle">Réseaux Sociaux :</p>
   <p>Afin de vous donner la possibilité de partager des contenus ou de donner votre opinion sur nos produits ou services, nous pouvons être amenés à intégrer dans notre site des éléments (boutons, plug-ins, …) en lien avec les réseaux sociaux.</p>
   <p>Ces éléments, permettent aux réseaux sociaux de suivre votre navigation, à la condition que vous soyez connecté au réseau social sur votre navigateur (même s'il n'y a pas eu d'utilisation du bouton "partager", par exemple)</p>
   <p>Les conditions d'utilisation des informations liées à ces éléments sont définies par le réseau social dont vous êtes membre, nous n'avons aucun contrôle sur les actions réalisées. Nous vous invitons à consulter les politiques de protection de la vie privée de ces réseaux sociaux afin de prendre connaissance de l'utilisation qui est faite des données collectées lors de votre navigation et des moyens de paramétrer la confidentialité de vos comptes.</p>
   <p class="subtitle">Les cookies "Flash"© :</p>
   <p>"Adobe Flash Player"™ est une solution informatique très répandue permettant la création de contenus multimédias interactifs. Elle utilise, elle aussi, de petits fichiers pour stocker des informations mais la technologie mise en œuvre est différente de celle utilisée pour les cookies. La gestion de vos choix concernant la confidentialité doit être gérée de manière spécifique.</p>
   <p>Vous pouvez gérer le contenu Flash lié à votre navigation via le gestionnaire d’Adobe Flash Player :</p>
   <a href="http://www.adobe.com/support/documentation/fr/flashplayer/help/settings_manager07.html">http://www.adobe.com/support/documentation/fr/flashplayer/help/settings_manager07.html</a>
   <p class="subtitle mt-3">Si l'utilisation de votre terminal est partagée par plusieurs personnes :</p>
   <p>Les cookies étant liés au terminal et au navigateur, nous ne pouvons pas nous assurer de manière certaine de l'identité de la personne naviguant sur ce terminal. Il est donc possible que les services et publicités qui vous seront proposés sur ce terminal ne correspondent qu'en partie à votre utilisation (la navigation/les préférences des autres utilisateurs du terminal pouvant être prises en compte).</p>
   <p>La confidentialité de votre terminal résulte de vos choix, aussi bien dans son utilisation (personnes ayant accès) que dans son paramétrage (concernant notamment les cookies).</p>
   <p class="subtitle">Vos choix concernant l'utilisation des cookies :</p>
   <p>A tout instant, plusieurs options sont possibles pour gérer les cookies et sont précisées ci-après.</p>
   <p>Les paramétrages que vous êtes susceptibles de réaliser peut avoir un impact sur votre navigation internet et sur le fonctionnement de certains services nécessitant l'utilisation de cookies. A ce titre, nous déclinons toute responsabilité pour les conséquences liées au fonctionnement dégradé de nos services, résultant de l’impossibilité d’enregistrer ou de consulter un cookie sur votre terminal.</p>
   <p class="subtitle">Navigateur sur ordinateur (PC):</p>
   <p>Au travers du paramétrage de votre navigateur, vous pouvez mener un certain nombre d'actions vous permettant d'administrer les cookies afin d'en accepter ou d'en refuser l'utilisation soit de manière globale soit de manière plus précise et ce à tout moment.</p>
   <p>Les éléments de paramétrage étant spécifiques à chaque navigateur nous vous proposons de suivre le lien correspondant à votre navigateur pour en savoir plus :</p>
   <p>Pour gérer les cookies sur Google Chrome :</p>
   <a href="https://support.google.com/chrome/answer/95647?hl=fr">
      <p>https://support.google.com/chrome/answer/95647?hl=fr</p>
   </a>
   <p>Pour gérer les cookies sur Internet Explorer :</p>
   <a href="http://windows.microsoft.com/fr-fr/internet-explorer/delete-manage-cookies#ie=ie-11">
      <p>http://windows.microsoft.com/fr-fr/internet-explorer/delete-manage-cookies#ie=ie-11</p>
   </a>
   <p>Pour gérer les cookies sur Mozilla Firefox :</p>
   <a href="https://support.mozilla.org/fr/kb/effacer-cookies-supprimer-infos-sites-enregistrees">
      <p>https://support.mozilla.org/fr/kb/effacer-cookies-supprimer-infos-sites-enregistrees</p>
   </a>
   <p>Pour gérer les cookies sur Safari :</p>
   <a href="http://support.apple.com/kb/ht1677?viewlocale=fr_FR&amp;locale=fr_FR">
      <p>http://support.apple.com/kb/ht1677?viewlocale=fr_FR&amp;locale=fr_FR</p>
   </a>
   <p class="mt-3">Pour les autres terminaux (smartphones, tablettes, objets connectés), nous vous invitons à consulter le menu d’aide de votre navigateur.</p>
   <p class="subtitle">Plateformes interprofessionnelles :</p>
   <p>Certaines plateformes interprofessionnelles permettent la gestion en ligne des cookies. A titre d'exemple le site YourOnlineChoices, qui est proposé par de nombreux professionnels de la publicité digitale regroupés au sein de l'association européenne EDAA (European Digital Advertising Alliance) et géré en France par l'Interactive Advertising Bureau (IAB) France.</p>
   <p>Vous pouvez, pour les entreprises inscrites sur cette plateforme, choisir d'activer ou de désactiver l'utilisation de cookies pour vous proposer des publicités correspondant à vos centres d'intérêts :</p>
   <a href="http://www.youronlinechoices.com/fr/controler-ses-cookies/">
      <p>http://www.youronlinechoices.com/fr/controler-ses-cookies/</p>
   </a>
   <p>Cette action n'empêche pas l'affichage de publicités sur les sites que vous consultez, elle permet (pour les entreprises inscrites) d'empêcher la collecte et l'utilisation de données dans l'objectif de la faire correspondre à vos centres d'intérêts.</p>
   <p class="subtitle">Autres Outils :</p>
   <p>En complément des solutions évoquées précédemment, il existe des outils (sous forme de plug-ins ou add-ons pour le navigateur), développés par des sociétés tierces, permettant de maîtriser la collecte et l’utilisation des cookies.</p>
   <p class="subtitle">Information complémentaire sur les cookies :</p>
   <div>Pour une information complémentaire sur les cookies, vous pouvez visiter le site de la Commission Nationale de l'Informatique et des Libertés (CNIL) :</div>
   <a href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/">
      <p>http://www.cnil.fr/vos-droits/vos-traces/les-cookies/</p>
   </a>
</div>

`;

const legalNotices = `
<div class="legal-notices">
   <a href="/" class="home">
     <img alt="" />
     Home
   </a>
   <div class="title">Mentions légales</div>
   <p>Ce site est édité par la société CARREFOUR FRANCE, Société par Actions Simplifiée locataire gérant au capital de 20 000 000 euros. BP 60075 - 91002 Evry Cedex. RCS Evry 451 321 335</p>
   <p>Nous avons choisi pour son hébergement la société OGLOBA SRL, dont le siège social est situé 8 avenue centrale – 1950 Kraainem - Belgique, immatriculée au Registre du Commerce et des Sociétés de Lille sous le numéro 424 761 419 00045.</p>
   <p>Contact de la société Ogloba SRL: <a href = "mailto: support@ogloba.com">support@ogloba.com</a></p>
   <p>Administratrice déléguée : Madame Zoé VAN DER SCHUEREN</p>
</div>
`;

const deliveryInfo = `
<div class="delivery-info">
   <a href="/" class="home">
     <img alt="" />
     Home
   </a>
   <div class="title">Informations de livraison</div>
   <p>Information livraison des cartes cadeaux.</p>
   <p>La carte cadeau est livrée par lettre suivie par La poste. Comptez 3 ou 4 jours ouvrés de délai de livraison : 2 jours ouvrés de fabrication de votre carte puis 48h de délai après l’expédition. La livraison de votre carte et son courrier personnalisé se fait uniquement en France métropolitaine (hors Corse et DOM TOM).</p>
   <p>Vous pouvez suivre votre commande sur le site www.laposte.fr en renseignant le numéro de suivi transmis dans l’e-mail de confirmation de l’expédition.</p>
   <p>Si vous êtes toujours en difficulté, n’hésitez pas à contacter notre service clients.</p>
   <p>Les frais de livraison sont de 4 € TTC par expédition.</p>
</div>

`;

export { about, generalCondition, personalData, legalNotices, deliveryInfo };
