/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 *
 * B2BPage
 *
 */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import ItemsCarousel from 'react-items-carousel';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { messages } from './messages';

import OpenPreviewIcon from 'img/B2Binfomation/section1/icon/open_preview.svg';
import ArrowRightIcon from 'img/B2Binfomation/section1/icon/arrow_right.svg';
import GapIcon from 'img/B2Binfomation/section1/pic/gap.png';
import SectionIcon2 from 'img/B2Binfomation/section2/icon/02.svg';
import SectionIcon4 from 'img/B2Binfomation/section2/icon/04.svg';
import { AiFillDollarCircle, AiFillLock } from 'react-icons/ai';
import ADApp from 'img/B2Binfomation/section3/pic/AD_app.png';
import Section5GapIcon from 'img/B2Binfomation/section5/pic/gap.png';
import Section5Icon1 from 'img/B2Binfomation/section5/icon/01.svg';
import Section5Icon2 from 'img/B2Binfomation/section5/icon/02.svg';
import Section5Icon3 from 'img/B2Binfomation/section5/icon/03.svg';
import Section5Icon4 from 'img/B2Binfomation/section5/icon/04.svg';
import IntroImage from 'img/B2Binfomation/section1/pic/intro.png';
import Section6Icon1 from 'img/B2Binfomation/section6/1.png';
import Section6Icon2 from 'img/B2Binfomation/section6/2.png';
import Section6Icon3 from 'img/B2Binfomation/section6/3.png';
import Section6Icon4 from 'img/B2Binfomation/section6/4.png';
import Section7Icon1 from 'img/B2Binfomation/section7/1.png';
import Section7Icon2 from 'img/B2Binfomation/section7/2.png';
import Section7Icon3 from 'img/B2Binfomation/section7/3.png';
import Section7Icon4 from 'img/B2Binfomation/section7/4.png';
import Section7IconBack1 from 'img/B2Binfomation/section7/back1.png';
import Section7IconBack2 from 'img/B2Binfomation/section7/back2.png';
import Section7IconBack3 from 'img/B2Binfomation/section7/back3.png';

import informationPDF1 from 'img/B2Binfomation/B2B-information-PDF/1.png';
import informationPDF2 from 'img/B2Binfomation/B2B-information-PDF/2.png';
import informationPDF3 from 'img/B2Binfomation/B2B-information-PDF/3.png';

import { authCheck } from 'app/APIs';
import { selectAuthUser } from 'app/pages/AuthPage/slice/selectors';
import { getBuConfig } from 'helpers/BuHelper';

interface Props {}

enum B2BTabType {
  Giftcard,
  Ticketing,
  Colis,
}

enum OverflowDirection {
  None,
  Right,
  Left,
}

const Section7Card = ({ icon, name, content }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isHover, setHover] = useState<boolean>(false);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const advertiseList = [
    Section7IconBack1,
    Section7IconBack2,
    Section7IconBack3,
  ];

  const handleTick = () => {
    setActiveItemIndex(activeItemIndex + 1);
  };

  useEffect(() => {
    const ticker = setInterval(handleTick, 2000);
    return () => clearInterval(ticker);
  });

  return (
    <article
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      onClick={() => history.push('/auth/b2b/login')}
    >
      <div
        className={`a-b2b-information-section7-card ${isHover ? 'hover' : ''}`}
      >
        <div className="a-b2b-information-section7-front">
          <img src={icon} alt="" />
          <h2>{name}</h2>
          <p>{content}</p>
        </div>
        <div className="a-b2b-information-section7-back">
          <ItemsCarousel
            infiniteLoop
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={1}
            gutter={0}
          >
            {map(advertiseList, advertise =>
              advertise ? <img src={advertise} alt="" /> : null,
            )}
          </ItemsCarousel>
          <button>{t(...messages.pickThisCard())}</button>
        </div>
      </div>
    </article>
  );
};

export function B2BPage(props: Props) {
  const { getAsset, buName } = getBuConfig();
  const { B2bLogo, B2bIntro, B2bSection2Icon1, B2bSection2Icon3 } = getAsset();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const [tab, setTab] = useState<B2BTabType>(B2BTabType.Giftcard);
  const [overflow, setOverflow] = useState<OverflowDirection>(
    OverflowDirection.None,
  );

  const informationPDFList = [
    {
      icon: informationPDF1,
      pdf:
        'https://s3.eu-west-3.amazonaws.com/com.ogloba.gc.resource.auchan/b2bInfoPage/DM5.pdf',
    },
    {
      icon: informationPDF2,
      pdf:
        'https://s3.eu-west-3.amazonaws.com/com.ogloba.gc.resource.auchan/b2bInfoPage/DM6.pdf',
    },
    {
      icon: informationPDF3,
      pdf:
        'https://s3.eu-west-3.amazonaws.com/com.ogloba.gc.resource.auchan/b2bInfoPage/DM7.pdf',
    },
  ];

  const section6List = [
    {
      icon: Section6Icon1,
      pdf:
        'https://s3.eu-west-3.amazonaws.com/com.ogloba.gc.resource.auchan/b2bInfoPage/DM1.pdf',
      zip:
        'https://s3.eu-west-3.amazonaws.com/com.ogloba.gc.resource.auchan/b2bInfoPage/DM1.zip',
    },
    {
      icon: Section6Icon2,
      pdf:
        'https://s3.eu-west-3.amazonaws.com/com.ogloba.gc.resource.auchan/b2bInfoPage/DM2.pdf',
      zip:
        'https://s3.eu-west-3.amazonaws.com/com.ogloba.gc.resource.auchan/b2bInfoPage/DM2.zip',
    },
    {
      icon: Section6Icon3,
      pdf:
        'https://s3.eu-west-3.amazonaws.com/com.ogloba.gc.resource.auchan/b2bInfoPage/DM3.pdf',
      zip:
        'https://s3.eu-west-3.amazonaws.com/com.ogloba.gc.resource.auchan/b2bInfoPage/DM3.zip',
    },
    {
      icon: Section6Icon4,
      pdf:
        'https://s3.eu-west-3.amazonaws.com/com.ogloba.gc.resource.auchan/b2bInfoPage/DM4.pdf',
      zip:
        'https://s3.eu-west-3.amazonaws.com/com.ogloba.gc.resource.auchan/b2bInfoPage/DM4.zip',
    },
  ];

  const section7List = [
    {
      icon: Section7Icon1,
      name: `La carte "Bons Plans !"`,
      content: 'Notre carte de revente par excellence',
    },
    {
      icon: Section7Icon2,
      name: `La carte "Bravo !"`,
      content: 'Notre carte incentive pour super collaborateurs',
    },
    {
      icon: Section7Icon3,
      name: `La carte "C'est Cadeau !"`,
      content: `Notre carte générique pour le plaisir d'offrir`,
    },
    {
      icon: Section7Icon4,
      name: `La carte "Mille Mercis !"`,
      content: 'Notre carte de remerciement, plus parlante que mille mercis !',
    },
  ];

  const responsive = {
    xxxl: {
      breakpoint: { max: 3000, min: 1400 },
      items: 4,
    },
    xxl: {
      breakpoint: { max: 1399, min: 1200 },
      items: 3,
    },
    xl: {
      breakpoint: { max: 1199, min: 992 },
      items: 2,
    },
    l: {
      breakpoint: { max: 991, min: 0 },
      items: 1,
    },
  };

  const use_sixThStreetTitle = ['6thStreet'];
  let b2bTitle;
  let b2bIconClassname = '';
  if (use_sixThStreetTitle.includes(buName)) {
    b2bTitle = (
      <h2>
        {t(...messages.sixThStreetTitleStart())}
        <span>{t(...messages.sixThStreetTitleBu())}</span>
        {t(...messages.sixThStreetTitleEnd())}
      </h2>
    );
    b2bIconClassname = 'bg_color';
  } else {
    b2bTitle = <h2>{t(...messages.section2Title())}</h2>;
  }

  // setInterval to check auth
  const user = useSelector(selectAuthUser);
  let intervalId = useRef<any>(null);
  useEffect(() => {
    intervalId.current = setInterval(() => {
      if (user) {
        authCheck(user.accessToken);
      }
    }, 30000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);
  const auchanTypeList = ['Auchan'];
  const adeoTypeList = ['LMFR'];
  return (
    <>
      <main>
        <div className="a-b2b-information-main d-flex flex-column align-items-center">
          <div className="a-b2b-information-section1">
            <img src={B2bIntro} alt="" />
            {!auchanTypeList.includes(buName) && (
              <div>
                {t(...messages.section1Title())}
                <img src={B2bLogo} alt="" />
              </div>
            )}
            {/* <h1>{t(...messages.title())}</h1>
             <div className="a-b2b-information-introHowToBuy position-relative">
               <div
                 className={`d-flex ${overflow == OverflowDirection.Right
                   ? 'a-b2b-information-scrollRight'
                   : ''
                   }
                   ${overflow == OverflowDirection.Left
                     ? 'a-b2b-information-scrollLeft'
                     : ''
                   }
                   `}
               >
                 <div className="a-b2b-information-cardBlog">
                   <img src={IntroImage} alt="IntroImage" />
                   <div>
                     <h3>{t(...messages.section1Title())}</h3>
                     <h6>{t(...messages.section1SubTitle())}</h6>
                     <a href="#">{t(...messages.sectionCTA())}</a>
                   </div>
                 </div>
                 <div className="a-b2b-information-cardBlog">
                   <a href="#">
                     {t(...messages.section1Link())}
                     <img src={OpenPreviewIcon} alt="download" />
                   </a>
                 </div>
                 <div className="a-b2b-information-cardBlog">
                   <a href="#">
                     {t(...messages.section1Link())}
                     <img src={OpenPreviewIcon} alt="download" />
                   </a>
                 </div>
                 <div className="a-b2b-information-cardBlog">
                   <a href="#">
                     {t(...messages.section1Link())}
                     <img src={OpenPreviewIcon} alt="download" />
                   </a>
                 </div>
               </div>
               <div className="a-scroll-btns">
                 <button
                   className={`${overflow === OverflowDirection.Right ? '' : 'd-none'
                     }`}
                   onClick={() => setOverflow(OverflowDirection.Left)}
                 >
                   <img src={ArrowRightIcon} alt="arrow" />
                 </button>
                 <button
                   className={`${overflow === OverflowDirection.Left ||
                     overflow == OverflowDirection.None
                     ? ''
                     : 'd-none'
                     }`}
                   onClick={() => setOverflow(OverflowDirection.Right)}
                 >
                   <img src={ArrowRightIcon} alt="arrow" />
                 </button>
               </div>
             </div> */}
          </div>
          {/* <div className="a-b2b-information-section1-gap">
             <img src={GapIcon} alt="gap" />
           </div> */}
          <div className="a-b2b-information-section2">
            {b2bTitle}
            <section>
              <div>
                <div className={b2bIconClassname}>
                  <img src={B2bSection2Icon1} alt="section2-1" />
                </div>
                <h5>{t(...messages.section2SubTitle1())}</h5>
                <h6>{t(...messages.section2Content1())}</h6>
                {/* <button>{t(...messages.section2CTA())}</button> */}
              </div>
              <div>
                <div>
                  {use_sixThStreetTitle.includes(buName) ? (
                    <img src={SectionIcon2} alt="section2-2" />
                  ) : (
                    <AiFillDollarCircle />
                  )}
                </div>
                <h5>{t(...messages.section2SubTitle2())}</h5>
                <h6>{t(...messages.section2Content2())}</h6>
                {/* <button>{t(...messages.section2CTA())}</button> */}
              </div>
              <div>
                <div className={b2bIconClassname}>
                  <img src={B2bSection2Icon3} alt="section2-3" />
                </div>
                <h5>{t(...messages.section2SubTitle3())}</h5>
                <h6>{t(...messages.section2Content3())}</h6>
                {/* <button>{t(...messages.section2CTA())}</button> */}
              </div>
              <div>
                <div>
                  {use_sixThStreetTitle.includes(buName) ? (
                    <img src={SectionIcon4} alt="section2-4" />
                  ) : (
                    <AiFillLock />
                  )}
                </div>
                <h5>{t(...messages.section2SubTitle4())}</h5>
                <h6>{t(...messages.section2Content4())}</h6>
                {/* <button>{t(...messages.section2CTA())}</button> */}
              </div>
            </section>
          </div>
          {/* <div className="a-b2b-information-section3">
             <section>
               <h1>
                 {t(...messages.section3Title1())}
                 <br />
                 {t(...messages.section3Title2())}
               </h1>
               <h4>{t(...messages.section3Content())}</h4>
               <a href="#">{t(...messages.sectionCTA())}</a>
             </section>
             <div className="a-b2b-information-ad">
               <img src={ADApp} alt="ADApp" />
               <h3>
                 {t(...messages.adTitle1())}
                 <br />
                 {t(...messages.adTitle2())}
               </h3>
             </div>
           </div> */}
          {/* <div className="a-b2b-information-section3-gap">
             <div>
               <h2>{t(...messages.section3GapTitle1())}</h2>
               <h4>{t(...messages.section3GapSubTitle1())}</h4>
             </div>
             <i></i>
             <div>
               <h3>{t(...messages.section3GapTitle2())}</h3>
               <h5>{t(...messages.section3GapSubTitle2())}</h5>
             </div>
             <div>
               <h3>{t(...messages.section3GapTitle3())}</h3>
               <h5>{t(...messages.section3GapSubTitle3())}</h5>
             </div>
             <div>
               <h3>{t(...messages.section3GapTitle4())}</h3>
               <h5>{t(...messages.section3GapSubTitle4())}</h5>
             </div>
           </div> */}
          {/* <div className="a-b2b-information-section4">
             <h1>{t(...messages.section4Title())}</h1>
             <h4>
               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
               eiusmod tempor incididunt ut labore et dolore magna aliqua.
             </h4>
             <a className="a-b2b-information-section4-btn" href="#">
               {t(...messages.section4CTA())}
             </a>
             <section>
               <a
                 role="button"
                 className={`a-b2b-information-section4-tabs ${
                   tab == B2BTabType.Giftcard
                     ? 'a-b2b-information-section4-tabs-active'
                     : ''
                 }`}
                 onClick={() => setTab(B2BTabType.Giftcard)}
               >
                 <div>
                   <h3>{t(...messages.section4TabTitle1())}</h3>
                   <h5>
                     Les masques sont disponibles uniquement sur demande de
                     devis.
                   </h5>
                 </div>
               </a>
               <a
                 className={`a-b2b-information-section4-tabs ${
                   tab == B2BTabType.Ticketing
                     ? 'a-b2b-information-section4-tabs-active'
                     : ''
                 }`}
                 onClick={() => setTab(B2BTabType.Ticketing)}
               >
                 <div>
                   <h3>{t(...messages.section4TabTitle2())}</h3>
                   <h5>
                     Les masques sont disponibles uniquement sur demande de
                     devis.
                   </h5>
                 </div>
               </a>
               <a
                 className={`a-b2b-information-section4-tabs ${
                   tab == B2BTabType.Colis
                     ? 'a-b2b-information-section4-tabs-active'
                     : ''
                 }`}
                 onClick={() => setTab(B2BTabType.Colis)}
               >
                 <div>
                   <h3>Colis gourmands</h3>
                   <h5>
                     Les masques sont disponibles uniquement sur demande de
                     devis.
                   </h5>
                 </div>
               </a>
             </section>
           </div> */}
          {/* <div className="a-b2b-information-section5">
             <div>
               <img
                 className={`a-b2b-information-section5-tab ${
                   tab !== B2BTabType.Giftcard && 'd-none'
                 }`}
                 src={Section5GapIcon}
                 alt="gap5"
               />
               <img
                 className={`a-b2b-information-section5-tab ${
                   tab !== B2BTabType.Ticketing && 'd-none'
                 }`}
                 src={Section5GapIcon}
                 alt="gap5"
               />
               <img
                 className={`a-b2b-information-section5-tab ${
                   tab !== B2BTabType.Colis && 'd-none'
                 }`}
                 src={Section5GapIcon}
                 alt="gap5"
               />
             </div>
             {tab === B2BTabType.Giftcard && (
               <h1>{t(...messages.section4TabTitle1())}</h1>
             )}
             {tab === B2BTabType.Ticketing && (
               <h1>{t(...messages.section4TabTitle2())}</h1>
             )}
             {tab === B2BTabType.Colis && (
               <h1>{t(...messages.section4TabTitle1())}</h1>
             )}
             <section>
               <div>
                 <img src={Section5Icon1} alt="s5-1" />
                 <h5>{t(...messages.section2SubTitle())}</h5>
                 <h6>{t(...messages.section2Content())}</h6>
               </div>
               <div>
                 <img src={Section5Icon2} alt="s5-2" />
                 <h5>{t(...messages.section2SubTitle())}</h5>
                 <h6>{t(...messages.section2Content())}</h6>
               </div>
               <div>
                 <img src={Section5Icon3} alt="s5-3" />
                 <h5>{t(...messages.section2SubTitle())}</h5>
                 <h6>{t(...messages.section2Content())}</h6>
               </div>
               <div>
                 <img src={Section5Icon4} alt="s5-4" />
                 <h5>{t(...messages.section2SubTitle())}</h5>
                 <h6>{t(...messages.section2Content())}</h6>
               </div>
             </section>
           </div> */}
          {!adeoTypeList.includes(buName) && (
            <>
              {' '}
              <div className="a-b2b-information-section7">
                <h1>{t(...messages.section7Title())}</h1>
                <Carousel
                  containerClass="a-card-all d-flex"
                  responsive={responsive}
                  partialVisible={true}
                >
                  {section7List.map(data => (
                    <Section7Card
                      icon={data.icon}
                      name={data.name}
                      content={data.content}
                    />
                  ))}
                </Carousel>
              </div>
              <div className="a-b2b-information-section6">
                <h1>{t(...messages.section6Title())}</h1>
                <section>
                  {informationPDFList.map(data => {
                    return (
                      <article>
                        <img
                          src={data.icon}
                          alt=""
                          onClick={() => window.open(data.pdf)}
                        />
                      </article>
                    );
                  })}
                </section>
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
}
