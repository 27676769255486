import i18next from 'locales/i18n';
import { call, put, takeLatest } from 'redux-saga/effects';
import { orderActions as actions } from '.';
import { PopupImgType } from 'types/common';

import { OrderDetailModal } from './types';
import {
  orderCreate,
  orderConfirm,
  orderCancel,
  orderQuotate,
  orderUnlock,
} from 'app/APIs';
import { map, pick, keys, toNumber } from 'lodash';
import { getBuIdFromUrl } from 'helpers';

function* orderCreateSaga(action) {
  const { orders, callback } = action.payload;

  const formattedOrders = map(orders, order =>
    pick(
      {
        ...order.data,
        templateId: toNumber(order.data.templateId),
      },
      keys(OrderDetailModal),
    ),
  );

  const resp = yield call(orderCreate, {
    aggregatorBuId: getBuIdFromUrl(true),
    custId: 'custId',
    order: formattedOrders,
  });
  console.log('orderCreateSaga', resp);
  if (!resp.success) {
    console.warn('orderCreateSaga', resp);
    yield put(actions.orderCreateFailure(resp));
  } else {
    yield put(actions.orderCreateSuccess(resp.data.orderNo));
    callback && callback(resp.data.orderNo);
  }
}

function* orderConfirmSaga(action) {
  const resp = yield call(orderConfirm, action.payload, getBuIdFromUrl(true));
  if (!resp.success) {
    console.warn(resp);
    yield put(actions.orderConfirmFailure(resp));
  } else {
    if (resp.data.error?.message) {
      console.warn(resp);
      yield put(actions.orderConfirmFailure(resp));
    } else {
      const { orderAmtTot, orderNo } = resp.data;
      yield put(actions.orderConfirmSuccess({ orderAmtTot, orderNo }));
    }
  }
}

function* orderUnlockSaga(action) {
  const { orderNo } = action.payload;
  const resp = yield call(orderUnlock, action.payload);
  if (!resp.success) {
    console.warn(resp);
    yield put(actions.orderUnlockFailure(resp));
  } else {
    yield put(actions.orderUnlockSuccess({ orderNo }));
  }
}

function* orderCancelSaga(action) {
  const resp = yield call(orderCancel, action.payload);
  if (!resp.success) {
    console.warn(resp);
    yield put(actions.orderCancelFailure(resp));
  } else {
    yield put(actions.orderCancelSuccess(resp.data.orderNo));
  }
}

function* orderQuotateSaga(action) {
  const { email } = action.payload;
  const resp = yield call(orderQuotate, action.payload);
  if (!resp.success || resp.data?.error || !resp.data.orderNo) {
    yield put(
      actions.orderQuotateFailure({
        ...resp,
        popup: {
          title: i18next.t('CommonPopup.oops'),
          content: i18next.t('CommonPopup.somethingWentWorng'),
          btnOkText: i18next.t('CommonPopup.tryAgain'),
          imgType: PopupImgType.Error,
        },
      }),
    );
  } else {
    yield put(
      actions.orderQuotateSuccess({
        popup: {
          title: i18next.t('CommonPopup.successful'),
          content: i18next.t('CheckoutForm.orderQuotatationSuccess', { email }),
          btnOkText: i18next.t('CommonPopup.ok'),
          imgType: PopupImgType.Success,
        },
      }),
    );
  }
}

export function* orderSaga() {
  yield takeLatest(actions.orderCreate.type, orderCreateSaga);
  yield takeLatest(actions.orderConfirm.type, orderConfirmSaga);
  yield takeLatest(actions.orderCancel.type, orderCancelSaga);
  yield takeLatest(actions.orderQuotate.type, orderQuotateSaga);
  yield takeLatest(actions.orderUnlock.type, orderUnlockSaga);
}
