import {
  NotificationType,
  PopupMode,
  PopupImgType,
  PopupContentType,
} from 'types/common';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { globalLayerSaga } from './saga';
import { GlobalLayerState } from './types';

export const initialState: GlobalLayerState = {
  isFetching: false,
  popup: {
    title: '',
    isOpen: false,
    okRedirectTo: '',
    mode: PopupMode.Alert,
    imgType: PopupImgType.None,
    contentType: PopupContentType.SmallCenter,
    content: '',
    handleOkClick: undefined,
  },
  notification: {
    isOpen: false,
    type: NotificationType.Success,
    title: '',
    content: '',
  },
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsFetching(state, action: PayloadAction<any>) {
      state.isFetching = action.payload;
    },
    openPopup(state, action: PayloadAction<any>) {
      state.popup = {
        ...state.popup,
        ...action.payload,
        isOpen: true,
      };
    },
    openNotification(state, action: PayloadAction<any>) {
      state.notification = {
        ...state.notification,
        ...action.payload,
        isOpen: true,
      };
    },
    closePopup(state) {
      state.popup = { ...initialState.popup };
    },
    closeNotification(state) {
      state.notification = { ...initialState.notification };
    },
  },
});

export const { actions: globalActions } = slice;

export const useGlobalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: globalLayerSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useGlobalSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
