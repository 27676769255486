import { BuConstant, TypeInfoList } from 'types/BuConstant';
import Loading from 'img/ksa/all_page/icon/loading.svg';
import PopupError from 'img/ksa/commonPopup/imgError.svg';
import PopupSuccess from 'img/ksa/commonPopup/imgSuccess.svg';
import BannerBackground from 'img/ksa/main_page/pic/ad_gift.png';
import ARBannerBackground from 'img/ksa/main_page/ARpic/ad_gift.png';
import AccountArrowLeftHoverIcon from 'img/ksa/account/icon/arrow_left_hover.svg';
import AccountArrowLeftPressedIcon from 'img/ksa/account/icon/arrow_left_pressed.svg';
import AccountArrowRightHoverIcon from 'img/ksa/account/icon/arrow_right_hover.svg';
import AccountArrowRightPressedIcon from 'img/ksa/account/icon/arrow_right_pressed.svg';
import AccountChooseOnePressedIcon from 'img/ksa/account/icon/chooseOne_pressed.svg';
import AccountImportIcon from 'img/ksa/account/icon/import.svg';
import AccountTopUpIcon from 'img/ksa/account/icon/topup.svg';
import AccountTopUpBg from 'img/ksa/account/myCards/topup.png';
import AccountUpdateProfileErrorIcon from 'img/ksa/account/icon/update-profile-error.svg';
import AccountUpdateProfileSuccessIcon from 'img/ksa/account/icon/update-profile-success.svg';
import AccountDetailsCloseIcon from 'img/ksa/account/myCards/icon/closeDetails.svg';
import AccountDetailsOpenIcon from 'img/ksa/account/myCards/icon/viewDetails.svg';
import AccountEmailPressedIcon from 'img/ksa/account/myCards/icon/email/pressed.svg';
import AccountSelectedPressedIcon from 'img/ksa/account/myCards/icon/select/pressed.svg';
import AccountSmsPressedIcon from 'img/ksa/account/myCards/icon/sms/pressed.svg';
import AccountGiftCardIcon from 'img/ksa/account/myCards/icon/giftcard.svg';
import AccountNewCardIcon from 'img/ksa/account/myCards/icon/newcard.svg';
import AccountOrderIcon from 'img/ksa/account/myCards/icon/order.svg';
import AccountOrderMobileIcon from 'img/ksa/account/myCards/icon/mobile-order.svg';
import AccountResendIcon from 'img/ksa/account/myCards/icon/resend.svg';
import AccountVideoIcon from 'img/ksa/account/myCards/icon/video.svg';
import AccountWalletIcon from 'img/ksa/account/myCards/icon/wallet.svg';
import AccountWalletMobileIcon from 'img/ksa/account/myCards/icon/mobile-wallet.svg';
import AccountDownloadIcon from 'img/ksa/account/orderHistory/icon/download.svg';
import RegisterPswActiveIcon from 'img/ksa/B2B_loginAndRegister/icon/psw/active.svg';
import LoginOAuthIcon from 'img/ksa/B2C_loginAndRegister/icon/oauth.svg';
import B2bLogo from 'img/ksa/B2Binfomation/section1/pic/logo.svg';
import B2bIntro from 'img/ksa/B2Binfomation/section1/pic/intro.jpg';
import B2bSection2Icon1 from 'img/ksa/B2Binfomation/section2/icon/01.svg';
import B2bSection2Icon3 from 'img/ksa/B2Binfomation/section2/icon/03.svg';
import B2bVerifyEmail from 'img/ksa/B2B_loginAndRegister/icon/b2b-verify.png';
import BuyingUploadMobileIcon from 'img/ksa/buying_flow_1_3/icon/step02/mobile_upload.svg';
import BuyingSelectedTemplateIcon from 'img/ksa/buying_flow_1_3/icon/step02/chooseTemplate/choose.svg';
import BuyingSelectedTemplateMobileIcon from 'img/ksa/buying_flow_1_3/icon/step02/chooseTemplate/mobile_choose.svg';
import BuyingGuestPressedIcon from 'img/ksa/buying_flow_1_3/icon/step03/guest/pressed.svg';
import BuyingLoginPressedIcon from 'img/ksa/buying_flow_1_3/icon/step03/login/pressed.svg';
import BuyingRegisterPressedIcon from 'img/ksa/buying_flow_1_3/icon/step03/register/pressed.svg';
import BuyingDocumentPressedIcon from 'img/ksa/buying_flow_1_3/icon/step03/sendby/doc/pressed.svg';
import BuyingEmailPressedIcon from 'img/ksa/B2C guest Step 1_3/icon/email/pressed.svg';
import BuyingEmailPressedMobileIcon from 'img/ksa/B2C guest Step 1_3/icon/email/mobile_pressed.svg';
import BuyingPhysicalPressedIcon from 'img/ksa/B2C guest Step 1_3/icon/physical/pressed.svg';
import BuyingPhysicalPressedMobileIcon from 'img/ksa/B2C guest Step 1_3/icon/physical/mobile_pressed.svg';
import BuyingPrintPressedIcon from 'img/ksa/buying_flow_1_3/icon/step03/sendby/print_at_home/pressed.svg';
import BuyingPrintPressedMobileIcon from 'img/ksa/buying_flow_1_3/icon/step03/sendby/print_at_home/mobile_pressed.svg';
import BuyingSmsPressedIcon from 'img/ksa/B2C guest Step 1_3/icon/SMS/pressed.svg';
import BuyingSmsPressedMobileIcon from 'img/ksa/B2C guest Step 1_3/icon/SMS/mobile_pressed.svg';
import BuyingAddGiftIcon from 'img/ksa/buying_flow_1_3/icon/step04/btn/add_another_gift.svg';
import BuyingGoToPSPIcon from 'img/ksa/buying_flow_1_3/icon/step04/btn/gotoPSP.svg';
import BuyingStep4QtyActiveAddIcon from 'img/ksa/buying_flow_1_3/icon/step04/quantity/add/active.svg';
import BuyingStep4QtyActiveMinusIcon from 'img/ksa/buying_flow_1_3/icon/step04/quantity/minus/active.svg';
import BuyingStepNumber1Icon from 'img/ksa/B2C guest Step 1_3/icon/1/active.svg';
import BuyingStepNumber2Icon from 'img/ksa/B2C guest Step 1_3/icon/2/active.svg';
import BuyingStepNumber3Icon from 'img/ksa/B2C guest Step 1_3/icon/3/active.svg';
import BuyingStepNumber4Icon from 'img/ksa/B2C guest Step 1_3/icon/4/active.svg';
import BuyingAddIcon from 'img/ksa/B2C guest Step 1_3/icon/btn_add_red.svg';
import BuyingDuplicateIcon from 'img/ksa/B2C guest Step 1_3/icon/btn_duplicate.svg';
import BuyingCouponIcon from 'img/ksa/B2C guest Step 1_3/icon/couponItemIcon.svg';
import BuyingBreadCrumbLoadingIcon from 'img/ksa/B2C guest Step 1_3/icon/loading_bird.svg';
import BuyingWritingIcon from 'img/ksa/B2C guest Step 1_3/icon/writing.svg';
import BuyingSuccessIcon from 'img/ksa/guest_buy_success/pic/bird.png';
import FooterLogo from 'img/ksa/footer/icon/logo/white.svg';
import FooterAds from 'img/ksa/footer/advertisement/app_ad_1.png';
import NavMainLogo from 'img/ksa/navigation/pic/logo.svg';
import NavMainLogoMobile from 'img/ksa/navigation/pic/mobile-logo.svg';
import NavCartIcon from 'img/navigation/icon/cart/normal.svg';
import NavCartMobileIcon from 'img/ksa/navigation/icon/Cart.svg';
import NavCartHoverIcon from 'img/ksa/navigation/icon/cart/hover_pressed.svg';
import NavHelpIcon from 'img/navigation/icon/help/normal.svg';
import NavHelpMobileIcon from 'img/ksa/navigation/icon/help.svg';
import NavHelpHoverIcon from 'img/ksa/navigation/icon/help/hover_pressed.svg';
import NavMapIcon from 'img/navigation/icon/map/normal.svg';
import NavMapMobileIcon from 'img/ksa/navigation/icon/location.svg';
import NavMapHoverIcon from 'img/ksa/navigation/icon/map/hover_pressed.svg';
import NavTelephoneIcon from 'img/ksa/navigation/icon/telephone.svg';
import NavPopUpTopupIcon from 'img/navigation/icon/pop_up/wallet/top_up.svg';
import NavPopUpWalletIcon from 'img/ksa/navigation/icon/pop_up/wallet.svg';
import NavPopUpCartIcon from 'img/navigation/icon/pop_up/cart/check_out.svg';
import NavPopUpFAQsIcon from 'img/navigation/icon/pop_up/FAQs.svg';
import NavEmailIcon from 'img/ksa/navigation/icon/email.svg';
import NavWalletIcon from 'img/navigation/icon/wallet/normal.svg';
import NavWalletHoverIcon from 'img/ksa/navigation/icon/wallet/hover_pressed.svg';
import QueryDownloadIcon from 'img/ksa/querycard/icon/history/download.svg';
import QueryDownloadMobileIcon from 'img/ksa/querycard/icon/history/mobile-download.svg';
import QuerySayThankYouIcon from 'img/ksa/querycard/icon/history/say_thank_you.svg';
import QueryArrowDownIcon from 'img/ksa/querycard/icon/history/more.svg';
import QueryArrowDownMobileIcon from 'img/ksa/querycard/icon/history/mobile-more.svg';
import BannerMobile1ImgMD from 'img/ksa/main_page/pic/banner_mobile/img-1-MD.png';
import BannerMobile1ImgSM from 'img/ksa/main_page/pic/banner_mobile/img-1-SM.png';
import BannerMobile1Bg from 'img/ksa/main_page/pic/banner_mobile/img-1-bg.png';
import ARBannerMobile1ImgMD from 'img/ksa/main_page/ARpic/banner_mobile/img-1-MD.png';
import ARBannerMobile1ImgSM from 'img/ksa/main_page/ARpic/banner_mobile/img-1-SM.png';
import ARBannerMobile1Bg from 'img/ksa/main_page/ARpic/banner_mobile/img-1-bg.png';
// import BannerMobile2ImgSM from 'img/main_page/pic/banner_mobile/img-2-SM.png';
// import BannerMobile2ImgMD from 'img/main_page/pic/banner_mobile/img-2-MD.png';
// import BannerMobile2Bg from 'img/main_page/pic/banner_mobile/img-2-bg.png';
import UAE_Icon from 'img/MAF_countryIcon/UAE_Icon.svg'
import QATAR_Icon from 'img/MAF_countryIcon/QATAR_Icon.svg'
import EGYPT_Icon from 'img/MAF_countryIcon/EGYPT_Icon.svg'
import KSA_Icon from 'img/MAF_countryIcon/KSA_Icon.svg'

import {
  about,
  deliveryInfo,
  generalCondition,
  legalNotices,
  personalData,
} from '../html';

enum FAQSection {
  GiftCard,
  DeliveryMethod,
  MyPersonalAccount,
  PaymentAndSecurityQuestions,
  HowToUse,
  Account,
}

enum FAQTabs {
  Tracking,
  GiftSender,
  GiftReceiver,
}

export class KsaBu implements BuConstant {
  buId = "KSA";

  isEnableRecaptcha = false;
  showIdCardNumberInRegister = false;
  supportPhoneNumber = '+XX 123456789';
  supportEmail = 'Support@we.com';
  googleAppStoreUrl = '';
  appleAppStoreUrl = '';
  browserTitle = 'Carrefour Gift Card';
  defaultCountry = 'TW';
  recaptchaKey = '6LcZ6sgeAAAAAETTd1AWPBhYJr12JzYhm7-DTkmZ';
  invisibleRecaptchaKey = '6Lcam-YfAAAAAIa6vkf9eORbtCasV3VbNM1w7AHQ';
  facebookAppId = '482449956535093';
  isNeedB2b = false;
  b2bUploadIdentify = true;
  isNeedSaleMan = true;
  isShowFooterBanner = true;
  DebitCardImgList = ['visa', 'mastercard', 'jcb'];
  messageLimit = 60;
  mediaList: { name: string; href: string }[] = [];
  moreInfoList = [
    {
      name: 'generalConditions',
      href: 'general-conditions',
      innerHTML: generalCondition,
    },
    {
      name: 'legalNotices',
      href: 'legal-notices',
      innerHTML: legalNotices,
    },
    {
      name: 'faq',
      href: 'faq',
      innerHTML: deliveryInfo,
    },
  ];
  isShowTermAndCondition = false;
  googleClientId =
    '147433044764-c8eqs483s3a01vthqq2lana1fdbpoc8p.apps.googleusercontent.com';
  isShowDecimal = true;
  defaultCurrency = 'EUR';
  isShowCurrencyCode = true;
  isShowAmountControl = true;
  isShowAmountItemCode = [''];
  isShowBlockButton = false;
  currentDefaultLanguage = '';
  isNeedPine = true;
  isNeedFreshDesk = true;
  isNeedInvoiceDownload = true;
  isNeedWallet = false;
  isAutoRegisterWithSocial = true;
  ZENDESK_SETTING_COLOR = '#0E5AA7';
  defaultLang = 'en';
  buName = "KSA";
  isNeedLoginToBuy = false;
  isNeedCorporate = true;
  isNeedSocietySearchPage = true;
  isNeedPrint = true;

  faqSections = [
    {
      title: {
        en: 'Gift card',
        ar: 'Gift card',
      },
      value: FAQSection.GiftCard,
      tabs: [
        {
          title: 'Tracking',
          value: FAQTabs.Tracking,
          contents: [
            {
              key: '1',
              title: {
                en: 'What is a Carrefour gift card?',
                ar: `What is a Carrefour gift card?`,
              },
              value: {
                en: `The Carrefour gift card is the ideal gift. The Carrefour gift card allows you to have a very wide choice in store on all the shelves, this card can have a face value (amount of your choice). The beneficiary can thus choose the gift he really wants since the Carrefour gift card gives him access to a very wide choice in store and allows him to make a "pleasure purchase".`,
                ar: `The Carrefour gift card is the ideal gift. The Carrefour gift card allows you to have a very wide choice in store on all the shelves, this card can have a face value (amount of your choice). The beneficiary can thus choose the gift he really wants since the Carrefour gift card gives him access to a very wide choice in store and allows him to make a "pleasure purchase".`,
              },
            },
            {
              key: '2',
              title: {
                en: 'Where can I use my Carrefour gift card?',
                ar: `Where can I use my Carrefour gift card?`,
              },
              value: {
                en: `The card can be used in Carrefour hypermarkets and supermarkets or at city checkouts. Additionally, gift card can also be used on Carrefour app by redeeming funds to Carrefour wallet.`,
                ar: `The card can be used in Carrefour hypermarkets and supermarkets or at city checkouts. Additionally, gift card can also be used on Carrefour app by redeeming funds to Carrefour wallet.`,
              },
            },
            {
              key: '3',
              title: {
                en: 'How long is my Carrefour gift card valid for?',
                ar: `How long is my Carrefour gift card valid for?`,
              },
              value: {
                en: `Carrefour gift card is valid for period of 1 year from the date of purchase.`,
                ar: `Carrefour gift card is valid for period of 1 year from the date of purchase.`,
              },
            },
          ],
        },
      ],
    },
    {
      title: {
        en: 'Delivery method',
        ar: 'Delivery method',
      },
      value: FAQSection.DeliveryMethod,
      tabs: [
        {
          title: 'Tracking',
          value: FAQTabs.Tracking,
          contents: [
            {
              key: '1',
              title: {
                en: 'Which carrier delivers my gift card ?',
                ar: `Which carrier delivers my gift card ?`,
              },
              value: {
                en: `The delivery of your Carrefour gift cards will be made via the Email or SMS.`,
                ar: `The delivery of your Carrefour gift cards will be made via the Email or SMS.`,
              },
            },
          ],
        },
      ],
    },
    {
      title: {
        en: 'Payment and security questions',
        ar: 'Payment and security questions',
      },
      value: FAQSection.PaymentAndSecurityQuestions,
      tabs: [
        {
          title: 'Tracking',
          value: FAQTabs.Tracking,
          contents: [
            {
              key: '1',
              title: {
                en: 'What payment methods are accepted on this site ?',
                ar: `What payment methods are accepted on this site ?`,
              },
              value: {
                en: `We accept payment by credit card only. After validation of your payment, you will receive an email confirming your order.`,
                ar: `We accept payment by credit card only. After validation of your payment, you will receive an email confirming your order.`,
              },
            },
            {
              key: '2',
              title: {
                en: 'Can I use my bank payment card safely?',
                ar: `Can I use my bank payment card safely?`,
              },
              value: {
                en: `You can use your payment card safely. Payments are secured. The banking information you provide is encrypted and transmitted directly to the bank.`,
                ar: `You can use your payment card safely. Payments are secured. The banking information you provide is encrypted and transmitted directly to the bank.`,
              },
            },
          ],
        },
      ],
    },
  ];
  paymentTCUrl = 'https://giftcards.mafcarrefour.com/general-conditions';
  categoryLimit = 4;
  isNeedQuery = true;
  isShowNavFaq = false;
  isShowDesktopBannerContent = false;
  orderSuccessRedirectBtn = null;
  ssoName = 'carrefour.fr';
  locationUrl = 'https://www.carrefour.fr/';
  defaultGMT = 8;
  showTimeZone = false;
  isShowCheckoutPincode = true;

  isEnableAccountTabsImport = false;
  isEnableAccountOrderTitle = false;
  isEnableAccountTabsCustName = false;
  isEnableAccountTabsCorporate = false;
  isEnableAccountTabsInbox = false;
  isEnableAccountTabsProfile = false;
  isEnableAccountTabsLogout = false;
  isBuyingSelfBtnDisplayFirst = true;
  isEnableHomePageTelephone = false;
  isEnableHomePageEmail = false;
  isEnableNavigationMap = false;
  isEnableNavigationMobileQueryCard = true;
  isEnableNavigationMobileLocation = false;
  isEnableNavigationMobileContactUs = false;
  isEnableNavigationMobileCorporate = false;
  isEnableNavigationMobileProfile = false;
  isEnableNavigationMobileImport = false;
  isEnableOrderSuccessLogo = true;
  isEnableOrderRecordOrderIcon = false;
  isEnablePayCardBlockBtn = false;
  isHomePageMobileBigDiscount = true;
  isEnableShoppingCartDiscountWarning = false;
  isEnableHomePageLoganLine2 = false;

  useBacktoHomePageBtn = false;

  creditCardYearPlaceholder = 'AA';

  tooltipCardFileWebName = 'Web';
  tooltipCardFileURL = '/';
  buCurrency = '';
  isSliderBanner = false;

  MAFCountrtList = [
    {id: 'UAE', name: 'UAE', img: UAE_Icon, text: 'United Arab Emirates'},
  ]

  getAsset() {
    return {
      Loading: Loading,
      PopUpError: PopupError,
      PopUpSuccess: PopupSuccess,

      BannerBackground,
      BannerMobile1ImgMD,
      BannerMobile1ImgSM,
      BannerMobile1Bg,
      ARBannerBackground,
      ARBannerMobile1ImgMD,
      ARBannerMobile1ImgSM,
      ARBannerMobile1Bg,
      BannerMobile2ImgMD: '',
      BannerMobile2ImgSM: '',
      BannerMobile2Bg: '',

      AccountArrowLeftHoverIcon,
      AccountArrowLeftPressedIcon,
      AccountArrowRightHoverIcon,
      AccountArrowRightPressedIcon,
      AccountChooseOnePressedIcon,
      AccountImportIcon,
      AccountTopUpIcon,
      AccountUpdateProfileErrorIcon,
      AccountUpdateProfileSuccessIcon,
      AccountEmailPressedIcon,
      AccountSelectedPressedIcon,
      AccountSmsPressedIcon,
      AccountDetailsCloseIcon,
      AccountDetailsOpenIcon,
      AccountGiftCardIcon,
      AccountNewCardIcon,
      AccountOrderIcon,
      AccountOrderMobileIcon,
      AccountResendIcon,
      AccountVideoIcon,
      AccountWalletIcon,
      AccountWalletMobileIcon,
      AccountTopUpBg,
      AccountDownloadIcon,

      LoginOAuthIcon,
      RegisterPswActiveIcon,

      B2bLogo,
      B2bIntro,
      B2bSection2Icon1,
      B2bSection2Icon3,
      B2bVerifyEmail,

      BuyingUploadMobileIcon,
      BuyingSelectedTemplateIcon,
      BuyingSelectedTemplateMobileIcon,
      BuyingGuestPressedIcon,
      BuyingLoginPressedIcon,
      BuyingRegisterPressedIcon,
      BuyingDocumentPressedIcon,
      BuyingEmailPressedIcon,
      BuyingEmailPressedMobileIcon,
      BuyingPhysicalPressedIcon,
      BuyingPhysicalPressedMobileIcon,
      BuyingPrintPressedIcon,
      BuyingPrintPressedMobileIcon,
      BuyingSmsPressedIcon,
      BuyingSmsPressedMobileIcon,
      BuyingAddGiftIcon,
      BuyingGoToPSPIcon,
      BuyingStep4QtyActiveAddIcon,
      BuyingStep4QtyActiveMinusIcon,
      BuyingStepNumber1Icon,
      BuyingStepNumber2Icon,
      BuyingStepNumber3Icon,
      BuyingStepNumber4Icon,
      BuyingAddIcon,
      BuyingDuplicateIcon,
      BuyingCouponIcon,
      BuyingBreadCrumbLoadingIcon,
      BuyingWritingIcon,
      BuyingSuccessIcon,

      FooterLogo,
      FooterAds,

      NavMainLogo,
      NavMainLogoMobile,
      NavCartIcon,
      NavCartMobileIcon,
      NavCartHoverIcon,
      NavHelpIcon,
      NavHelpMobileIcon,
      NavHelpHoverIcon,
      NavMapIcon,
      NavMapMobileIcon,
      NavMapHoverIcon,
      NavWalletIcon,
      NavWalletHoverIcon,
      NavEmailIcon,
      NavTelephoneIcon,
      NavPopUpWalletIcon,
      NavPopUpCartIcon,
      NavPopUpFAQsIcon,
      NavPopUpTopupIcon,

      QueryDownloadIcon,
      QueryDownloadMobileIcon,
      QueryArrowDownIcon,
      QueryArrowDownMobileIcon,
      QuerySayThankYouIcon,
    };
  }
}
