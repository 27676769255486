/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  login: () => _t(translations.Navigation.login, 'Login'),
  loginAndRegister: () =>
    _t(translations.Navigation.loginAndRegister, 'Login & Register'),
  buyEGiftCards: () =>
    _t(translations.Navigation.buyEGiftCards, 'Buy eGiftcards'),
  queryCard: () => _t(translations.Navigation.queryCard, 'Query Card'),
  noCartTitle: () => _t(translations.Navigation.noCartTitle, 'Oops!'),
  noCartContent: () =>
    _t(
      translations.Navigation.noCartContent,
      'You don’t have anything in cart. Let’s buy some new cards.',
    ),
  noCartCTA: () => _t(translations.Navigation.noCartCTA, "Let's buy"),
  faqs: () => _t(translations.Navigation.faqs, 'FAQs'),
  contactUs: () => _t(translations.Navigation.contactUs, 'Contact us'),
  cartTotalAmount: () =>
    _t(translations.Navigation.cartTotalAmount, 'Total Amount'),
  cartProceedCheckout: () =>
    _t(translations.Navigation.cartCheckout, 'Proceed to Checkout'),
  walletAvailableCredit: () =>
    _t(translations.Navigation.walletAvailableCredit, 'Available Credit'),
  walletTopUp: () => _t(translations.Navigation.walletTopUp, 'Top up'),
  to: () => _t(translations.Navigation.to, 'To'),
  deliveryBy: () => _t(translations.Navigation.deliveryBy, 'Delivery by'),
  email: () => _t(translations.Navigation.email, 'Email'),
  SMS: () => _t(translations.Navigation.SMS, 'SMS'),
  physical: () => _t(translations.Navigation.physical, 'Physical'),
  individualAccount: () =>
    _t(translations.Navigation.individualAccount, 'Individual Account'),
  businessAccount: () =>
    _t(translations.Navigation.businessAccount, 'Business Account'),
  individual: () => _t(translations.Navigation.individual, 'Individual'),
  business: () => _t(translations.Navigation.business, 'Business'),
  queryBalance: () => _t(translations.Navigation.queryBalance, 'Query Balance'),
  dowloadApp: () => _t(translations.Navigation.dowloadApp, 'Dowload APP'),
  forBusiness: () => _t(translations.Navigation.forBusiness, 'For Business'),
  forCustomer: () => _t(translations.Navigation.forCustomer, 'For Customer'),
  location: () => _t(translations.Navigation.location, 'Location'),
  help: () => _t(translations.Navigation.help, 'Help'),
  helpSubtitle1: () =>
    _t(translations.Navigation.helpSubtitle1, 'We are here for you!'),
  helpSubtitle2: () =>
    _t(translations.Navigation.helpSubtitle2, 'Please check'),
  helpSubtitle3: () =>
    _t(translations.Navigation.helpSubtitle3, 'or contact us.'),
  tel: () => _t(translations.Navigation.tel, 'Tel'),
  warnModalTitle: () =>
    _t(
      translations.Navigation.warnModalTitle,
      'Are you sure to leave this page?',
    ),
  warnModalContentOne: () =>
    _t(
      translations.Navigation.warnModalContentOne,
      'This order item is not yet finish, please fill all blanks for us to save your order item in cart.',
    ),
  warnModalContentTwo: () =>
    _t(
      translations.Navigation.warnModalContentTwo,
      'If you leave this page now, the order item won’t be saved in cart.',
    ),
  warnModalConfirm: () =>
    _t(translations.Navigation.warnModalConfirm, 'Yes, leave this page'),
  warnModalCancel: () =>
    _t(translations.Navigation.warnModalCancel, 'No, continue editing'),
  loginAs: () => _t(translations.Navigation.loginAs, 'Login as:'),
  account: () => _t(translations.Navigation.account, 'Account'),
  map: () => _t(translations.Navigation.map, 'Map'),
  cart: () => _t(translations.Navigation.cart, 'Cart'),
  wallet: () => _t(translations.Navigation.wallet, 'Wallet'),
  hello: () => _t(translations.Navigation.hello, 'Hello, {{name}}'),
  helloName: () => _t(translations.Navigation.helloName, 'Hello, {{name}}'),
  profileSetting: () =>
    _t(translations.AccountTabs.profileSetting, 'Profile setting'),
  import: () => _t(translations.AccountTabs.import, 'Import'),
  myCards: () => _t(translations.AccountTabs.myCards, 'My cards'),
  orderHistory: () =>
    _t(translations.AccountTabs.orderHistory, 'Order history'),
  inbox: () => _t(translations.Navigation.inbox, 'Inbox'),
  logoutError: () =>
    _t(
      translations.AccountPage.logoutError,
      'Seems there are something went wrong. Please try again or contact us.',
    ),
  logout: () => _t(translations.AccountTabs.logout, 'Logout'),
  logoutFail: () => _t(translations.AccountPage.logoutFail, 'Oops!'),
  tryAgain: () => _t(translations.AccountPage.tryAgain, 'Try again'),
  corporateAccount: () =>
    _t(translations.AccountTabs.corporateAccount, 'Corporate Account'),
  salesmanAccount: () =>
    _t(translations.AccountTabs.salesmanAccount, 'Salesman Account'),
  chooseLanguage: () =>
    _t(translations.Navigation.chooseLanguage, 'Choose Language'),
};
