import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
declare global {
  interface Window {
    grecaptcha: any;
    onloadCB: (token) => void;
    recaptcha: any;
    __google_recaptcha_client: any;
    __recaptcha_api: any;
    ___grecaptcha_cfg: any;
  }
}

interface Props {
  renderKey?: string;
  callback?: () => void;
}

export default function GRecaptcha(props: Props) {
  const { i18n } = useTranslation();
  function recaptchaInitial() {
    /**
     * 移除原本註冊過的recaptcha api，避免同時註冊checkbox & invisible recaptcha 出錯
     */
    delete window.grecaptcha;
    delete window.recaptcha;
    delete window.__google_recaptcha_client;
    delete window.__recaptcha_api;
    delete window.___grecaptcha_cfg;

    const newSrcTag = document.createElement('script');
    newSrcTag.src = `https://www.google.com/recaptcha/api.js?hl=${i18n.language}&render=explicit`;
    newSrcTag.setAttribute('data-type', `recaptcha-${i18n.language}`);
    newSrcTag.setAttribute('async', '');
    newSrcTag.setAttribute('defer', '');
    document.head.appendChild(newSrcTag);
  }

  useEffect(() => {
    recaptchaInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return <div id="grecaptcha-target"></div>;
}
