import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.account || initialState;

export const selectAccount = createSelector([selectSlice], state => state);
export const selectIsFetching = createSelector(
  [selectSlice],
  state => state.isFetching,
);
export const selectMember = createSelector(
  [selectSlice],
  state => state.member,
);

export const selectInboxMessages = createSelector(
  [selectSlice],
  state => state.inboxMessages,
);

export const selectPreviousFile = createSelector(
  [selectSlice],
  state => state.memberPreviousFile,
);

export const selectWallet = createSelector(
  [selectSlice],
  state => state.wallet,
);

export const selectHistory = createSelector(
  [selectSlice],
  state => state.memberHistory,
);

export const selectProducts = createSelector(
  [selectSlice],
  state => state.products,
);

export const selectCardDetail = createSelector(
  [selectSlice],
  state => state.cardDetail,
);

export const selectTransactionData = createSelector(
  [selectSlice],
  state => state.cardTransactionData,
);

export const selectAccountError = createSelector(
  [selectSlice],
  state => state.error,
);

export const selectAccountIsTransfer = createSelector(
  [selectSlice],
  state => state.isTransfer,
);

export const selectAccountIsTopUp = createSelector(
  [selectSlice],
  state => state.isTopUp,
);

export const selectAccountIsCardResend = createSelector(
  [selectSlice],
  state => state.isCardResend,
);

export const selectAccountCardPrint = createSelector(
  [selectSlice],
  state => state.cardPrint,
);

export const selectAccountProfileIsUpdateSuccess = createSelector(
  [selectSlice],
  state => state.isUpdateSuccess,
);

export const selectCustomerList = createSelector(
  [selectSlice],
  state => state.customerList,
);

export const selectCustomerMember = createSelector(
  [selectSlice],
  state => state.customerMember,
);

export const selectIsNeedPinCode = createSelector(
  [selectSlice],
  state => state.isNeedPinCode,
);
