import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getBuConfig } from 'helpers/BuHelper';

interface Props {
  containerId: string;
  closeTimeout?: number;
  onSuccess: (token: string) => void;
  isInvisible?: boolean;
}

interface UseGRecaptcha {
  isRecaptchaProcess: boolean;
  startRecaptcha: () => void;
}

export default function useGRecaptcha(props: Props): UseGRecaptcha {
  const {
    containerId,
    onSuccess,
    closeTimeout = 0,
    isInvisible = false,
  } = props;
  const {
    invisibleRecaptchaKey,
    isEnableRecaptcha,
    recaptchaKey,
  } = getBuConfig();
  const { i18n } = useTranslation();
  const [isRecaptchaProcess, setIsRecaptchaProcess] = useState<boolean>(false);

  const createRecaptchaInstance = lang => {
    const targetBox = document.getElementById(containerId);
    const recaptchaInstanceDomId = `g-recaptcha-${containerId}-${lang}`;
    if (!document.getElementById(recaptchaInstanceDomId)) {
      const recaptchaInstance = document.createElement('div');
      recaptchaInstance.id = recaptchaInstanceDomId;
      targetBox!.appendChild(recaptchaInstance);
      const instanceId = window.grecaptcha.render(recaptchaInstanceDomId, {
        sitekey: isInvisible ? invisibleRecaptchaKey : recaptchaKey,
        size: isInvisible ? 'invisible' : 'normal',
        callback: function successCallback(token: string) {
          setTimeout(() => {
            onSuccess(token);
            setIsRecaptchaProcess(false);
            window.grecaptcha.reset(instanceId);
            if (isInvisible) {
              document.getElementById(recaptchaInstanceDomId)?.remove();
            }
          }, closeTimeout * 1000);
        },
      });
      if (isInvisible) {
        window.grecaptcha.execute(instanceId);
      }
    }
  };

  useEffect(() => {
    if (isRecaptchaProcess) {
      if (isEnableRecaptcha) {
        createRecaptchaInstance(i18n.language);
      } else {
        onSuccess('pass');
        setIsRecaptchaProcess(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecaptchaProcess]);

  const startRecaptcha = () => {
    setIsRecaptchaProcess(true);
  };

  return { isRecaptchaProcess, startRecaptcha };
}
