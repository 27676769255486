import { useState, useEffect } from 'react';

interface Props {
  onCountdownToZero?: () => void;
}

interface UseCountdown {
  show: boolean;
  startCountdown: (seconds?: number) => void;
  setMaxSecond: () => void;
  setOneSecond: () => void;
}

export default function useCountdown(props?: Props): UseCountdown {
  const { onCountdownToZero } = props || {};
  const [show, setShow] = useState(false);
  const [countDownSecond, setCountDownSecond] = useState(0);

  useEffect(() => {
    if (show && countDownSecond <= 0) {
      setShow(false);
      setTimeout(() => {
        onCountdownToZero && onCountdownToZero();
      }, 300);
      return;
    }
    const id = setInterval(() => {
      setCountDownSecond(countDownSecond - 1);
    }, 1000);

    return () => {
      clearInterval(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDownSecond]);

  const startCountdown = (seconds = 3) => {
    setShow(true);
    setCountDownSecond(seconds);
  };

  const setMaxSecond = () => {
    setCountDownSecond(Number.MAX_SAFE_INTEGER);
  };

  const setOneSecond = () => {
    setCountDownSecond(1);
  };

  return { show, startCountdown, setMaxSecond, setOneSecond };
}
