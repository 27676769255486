import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.order || initialState;

export const selectOrder = createSelector([selectSlice], state => state);

export const selectIsFetching = createSelector(
  [selectSlice],
  state => state.isFetching,
);

export const selectIsConfirm = createSelector(
  [selectSlice],
  state => state.isConfirm,
);

export const selectIsCanceled = createSelector(
  [selectSlice],
  state => state.isCanceled,
);

export const selectOrderNo = createSelector(
  [selectSlice],
  state => state.orderNo,
);

export const selectOrderTotalAmount = createSelector(
  [selectSlice],
  state => state.orderAmtTot,
);

export const selectPayProcessing = createSelector(
  [selectSlice],
  state => state.isPayProcessing,
);
