import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import { mainPageActions as actions } from '.';
import { selectProduct } from './selectors';
import { map, includes, reduce, concat, get } from 'lodash';
import { decryptionDES, setJwtKey } from 'helpers/crypto';
import {
  fetchTimestamp,
  fetchCategory,
  fetchProduct,
  fetchProductDetail,
  fetchTemplateCategory,
  fetchTemplates,
  fetchLetterType,
  fetchAdditionalProduct,
  fetchCountryList,
  ApiResponse,
  fetchBuParameter,
  fetchSNameFetcher,
} from 'app/APIs';
import { selectAuthUser } from 'app/pages/AuthPage/slice/selectors';
import { customLocalStorage, getBuIdFromUrl } from 'helpers';

function* fetchTimestampSaga(action) {
  const date = new Date();
  date.setSeconds(0);
  const timestamp = `${(date.getTime() / 1000) | 0}000`;
  let hex = '';
  for (let i = 0; i < timestamp.length; i++) {
    hex += timestamp.charCodeAt(i).toString(16);
  }
  const base64 = btoa(hex);
  const resp = yield call(fetchTimestamp, base64);

  const { callback } = action.payload;

  if (!resp.success) {
    console.warn(resp);
    callback && callback(false);
    yield put(actions.fetchTimestampFailure(resp));
  } else {
    const privateKey = decryptionDES(resp.data, timestamp);
    if (privateKey) {
      const buId = getBuIdFromUrl(true);
      const { setItemLs } = customLocalStorage(buId);

      setItemLs('timeRequest', btoa(timestamp));
      setItemLs('timeResponse', resp.data);
      setJwtKey(privateKey, timestamp);
      callback && callback(true);
      yield put(actions.fetchTimestampSuccess(resp.data.categories));
    } else {
      callback && callback(false);
      yield put(actions.fetchTimestampFailure(resp));
    }
  }
}

function* fetchCategorySaga() {
  const authUser = yield select(selectAuthUser);
  const custId = authUser?.custId ?? '';
  const resp = yield call(fetchCategory, getBuIdFromUrl(true), custId);
  if (!resp.success) {
    console.warn(resp);
    yield put(actions.fetchCategoryFailure(resp));
  } else {
    yield put(actions.fetchCategorySuccess(resp.data.categories));
  }
}

function* fetchProductSaga(action) {
  const { categoryId, productName, page, custId } = action.payload;
  const resp = yield call(fetchProduct, categoryId, page, {
    aggregatorBuId: getBuIdFromUrl(true),
    productName,
    custId,
  });

  if (!resp.success) {
    console.warn(resp);
    yield put(actions.fetchProductFailure(resp));
  } else {
    if (page > 1) {
      const productList = yield select(selectProduct);
      yield put(
        actions.fetchProductSuccess({
          items: productList.concat(resp.data.products || []),
          totalCount: resp.data.totalCount,
          page,
        }),
      );
    } else {
      yield put(
        actions.fetchProductSuccess({
          items: resp.data.products || [],
          totalCount: resp.data.totalCount,
          page: 1,
        }),
      );
    }
  }
}

function* fetchProductDetailSaga(action) {
  const { itemCode, custId } = action.payload;
  const resp = yield call(
    fetchProductDetail,
    itemCode,
    getBuIdFromUrl(true),
    custId,
  );

  if (!resp.success) {
    console.warn(resp);
    yield put(actions.fetchProductDetailFailure(resp));
  } else {
    yield put(
      actions.fetchProductDetailSuccess({
        itemCode,
        data: resp.data,
      }),
    );
  }
}

function* fetchTemplateCategorySaga(action) {
  const { itemCode } = action.payload;
  try {
    const resp = yield call(
      fetchTemplateCategory,
      getBuIdFromUrl(true),
      itemCode,
    );
    if (!resp.success) {
      throw new Error(' fetch template category fail');
    }

    yield put(
      actions.fetchTemplateCategorySuccess(resp.data.categoriesTemplates),
    );
  } catch (error) {
    console.warn(error);
    yield put(actions.fetchTemplateCategoryFailure(error));
  }
}

function* fetchTemplatesSaga(action) {
  const { templateCategoryList, itemCode } = action.payload;
  try {
    const respArray: Array<ApiResponse> = yield all(
      templateCategoryList.map(category =>
        call(fetchTemplates, category.templateId, itemCode),
      ),
    );

    if (
      includes(
        map(respArray, resp => resp.success),
        false,
      )
    ) {
      throw new Error('fetch template category fail');
    }

    const templateArray: Array<{ id: string; images: any[] }> | [] = reduce(
      templateCategoryList,
      (acc: Array<{ id: string; images: any[] }> | [], value, index) => {
        if (!respArray[index]) {
          return acc;
        }
        return concat(acc, {
          id: value.templateId,
          images: get(respArray[index], `data.categoryImages`, []),
        });
      },
      [],
    );

    yield put(actions.fetchTemplatesSuccess(templateArray));
  } catch (error) {
    console.warn(error);
    yield put(actions.fetchTemplatesFailure(error));
  }
}

function* fetchLetterTypeSaga() {
  try {
    const resp = yield call(fetchLetterType);
    if (!resp.success) {
      throw resp.data;
    }

    yield put(actions.fetchLetterTypeSuccess(resp.data.letterTypeList));
  } catch (error) {
    console.warn('fetchLetterTypeSaga', error);
    yield put(actions.fetchLetterTypeFailure(error));
  }
}

function* fetchAdditionalProductSaga() {
  try {
    const resp = yield call(fetchAdditionalProduct, getBuIdFromUrl(true));

    if (!resp.success) {
      throw resp.data;
    }

    yield put(
      actions.fetchAdditionalProductSuccess({
        additionalProducts: resp.data.additionalProductList,
      }),
    );
  } catch (error) {
    console.warn('fetchAdditionalProductSaga', error);
    yield put(actions.fetchAdditionalProductFailure(error));
  }
}

function* fetchCountryListSaga() {
  try {
    const resp = yield call(fetchCountryList);

    if (!resp.success) {
      throw resp.data;
    }
    yield put(
      actions.fetchCountryListSuccess({ countryList: resp.data.countryList }),
    );
  } catch (error) {
    console.warn('fetchCountryListSaga', error);
    yield put(actions.fetchCountryListFailed(error));
  }
}

function* fetchSNameFetcherSaga() {
  try {
    const resp = yield call(fetchSNameFetcher, { mID: 'URSSAF' });

    if (!resp.success) {
      throw resp.data;
    }
    yield put(
      actions.fetchSNameFetcherSuccess({ scodeList: resp.data.scodeList }),
    );
  } catch (error) {
    console.warn('fetchSNameFetcherSaga', error);
    yield put(actions.fetchSNameFetcherFailed(error));
  }
}

function* fetchBuParameterSaga(action) {
  const { buId } = action.payload;
  try {
    const resp = yield call(fetchBuParameter, buId);

    if (!resp.success) {
      throw resp.data;
    }
    const {
      currency,
      memberShip,
      discountType,
      paymentType,
      cardMadeTaxRate,
      shippingTaxRate,
      isEnableGuestMode,
      isAllowMultipleProduct,
      b2bMaxQty,
      b2cMaxQty,
      decimalDigits,
      pspProvider,
    } = resp.data;
    yield put(
      actions.getBuParameterSuccess({
        currency,
        memberShip,
        discountType,
        paymentType: paymentType || '',
        cardMadeTaxRate,
        shippingTaxRate,
        isEnableGuestMode,
        isAllowMultipleProduct,
        b2bMaxQty,
        b2cMaxQty,
        decimalDigits,
        pspProvider,
      }),
    );
  } catch (error) {
    console.warn('fetchBuParameterSaga', error);
    yield put(actions.getBuParameterFailed(error));
  }
}

function* fetchTemplateProductDetailSaga(action) {
  const { templateData, custId, errorCallBack } = action.payload;

  try {
    const respArray: Array<ApiResponse> = yield all(
      templateData.images.map(image =>
        call(fetchProductDetail, image.itemCode, getBuIdFromUrl(true), custId),
      ),
    );

    if (
      includes(
        map(respArray, resp => resp.success),
        false,
      ) ||
      respArray.filter(resp => resp.data.error?.code).length > 0
    ) {
      throw new Error('fetch template product detail fail');
    }

    const newTemplateDataImages = templateData.images.map(template => {
      const filterResp = respArray.filter(
        respItem => respItem.data.itemCode === template.itemCode,
      )[0];

      if (filterResp) {
        return {
          ...template,
          discount: filterResp.data.discount,
        };
      } else {
        return template;
      }
    });

    const newTemplateData = { ...templateData, images: newTemplateDataImages };

    yield put(actions.getTemplateDiscountSuccess(newTemplateData));
  } catch (error) {
    console.warn(error);
    yield put(actions.getTemplateDiscountFailed(error));
    errorCallBack();
  }
}

export function* mainPageSaga() {
  yield takeLatest(actions.fetchTimestamp, fetchTimestampSaga);
  yield takeLatest(actions.fetchCategory, fetchCategorySaga);
  yield takeLatest(actions.fetchProduct, fetchProductSaga);
  yield takeLatest(actions.fetchProductDetail, fetchProductDetailSaga);
  yield takeLatest(
    actions.fetchTemplateCategory.type,
    fetchTemplateCategorySaga,
  );
  yield takeLatest(actions.fetchTemplates.type, fetchTemplatesSaga);
  yield takeLatest(actions.fetchLetterType.type, fetchLetterTypeSaga);
  yield takeLatest(
    actions.fetchAdditionalProduct.type,
    fetchAdditionalProductSaga,
  );
  yield takeLatest(actions.fetchCountryList.type, fetchCountryListSaga);
  yield takeLatest(actions.fetchSNameFetcher.type, fetchSNameFetcherSaga);
  yield takeLatest(actions.getBuParameter.type, fetchBuParameterSaga);
  yield takeLatest(
    actions.getTemplateDiscount.type,
    fetchTemplateProductDetailSaga,
  );
}
