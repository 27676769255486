/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  warnTitle: () => _t(translations.App.warnTitle, 'Oops!'),
  warnContent: () =>
    _t(
      translations.App.warnContent,
      'Seems there are something went wrong. Please try again or contact us.',
    ),
  warnConfirm: () => _t(translations.App.warnConfirm, 'Try again'),
  warnTimeout: () =>
    _t(
      translations.App.warnTimeout,
      "You've been inactive 30 mins. For your security, we'll automatically sign you out.",
    ),
};
