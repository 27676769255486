import React, { useEffect, useState } from 'react';
import RippleLoading from 'img/Unlock/loading-ripple.svg';
import LockIcon from 'img/Unlock/lock.png';
import UnlockIcon from 'img/Unlock/unlock.png';
import FailIcon from 'img/Unlock/fail.svg';
import SuccessIcon from 'img/Unlock/success.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetching,
  selectUnlockState,
  selectOrderNo,
} from './slice/selector';
import { useUnlockSlice } from './slice';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { messages } from './message';
import useGRecaptcha from 'utils/hooks/useGRecaptcha';
import GRecaptcha from '../../components/GRecaptcha';
import CommonPopup from 'app/components/CommonPopup';
import { PopupMode } from 'types/common';

export default function Unlock() {
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);
  const unlockState = useSelector(selectUnlockState);
  const unlockSuccessOrderNo = useSelector(selectOrderNo);
  const [isDisplayModal, setIsDisplayModal] = useState(false);
  const { actions } = useUnlockSlice();
  const { search } = useLocation();
  const { t } = useTranslation();
  const orderNo = new URLSearchParams(search).get('orderNo');

  const containerId = 'g-recaptcha-unblock';
  const { isRecaptchaProcess, startRecaptcha } = useGRecaptcha({
    containerId,
    closeTimeout: 1,
    onSuccess: captchaToken => {
      dispatch(
        actions.fetchUnlockOrder({ orderNo: orderNo || '', captchaToken }),
      );
    },
    isInvisible: true,
  });

  useEffect(() => {
    if (!orderNo) {
      window.location.href = '/';
    } else {
      setIsDisplayModal(true);
    }
  }, [search]);

  const handleUnlockCard = () => {
    setTimeout(() => {
      if (window.grecaptcha) {
        startRecaptcha();
      }
      window.scrollTo(0, 0);
      setIsDisplayModal(false);
    }, 1000);
    // dispatch(actions.fetchUnlockOrder({ orderNo: orderNo || '' }));
  };

  const unlockStatement = () => {
    if (typeof unlockState.error.code === 'undefined') {
      return (
        <>
          <div className="title">{t(...messages.titleSuccess())}</div>
          <p>
            {t(...messages.successStatement1(), {
              orderNo: unlockSuccessOrderNo,
              cardQty: unlockState.cardQty,
            })}
          </p>
          <p>{t(...messages.successStatement2())}</p>
        </>
      );
    }

    switch (unlockState.error.code) {
      case 58019:
        return (
          <>
            <div className="title">{t(...messages.titleAlready())}</div>
            <p>
              {t(...messages.alreadyStatement1(), {
                orderNo: unlockSuccessOrderNo,
                cardQty: unlockState.cardQty,
              })}
            </p>
          </>
        );

      default:
        return (
          <>
            <div className="title">{t(...messages.titleOops())}</div>
            <p>
              {t(...messages.oopsStatement1(), {
                orderNo: unlockSuccessOrderNo,
                cardQty: unlockState.cardQty,
              })}
            </p>
            <p>{t(...messages.oopsStatement2())}</p>
          </>
        );
    }
  };

  const isUnLock =
    typeof unlockState.error.code === 'undefined' ||
    unlockState.error.code === 58019;

  return (
    <>
      <GRecaptcha />
      {isRecaptchaProcess && <div id={containerId} className="mt-3" />}
      <div className="unlock">
        {isFetching ? (
          <div className="loading-process">
            <div>
              <img src={RippleLoading} alt="loading" />
              <h3>Loading now...</h3>
            </div>
          </div>
        ) : (
          <div className="unlock-result">
            <div className="unlock-result-container">
              {isUnLock ? (
                <img src={UnlockIcon} alt="" className="unlock-icon" />
              ) : (
                <img src={LockIcon} alt="" className="lock-icon" />
              )}

              <div className="result-card">
                <img src={isUnLock ? SuccessIcon : FailIcon} alt="" />
                <div className="content">{unlockStatement()}</div>
              </div>
              {isUnLock ? (
                <a href="/" target="_blank">
                  {t(...messages.homePage())}
                </a>
              ) : (
                <a href="/" target="_blank">
                  {t(...messages.contactUs())}
                </a>
              )}
            </div>
          </div>
        )}
      </div>
      <CommonPopup
        title={t(...messages.confirmationTitle())}
        handleClosePopup={() => window.location.replace('/')}
        mode={PopupMode.Ask}
        btnCloseText={t(...messages.confirmationNo())}
        btnOkText={t(...messages.confirmationYes())}
        handleBtnClose={() => window.location.replace('/')}
        handleBtnOK={() => handleUnlockCard()}
        showPopup={isDisplayModal}
        className="popup-confirmation mobile-style"
      >
        <div className="popup-confirmation-content">
          <div>{t(...messages.confirmationText1())}</div>
          <div>{t(...messages.confirmationText2())}</div>
        </div>
      </CommonPopup>
    </>
  );
}
