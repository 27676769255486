import { CompanyInfo } from 'app/pages/AuthPage/slice/types';
import { Transaction, GiftCard } from 'types/common';
/* --- STATE --- */
export interface AccountState {
  member: AccountMember;
  isFetching: boolean;
  isNeedPinCode: boolean | null;
  error: any;
  inboxMessages: InboxMessage[];
  wallet: {
    balance: number;
    orderCount: number;
    isReloadable: boolean;
    cardNumber: string;
    processAmount: number;
  } | null;
  memberHistory: {
    historyType: string;
    totalCount: number;
    transactionRecords: TransactionRecord[];
  };
  memberPreviousFile: string;
  products: any[] | null;
  cardDetail: MemberCardDetail;
  cardTransactionData: {
    totalCount: number;
    transactionList: Transaction[];
  };
  isTransfer: boolean;
  isTopUp: boolean;
  isCardResend?: boolean;
  cardPrint?: {
    file: {
      error: any;
      pdfUrl: string;
    };
    currentCardNo: string;
  };
  isUpdateSuccess: boolean | null;
  customerList: Customer[];
  customerMember?: AccountMember;
}

export type AccountAddressType = {
  civillity: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  country: string;
  city: string;
  firstAddress: string;
  secondAddress: string;
  zipCode: string;
  invoiceEmail?: string;
};

export interface MemberCardDetail {
  cardList: GiftCard[];
  productName: string;
  productImageUrl: string;
  howToUse: string;
  termOfService: string;
  itemCode: string;
  transferMode: string;
  isReloadable: boolean;
}

export interface AccountMember {
  firstName: string;
  lastName: string;
  email: string;
  custId: string;
  isActivateAccount: number;
  contractTypeId: string;
  civillity: string;
  memberType: string;
  phoneNumber: string;
  companyInfo: CompanyInfo;
  deliveryInfo: AccountAddressType;
  invoiceInfo: AccountAddressType;
  allowUpdateProfile: boolean;
}

export interface AccountProduct {
  itemCode: string;
  productName: string;
  productImage: string;
  totalAmount: string;
  productKind: string;
  expiryDateList: Array<{
    cardNumber: string;
    expiryDate: string;
  }>;
}

export interface TransactionRecord {
  deliveryMethod: string;
  mediaType: string;
  mediaUrl: string;
  detail: DetailInfo[];
  discount: number;
  qty: number;
  senderName: string;
  status: string;
  transAmount: string;
  transTime: string;
  uniqueNumber: string;
  transType: string;
  isInvoiceAvailable: boolean;
  paymentInfoList: PaymentInfo[];
}

export interface DetailInfo {
  amount: number;
  imageUrl: string;
  productName: string;
  qty: number;
  orderDeliverMethod: string;
  trackingNo?: string;
  email?: string;
  mobileNo?: string;
}

export interface PaymentInfo {
  paymentId: string;
  paymentMethod: string;
  paymentMethodCode: string;
}

export interface InboxMessage {
  amount: string;
  date: string;
  mediaType: string;
  mediaUrl: string;
  message: string;
  productImage: string;
  productName: string;
  sendMethod: string;
  sender: string;
}

export interface Customer {
  contractType: ContractType;
  paymentType: string | null;
  custId: string;
  custName: string;
}

export type AccountContactInfo = {
  civillity: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

export enum HistoryStatusType {
  OrderRequesting = '03',
  OrderRequesting2 = '041',
  OrderRequesting3 = '044',
  OrderConfirmed = '09',
  OrderConfirmed2 = '042',
  OrderConfirmed3 = '091',
  OrderCanceled = '02',
  OrderPending = '04',
  OrderFullReturned = '099',
  OrderPartialReturned = '098',
  TransferREQUEST = '01',
  TransferConfirmed = '03',
  WalletConfirmed = 'Y',
  WalletCanceled = 'N',
  WalletRequesting = 'R',
  All = 'All',
}

export enum SocialMediaType {
  GooGle = 'GG',
  FB = 'FB',
}

export enum SocialMediaName {
  Google = 'Google',
  Facebook = 'Facebook',
  Auchan = 'Auchan.fr',
}

export enum ContractType {
  Light = '1',
  EnCompte = '2',
  Registered = '3',
}

export enum WalletTransType {
  Redeem = 'P',
  TopUp = 'L',
}

export enum ProfileTabType {
  Company = 'cI',
  Contact = 'cD',
  Address = 'ad',
  Error = 'e',
}

export enum AddressType {
  Company = 'C',
  Invoicing = 'I',
  Delivery = 'D',
  Error = 'E',
}

export enum OrderDeliverMethodType {
  trackingNo = '0',
  mobile = '1',
  email = '3',
  emailMobile = '4',
}

export type TransferInfoType = {
  amount: number;
  cardNumber: string;
  deliverType: string;
  mediaUrl: string;
  message: string;
  receiverMail: string;
  receiverMobileNo: string;
  receiverName: string;
  senderMail: string;
  senderMobileNo: string;
  senderName: string;
  templateId: string;
};

export enum MemberType {
  B2C = 'C',
  B2B = 'B',
}
