/**
 *
 * CommonButton
 *
 */
import React from 'react';
import { ButtonVariant, ButtonSize } from 'types/common';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  className?: string;
  disabled?: boolean;
  iconSource?: string;
  text: string;
  onClick?: () => void;
}

function CommonButton(props: Props) {
  const {
    className = '',
    disabled = false,
    text,
    type = 'button',
    onClick,
    iconSource,
    variant = ButtonVariant.Primary,
    size = ButtonSize.Medium,
  } = props;

  const buttonClassName = [
    'common-button',
    `common-button--${variant}`,
    `common-button--${size}`,
    iconSource ? `common-button--hasIcon` : '',
    className,
  ].join(' ');
  return (
    <button
      type={type}
      onClick={onClick}
      className={buttonClassName}
      disabled={disabled}
    >
      {text}
      {iconSource && (
        <img className="common-button__icon" src={iconSource} alt="icon" />
      )}
    </button>
  );
}

export default CommonButton;
