/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  successTitle: () =>
    _t(translations.CommonNotification.successTitle, 'Success'),
  successContent: () => _t(translations.CommonNotification.successContent, ''),
  errorTitle: () =>
    _t(translations.CommonNotification.errorTitle, 'Error, please try again'),
  errorCouponCardBalance: () =>
    _t(
      translations.CommonNotification.errorCouponCardBalance,
      'Insufficient Card Balance',
    ),
  errorContent: () =>
    _t(
      translations.CommonNotification.errorContent,
      'Please contact us if there are any questions',
    ),
};
