/**
 *
 * Asynchronously loads the component for GuestPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const QueryPage = lazyLoad(
  () => import('./index'),
  module => module.QueryPage,
);

export const MobileQueryPage = lazyLoad(
  () => import('./index-mobile'),
  module => module.MobileQueryPage,
);
