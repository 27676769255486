/**
 *
 * Asynchronously loads the component for GuestPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const GuestPage = lazyLoad(
  () => import('./index'),
  module => module.GuestPage,
);

export const MobileGuestPage = lazyLoad(
  () => import('./index-mobile'),
  module => module.GuestPage,
);
