/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { createReducer } from './reducers';
import { get } from 'lodash';
import { encryption } from 'helpers/crypto';
import {
  startFetchingMiddleware,
  stopFetchingMiddleware,
} from './middlewares/fetchingHandlerMiddleware';
import { popupMiddleware } from './middlewares/popupMiddleware';
import {
  customLocalStorage,
  customSessionStorage,
  getBuIdFromUrl,
} from 'helpers';
export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [
    startFetchingMiddleware,
    sagaMiddleware,
    stopFetchingMiddleware,
    popupMiddleware,
  ];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  const store = configureStore({
    reducer: createReducer(),
    middleware: [...getDefaultMiddleware(), ...middlewares],
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
  });

  store.subscribe(() => {
    const globleState = store.getState();
    const { shoppingCart } = globleState;
    const buId = getBuIdFromUrl(true);
    const orders = get(shoppingCart, 'cartItems', []);
    const couponList = get(shoppingCart, 'couponList', []);
    // localStorage.setItem('orders', encryption(JSON.stringify(orders)));
    // sessionStorage.setItem('cl', encryption(JSON.stringify(couponList)));
    if (!!buId) {
      const { setItemLs } = customLocalStorage(buId);
      const { setItemSs } = customSessionStorage(buId);
      setItemLs('orders', encryption(JSON.stringify(orders)));
      setItemSs('cl', encryption(JSON.stringify(couponList)));
    }
  });

  return store;
}
