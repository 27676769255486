/**
 *
 * Banner
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { messages } from './messages';
import AdImage from 'img/footer/advertisement/app_ad_1.png';
import B2BADImage1 from 'img/footer/contactUs/pic/01.png';
import B2BADImage2 from 'img/footer/contactUs/pic/02.png';
import { getBuConfig } from 'helpers/BuHelper';

interface Props {}

export function Banner(props: Props) {
  const { t } = useTranslation();
  const {
    buName,
    supportPhoneNumber,
    supportEmail,
    googleAppStoreUrl,
    appleAppStoreUrl,
  } = getBuConfig();

  const matchB2B = useRouteMatch('/b2b');

  const [isShowAppStoreButton, setIsShowAppStoreButton] = React.useState(false);
  React.useEffect(() => {
    if (googleAppStoreUrl + appleAppStoreUrl !== '') {
      setIsShowAppStoreButton(true);
    }
  });

  return (
    <>
      {matchB2B ? (
        <section className="a-a-banner d-flex">
          <img src={B2BADImage1} alt="img1" />
          <img className="d-none d-xl-flex" src={B2BADImage2} alt="img2" />
          <aside>
            <h3>{t(...messages.b2bTitle())}</h3>
            <h4>{t(...messages.b2bSubTitle())}</h4>
            <div className="d-flex">
              <a className="sendEmail_btn" href={`mailto:${supportEmail}`}>
                {t(...messages.sendEmail())}
              </a>
              {supportPhoneNumber && (
                <a
                  className="callUs_btn"
                  href={`tel:${supportPhoneNumber.replaceAll(' ', '')}`}
                >
                  {t(...messages.callUs())}
                </a>
              )}
            </div>
          </aside>
        </section>
      ) : (
        <section id="a-m-banner">
          <div className="container d-flex align-items-center justify-content-center flex-wrap flex-lg-nowrap">
            <img src={AdImage} alt="ad" />
            <div className="d-flex flex-column flex-xl-row">
              <section>
                <h2>{t(...messages.title(), { buName })}</h2>
                <h3>{t(...messages.subTitle())}</h3>
              </section>
              {isShowAppStoreButton && (
                <div className="d-flex flex-column align-items-center flex-md-row flex-xl-column flex-xxl-row">
                  <a href={googleAppStoreUrl} className="btn btn-light">
                    {t(...messages.googlePlay())}
                  </a>
                  <a href={appleAppStoreUrl} className="btn btn-light">
                    {t(...messages.appStore())}
                  </a>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}
