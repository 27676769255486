import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import { salesmanBuyingSelfActions as actions } from '.';
import { OrderCreateInterface } from './constants';
import {
  fetchProductDetail,
  orderCreate,
  getProductsDiscount,
  ApiResponse,
} from 'app/APIs';
import { selectAuthUser } from 'app/pages/AuthPage/slice/selectors';
import { pick, keys, map, toNumber, isEmpty, includes } from 'lodash';
import { ProductDiscount } from 'types/common';
import { getBuIdFromUrl } from 'helpers';

function* fetchProductDiscountSaga(action) {
  const { itemCode, custId } = action.payload;
  try {
    const { accessToken } = yield select(selectAuthUser);
    const resp = yield call(getProductsDiscount, {
      itemCode,
      custId,
      accessToken,
    });
    if (!resp.success) {
      throw resp.data;
    }
    const discountList = {
      custId,
      itemCode,
      accumulateAmt: resp.data.accumulateAmt,
      isAccuSales: resp.data.isAccuSales,
      discountList: !isEmpty(resp.data.productDiscountList)
        ? resp.data.productDiscountList
        : [],
    };
    yield put(actions.fetchProductDiscountSuccess(discountList));
  } catch (error) {
    console.warn('fetchProductDiscountSaga', error);
    yield put(actions.fetchProductDiscountFail(error));
  }
}

function* fetchProductsDiscountSaga(action) {
  const { itemCodeList, custId } = action.payload;
  try {
    const { accessToken } = yield select(selectAuthUser);
    const respArray: Array<ApiResponse> = yield all(
      itemCodeList.map(itemCode =>
        call(getProductsDiscount, {
          itemCode,
          custId,
          accessToken,
        }),
      ),
    );

    if (
      includes(
        map(respArray, resp => resp.success),
        false,
      )
    ) {
      throw new Error('fetch template discount list fail');
    }

    const productsDiscountList: {
      custId: string;
      itemCode: string;
      accumulateAmt: number;
      isAccuSales: boolean;
      discountList: ProductDiscount[];
    }[] = respArray.map((resp, index) => ({
      custId,
      itemCode: itemCodeList[index],
      accumulateAmt: resp.data.accumulateAmt,
      isAccuSales: resp.data.isAccuSales,
      discountList: resp.data.productDiscountList,
    }));

    yield put(actions.fetchProductsDiscountSuccess(productsDiscountList));
  } catch (error) {
    console.warn('fetchProductDiscountSaga', error);
    yield put(actions.fetchProductDiscountFail(error));
  }
}

function* fetchProductDetailSaga(action) {
  const { itemCode, custId } = action.payload;
  try {
    const resp = yield call(
      fetchProductDetail,
      itemCode,
      getBuIdFromUrl(true),
      custId,
    );

    if (!resp.success) {
      throw resp.data;
    }

    if (resp.data.error) {
      throw resp.data.error;
    }

    yield put(
      actions.fetchProductDetailSuccess({
        itemCode: `${itemCode}_${custId}`,
        data: resp.data,
      }),
    );
  } catch (error) {
    console.warn('fetchProductDetailSaga', error);
    yield put(
      actions.fetchProductDetailFailure({ itemCode: `${itemCode}_${custId}` }),
    );
  }
}

function* checkoutSaga(action) {
  const {
    orders,
    callback,
    custId,
    orderImageUrl,
    freeItem,
    freeItemQty,
    paymentMean,
    rewardType,
    tempId,
  } = action.payload;
  try {
    const formattedOrders = map(orders, order =>
      pick(
        {
          ...order.data,
          deliverDate: order.data.deliverDate,
          templateId: toNumber(order.data.templateId),
        },
        keys(OrderCreateInterface),
      ),
    );

    const resp = yield call(orderCreate, {
      aggregatorBuId: getBuIdFromUrl(true),
      custId,
      orderImageUrl,
      order: formattedOrders,
      freeItem,
      freeItemQty,
      paymentMean,
      rewardType,
      tempId,
    });

    if (!resp.success) {
      throw resp.data;
    }

    yield put(actions.checkoutSuccess(resp.data.orderNo));
    callback && callback(resp.data.orderNo);
  } catch (error) {
    console.warn('checkoutSaga', error);
    yield put(actions.checkoutFailure(error));
  }
}

export function* salesmanBuyingSelfSaga() {
  yield takeLatest(actions.fetchProductDetail.type, fetchProductDetailSaga);
  yield takeLatest(actions.checkout.type, checkoutSaga);
  yield takeLatest(actions.fetchProductDiscount.type, fetchProductDiscountSaga);
  yield takeLatest(
    actions.fetchProductsDiscount.type,
    fetchProductsDiscountSaga,
  );
}
