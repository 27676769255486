import { DeliverType, OrderDetail, OrderDraftPath } from 'types/common';

export const OrderCreateInterface = {
  amount: 0,
  deliverDate: 'string',
  deliverType: 'string',
  extraItemCode: 'string',
  itemCode: 'string',
  letterTypeId: 'string',
  message: 'string',
  message2: 'string',
  personalImageUrl: 'string',
  qty: 0,
  receiverAddress: 'string',
  receiverCity: 'string',
  receiverCountryCode: 'string',
  receiverEmail: 'string',
  receiverMobileNo: 'string',
  receiverName: 'string',
  receiverVatNumber: 'string',
  receiverZipCode: 'string',
  senderEmail: 'string',
  senderName: 'string',
  templateId: 0,
};

export const ORDER_TEMPLATE: OrderDetail = {
  draftPath: OrderDraftPath.SalesmanToSelf,
  productImgUrl: '',
  productName: '',
  productDiscount: 0,
  additionalTaxRate: 0,
  productTaxRate: 0,
  categoryId: '',
  customerId: '',
  customerName: '',
  amount: 0,
  qty: 1,
  discount: '0%',
  itemCode: '',
  templateId: '',
  templateUrl: '',
  senderName: '',
  senderEmail: '',
  message: '',
  personalImageUrl: '',
  receiverCivility: '',
  receiverName: '',
  receiverFirstName: '',
  receiverLastName: '',
  receiverMobileAreaCode: '',
  receiverMobileNo: '',
  receiverEmail: '',
  receiverCity: '',
  receiverCountryCode: '',
  receiverAddress: '',
  receiverAddress2: '',
  receiverZipCode: '',
  receiverVatNumber: '',
  deliverType: DeliverType.PDF,
  deliverDate: '',
  deliverTimeZone: '',
  letterTypeId: '',
  letterPrice: 0,
  salesmanLetterType: null,
  salesmanLetterValue: null,
  message2: '',
  extraItemCode: '',
  extraItemImgUrl: '',
  extraProductName: '',
  extraProductAmount: 0,
  kind: '',
  cardMadeFee: 0,
  validMonths: 12,
};
