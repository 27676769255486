/**
 *
 * ForceLogoutPage
 *
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAccountSlice } from 'app/pages/AccountPage/slice';

import { useTranslation } from 'react-i18next';
import { getBuIdFromUrl, useLocalStorage, useParameter } from 'helpers';
import { useShoppingCartSlice } from 'app/pages/ShoppingCart/slice';
import { selectShoppingCart } from 'app/pages/ShoppingCart/slice/selectors';
import { selectMemberShip } from 'app/pages/HomePage/slice/selectors';
import { messages } from './messages';
import { LoadingProgress } from 'app/components/LoadingProgress';
import { MemberShipType } from 'types/common';

interface Props {}

export function ForceLogoutPage(props: Props) {
  const [isLogoutFetching, setIsLogoutFetching] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const { clearLocalStorage, getItemLs } = useLocalStorage();
  const { custType } = useParameter();
  const dispatch = useDispatch();
  const { actions } = useAccountSlice();
  const { actions: cartActions } = useShoppingCartSlice();
  const shoppingCart = useSelector(selectShoppingCart);
  const memberShip = useSelector(selectMemberShip);

  const clearShoppingCart = () => {
    if (shoppingCart.cartItems.length > 0) {
      dispatch(cartActions.clearAll());
    }
  };

  const logoutSuccess = () => {
    if (custType.isLogin) {
      clearShoppingCart();
    }
    clearLocalStorage();
    dispatch(actions.logout());
  };
  const logoutFailed = () => {
    setIsLogoutFetching(false);
    // setLogoOutError(t(...messages.logoutError()));
    // setIsShowLogoOutError(true);
  };

  useEffect(() => {
    if (!memberShip) {
      return;
    }
    setIsLogoutFetching(true);
    const getUrlString = window.location.href;
    const url = new URL(getUrlString);
    let path = custType.isDefinitelyB2B
      ? '/auth/b2b/login'
      : custType.isSalesman
      ? '/auth/salesman/login'
      : memberShip === MemberShipType.SSO ||
        Boolean(url.searchParams.get('failed'))
      ? '/'
      : '/auth/login';
    const oauthToken = getItemLs('refreshToken');
    const buIdPath = '/' + (getBuIdFromUrl(true) || '');
    path = path === '/' ? buIdPath : buIdPath + path;
    if (oauthToken) {
      dispatch(
        actions.getOauthLogout({
          refreshToken: oauthToken,
          accessToken: '',
          successCb: () => {
            logoutSuccess();
            window.location.href = path;
          },
          failedCb: () => {
            logoutFailed();
          },
        }),
      );
    } else {
      logoutSuccess();
      window.location.href = path;
    }
  }, [memberShip]);

  return (
    <>
      {isLogoutFetching && (
        <div className="loading-full-screen">
          <LoadingProgress />
        </div>
      )}
    </>
  );
}
