import { PayloadAction } from '@reduxjs/toolkit';
import {
  AddressInfo,
  CompanyInfo,
  ContactInfo,
} from 'app/pages/AuthPage/slice/types';
import { findIndex } from 'lodash';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { accountSaga } from './saga';
import {
  AccountState,
  InboxMessage,
  TransactionRecord,
  MemberCardDetail,
  TransferInfoType,
  Customer,
  AccountContactInfo,
} from './types';

export const initialState: AccountState = {
  isFetching: false,
  isTransfer: false,
  isTopUp: false,
  isNeedPinCode: null,
  member: {
    firstName: '',
    lastName: '',
    email: '',
    custId: '',
    contractTypeId: '1',
    isActivateAccount: 0,
    civillity: '',
    memberType: '',
    phoneNumber: '',
    allowUpdateProfile: false,
    companyInfo: {
      accountCategory: '',
      companyName: '',
      commercialName: '',
      sirenNumber: '',
      vatNumber: '',
      companyPhoneNumber: '',
      societyType: 0,
      city: '',
      country: '',
      firstAddress: '',
      secondAddress: '',
      zipCode: '',
      numberOfEmployees: '',
      isContactWithStore: 0,
    },
    deliveryInfo: {
      civillity: '',
      city: '',
      country: '',
      lastName: '',
      firstName: '',
      firstAddress: '',
      secondAddress: '',
      phoneNumber: '',
      zipCode: '',
    },
    invoiceInfo: {
      civillity: '',
      city: '',
      country: '',
      lastName: '',
      firstName: '',
      firstAddress: '',
      secondAddress: '',
      phoneNumber: '',
      zipCode: '',
    },
  },
  inboxMessages: [],
  wallet: null,
  error: null,
  memberHistory: {
    historyType: '',
    totalCount: 0,
    transactionRecords: [],
  },
  memberPreviousFile: '',
  products: null,
  cardDetail: {
    isReloadable: false,
    itemCode: '',
    productName: '',
    productImageUrl: '',
    howToUse: '',
    cardList: [],
    termOfService: '',
    transferMode: '',
  },
  cardTransactionData: {
    totalCount: 0,
    transactionList: [],
  },
  isCardResend: false,
  cardPrint: {
    file: {
      error: null,
      pdfUrl: '',
    },
    currentCardNo: '',
  },
  isUpdateSuccess: null,
  customerList: [],
  customerMember: undefined,
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    fetchMemberProfile(
      state,
      action: PayloadAction<{
        custId: string;
        accessToken: string;
        isForCustomer?: boolean;
        successCb?: () => void;
      }>,
    ) {
      state.isFetching = true;
    },
    fetchMemberProfileSuccess(
      state,
      action: PayloadAction<{
        custId: string;
        email: string;
        firstName: string;
        lastName: string;
        memberType: string;
        contractTypeId?: string;
        isActivateAccount?: number;
        allowUpdateProfile?: boolean;
      }>,
    ) {
      state.isFetching = false;
      state.member = { ...state.member, ...action.payload };
    },
    fetchMemberProfileB2BSuccess(
      state,
      action: PayloadAction<{
        custId: string;
        companyInfo: CompanyInfo;
        contactInfo: AccountContactInfo;
        deliverInfo: AddressInfo;
        invoiceInfo: AddressInfo;
        memberType: string;
        allowUpdateProfile: boolean;
      }>,
    ) {
      const {
        custId,
        memberType,
        companyInfo,
        contactInfo,
        invoiceInfo,
        deliverInfo,
        allowUpdateProfile,
      } = action.payload;
      state.isFetching = false;
      const newMember = {
        firstName: contactInfo?.firstName || '',
        lastName: contactInfo?.lastName || '',
        email: contactInfo?.email || '',
        custId,
        civillity: contactInfo?.civillity || '',
        memberType,
        phoneNumber: contactInfo.phoneNumber,
        companyInfo: companyInfo,
        invoiceInfo: invoiceInfo,
        deliveryInfo: deliverInfo,
        allowUpdateProfile,
      };
      state.member = { ...state.member, ...newMember };
    },
    fetchCustomerMemberProfileSuccess(
      state,
      action: PayloadAction<{
        custId: string;
        companyInfo: CompanyInfo;
        contactInfo: AccountContactInfo;
        deliverInfo: AddressInfo;
        invoiceInfo: AddressInfo;
        memberType: string;
        allowUpdateProfile: boolean;
      }>,
    ) {
      const {
        custId,
        memberType,
        companyInfo,
        contactInfo,
        invoiceInfo,
        deliverInfo,
        allowUpdateProfile,
      } = action.payload;
      state.isFetching = false;
      const newMember = {
        firstName: contactInfo?.firstName || '',
        lastName: contactInfo?.lastName || '',
        email: contactInfo?.email || '',
        custId,
        civillity: contactInfo?.civillity || '',
        memberType,
        phoneNumber: contactInfo.phoneNumber,
        companyInfo: companyInfo,
        invoiceInfo: invoiceInfo,
        deliveryInfo: deliverInfo,
        allowUpdateProfile,
      };
      state.customerMember = { ...state.member, ...newMember };
    },
    fetchMemberProfileFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.member = {
        ...state.member,
        custId: 'error',
        email: '',
        firstName: '',
        lastName: '',
        contractTypeId: '',
        isActivateAccount: 0,
      };
      state.error = action.payload;
    },
    fetchMemberPreviousFile(
      state,
      action: PayloadAction<{
        custId: string;
        accessToken: string;
        download?: boolean;
      }>,
    ) {
      state.isFetching = true;
    },
    fetchMemberPreviousFileSuccess(
      state,
      action: PayloadAction<{
        fileLink: string;
      }>,
    ) {
      state.isFetching = false;
      state.memberPreviousFile = action.payload.fileLink;
    },
    fetchMemberPreviousFileFailure(state) {
      state.isFetching = false;
    },
    updateMemberProfile(
      state,
      action: PayloadAction<
        | {
            custId: string;
            accessToken: string;
            firstName: string;
            lastName: string;
            email: string;
            memberType: string;
            password?: string;
            callback?: () => void;
          }
        | {
            custId: string;
            accessToken: string;
            memberType: string;
            contactInfo: AccountContactInfo;
            companyInfo: CompanyInfo;
            deliveryInfo: AddressInfo;
            invoiceInfo: AddressInfo;
            profileTab: string;
            editType?: string;
            password?: string;
            callback?: () => void;
          }
      >,
    ) {
      state.isFetching = true;
    },
    updateMemberProfileSuccess(
      state,
      action: PayloadAction<{
        firstName: string;
        lastName: string;
      }>,
    ) {
      state.isFetching = false;
      state.member = {
        ...state.member,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      };
      state.isUpdateSuccess = true;
    },
    updateB2BMemberProfileSuccess(
      state,
      action: PayloadAction<{
        contactInfo: AccountContactInfo;
        companyInfo: CompanyInfo;
        deliveryInfo: AddressInfo;
        invoiceInfo: AddressInfo;
      }>,
    ) {
      state.isFetching = false;
      const {
        companyInfo,
        contactInfo,
        invoiceInfo,
        deliveryInfo,
      } = action.payload;
      state.isFetching = false;
      const newMember = {
        firstName: contactInfo?.firstName || '',
        lastName: contactInfo?.lastName || '',
        email: contactInfo?.email || '',
        civillity: contactInfo?.civillity || '',
        phoneNumber: contactInfo.phoneNumber,
        companyInfo: companyInfo,
        invoiceInfo: invoiceInfo,
        deliveryInfo: deliveryInfo,
      };
      state.member = { ...state.member, ...newMember };
      state.isUpdateSuccess = true;
    },
    updateMemberProfileFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.error = action.payload;
      state.isUpdateSuccess = false;
    },
    resetIsUpdateSuccess(state) {
      state.isUpdateSuccess = null;
    },
    fetchInboxMessages(
      state,
      action: PayloadAction<{
        custId: string;
        accessToken: string;
      }>,
    ) {
      state.isFetching = true;
    },
    fetchInboxMessagesSuccess(state, action: PayloadAction<InboxMessage[]>) {
      state.isFetching = false;
      state.inboxMessages = action.payload;
    },
    fetchInboxMessagesFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    fetchMemberHistory(
      state,
      action: PayloadAction<{
        historyType: string;
        page: number;
        cardNumber?: string;
        dateBegin?: string;
        dateEnd?: string;
        status?: string;
        productName?: string;
      }>,
    ) {
      state.isFetching = true;
    },
    fetchMemberHistorySuccess(
      state,
      action: PayloadAction<{
        historyType: string;
        totalCount: number;
        transactionRecords: TransactionRecord[];
      }>,
    ) {
      state.isFetching = false;
      state.memberHistory = action.payload;
    },
    fetchMemberHistoryFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.error = action.payload;
      state.memberHistory = {
        historyType: state.memberHistory.historyType,
        totalCount: 0,
        transactionRecords: state.memberHistory.transactionRecords,
      };
    },
    resetMemberHistory(state) {
      state.memberHistory = {
        historyType: '',
        totalCount: 0,
        transactionRecords: [],
      };
    },
    fetchMemberWallet(
      state,
      action: PayloadAction<{
        custId: string;
        accessToken: string;
      }>,
    ) {
      state.isFetching = true;
    },
    fetchMemberWalletSuccess(
      state,
      action: PayloadAction<{
        balance: number;
        orderCount: number;
        isReloadable: boolean;
        cardNumber: string;
        processAmount: number;
      }>,
    ) {
      state.isFetching = false;
      state.wallet = action.payload;
    },
    fetchMemberWalletFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    fetchMemberProducts(
      state,
      action: PayloadAction<{
        custId: string;
        accessToken: string;
        productName?: string;
        categoryId?: string;
      }>,
    ) {
      state.isFetching = true;
    },
    fetchMemberProductsSuccess(state, action: PayloadAction<any[]>) {
      state.isFetching = false;
      state.products = action.payload;
    },
    fetchMemberProductsFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    fetchGiftCards(
      state,
      action: PayloadAction<{
        custId: string;
        accessToken: string;
        itemCode: string;
      }>,
    ) {
      state.isFetching = true;
    },
    fetchGiftCardsSuccess(state, action: PayloadAction<MemberCardDetail>) {
      state.isFetching = false;
      state.cardDetail = action.payload;
    },
    fetchGiftCardsFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    resetGiftCards(state) {
      state.products = null;
    },
    fetchTransaction(
      state,
      action: PayloadAction<{
        cardNumber: string;
        pageNo: number;
      }>,
    ) {
      state.isFetching = true;
    },
    fetchTransactionSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.cardTransactionData.totalCount = action.payload.totalCount;
      state.cardTransactionData.transactionList =
        action.payload.transactionList;
    },
    fetchTransactionFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    updateCard(
      state,
      action: PayloadAction<{
        cardNumber: string;
        actionType: string;
        callback: any;
        captchaToken: string;
      }>,
    ) {
      state.isFetching = true;
    },
    updateCardSuccess(
      state,
      action: PayloadAction<{
        cardNumber: string;
        isBlock: boolean;
      }>,
    ) {
      state.isFetching = false;
      if (state.cardDetail.cardList) {
        const currIndex = findIndex(state.cardDetail.cardList, [
          'cardNumber',
          action.payload.cardNumber,
        ]);
        if (currIndex !== -1) {
          state.cardDetail.cardList[currIndex].isBlocked =
            action.payload.isBlock;
        }
      }
    },
    updateCardFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    getCardTopUp(
      state,
      action: PayloadAction<{
        paymentInfo: any;
        accessToken: string;
        successCb: () => void;
        failedCb: () => void;
      }>,
    ) {
      state.isFetching = true;
    },
    getCardTopUpSuccess(state, action: PayloadAction<any>) {
      const { balance, cardNumber } = action.payload;
      state.isFetching = false;
      state.cardDetail.cardList = state.cardDetail.cardList.map(card =>
        card.cardNumber === cardNumber ? { ...card, balance } : card,
      );
      state.isTopUp = true;
    },
    getCardTopUpFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    clearIsTopUp(state) {
      state.isTopUp = false;
    },
    getTransactionTopUp(
      state,
      action: PayloadAction<{
        paymentInfo: any;
        accessToken: string;
        successCb: () => void;
        failedCb: () => void;
      }>,
    ) {
      state.isFetching = true;
    },
    getTransactionTopUpSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      if (state.wallet?.balance) {
        state.wallet.balance = action.payload.balance;
      }
    },
    getTransactionTopUpFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    fetchTransferCard(state, action: PayloadAction<TransferInfoType>) {
      state.isFetching = true;
    },
    fetchTransferCardSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.isTransfer = true;
    },
    fetchTransferCardFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.error = action.payload;
    },
    fetchCardValidation(state, action) {
      state.isFetching = true;
    },
    fetchCardValidationSuccess(state, action) {
      state.isFetching = false;
      state.isNeedPinCode = action.payload;
    },
    fetchCardValidationFailed(state, action) {
      state.isFetching = false;
      state.error = action.payload;
    },
    fetchCardValidationReset(state, action) {
      state.error = '';
      state.isNeedPinCode = null;
    },
    clearIsTransfer(state) {
      state.isTransfer = false;
    },
    clearError(state) {
      state.error = null;
    },
    logout(state) {
      state = initialState;
    },
    getCardResend(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    getCardResendSuccess(state) {
      state.isFetching = false;
      state.isCardResend = true;
    },
    getCardResendFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
      console.warn(action.payload);
    },
    resetIsCardResend(state) {
      state.isCardResend = false;
    },
    getCardPrint(
      state,
      action: PayloadAction<{ cardNumber: string; captchaToken: string }>,
    ) {
      const { cardNumber } = action.payload;
      if (state.cardPrint) {
        state.cardPrint = {
          ...state.cardPrint,
          currentCardNo: cardNumber,
        };
      }
      state.isFetching = true;
    },
    getCardPrintSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      if (state.cardPrint) {
        state.cardPrint = { ...state.cardPrint, file: action.payload };
      }
    },
    getCardPrintFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
      console.warn(action.payload);
    },
    getImportCard(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    getImportCardSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      if (action.payload.buId && action.payload.buId !== 'AFI_NON_WHITE') {
        state.member.isActivateAccount = 1;
      }
      console.log(action.payload);
    },
    getImportCardFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
      console.warn(action.payload);
    },
    getOauthLogout(
      state,
      action: PayloadAction<{
        refreshToken: string;
        accessToken: string;
        successCb?: () => void;
        failedCb?: () => void;
      }>,
    ) {
      state.isFetching = true;
    },
    getOauthLogoutSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    getOauthLogoutFailed(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    getCustomerList(
      state,
      action: PayloadAction<{
        custId: string;
      }>,
    ) {
      state.isFetching = true;
    },
    getCustomerListSuccess(state, action: PayloadAction<Customer[]>) {
      state.isFetching = false;
      state.customerList = action.payload;
    },
    getCustomerListFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.error = action.payload;
    },
  },
});

export const { actions: accountActions } = slice;

export const useAccountSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: accountSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAccountSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
