/**
 *
 * CommonPopup
 *
 */
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PopupMode,
  PopupImgType,
  PopupContentType,
  ButtonVariant,
} from 'types/common';
import modalCloseIcon from 'img/account/icon/modalCloseIcon.svg';
import FaqIcon from 'img/navigation/icon/help/normal.svg';
import CommonButton from '../CommonButton';
import { messages } from './messages';
import useWindowSize from 'utils/hooks/useWindowSize';
import { getBuConfig } from 'helpers/BuHelper';

interface Props {
  className?: string;
  contentClassName?: string;
  children?: React.ReactNode;
  imgType?: PopupImgType;
  mode?: PopupMode;
  contentType?: PopupContentType;
  showFAQ?: boolean;
  showPopup?: boolean;
  title?: string;
  btnCloseText?: string;
  btnCloseDisabled?: boolean;
  btnOkText?: string;
  btnOkDisabled?: boolean;
  handleClosePopup: () => void;
  handleBtnClose?: () => void;
  handleBtnOK?: () => void;
  isError?: boolean;
}

function CommonPopup(props: Props) {
  const { getAsset } = getBuConfig();
  const { PopUpError, PopUpSuccess, NavMainLogo } = getAsset();
  const { t } = useTranslation();
  const {
    className = '',
    contentClassName = '',
    children,
    imgType = PopupImgType.None,
    mode = PopupMode.Ask,
    contentType = PopupContentType.Medium,
    showFAQ = false,
    showPopup,
    title,
    btnCloseDisabled = false,
    btnCloseText,
    btnOkDisabled = false,
    btnOkText,
    handleClosePopup,
    handleBtnOK,
    handleBtnClose,
    isError = false,
  } = props;
  const { width } = useWindowSize();

  const scrollableInnerClassName = [
    'common-popup__scrollable-inner',
    `common-popup__scrollable-inner--${contentType}`,
  ].join(' ');

  const handleGoToFaq = () => {
    window.open('/faq');
  };

  useEffect(() => {
    return () => handleClosePopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`common-popup ${className}`} hidden={!showPopup}>
      <div className="common-popup__container">
        <div
          className="common-popup__close"
          onClick={handleClosePopup}
          hidden={mode === PopupMode.Ask && width > 991}
        >
          <img src={modalCloseIcon} alt="close modal" />
        </div>
        <div className="common-popup__scrollable">
          <div className={scrollableInnerClassName}>
            {imgType !== PopupImgType.None && (
              <img
                className="common-popup__image"
                src={NavMainLogo}
                alt="icon"
              />
            )}
            {title && <div className="common-popup__title">{title}</div>}
            <div className={`common-popup__content ${contentClassName}`}>
              {children}
            </div>
          </div>
        </div>
        {mode !== PopupMode.Buttonless && (
          <div className="common-popup__buttons">
            <CommonButton
              className="common-popup__button"
              disabled={btnOkDisabled}
              text={btnOkText ?? t(...messages.ok())}
              variant={ButtonVariant.Primary}
              onClick={handleBtnOK}
            />
            {mode === PopupMode.Ask && (
              <CommonButton
                className="common-popup__button"
                disabled={btnCloseDisabled}
                text={btnCloseText ?? t(...messages.close())}
                variant={ButtonVariant.PrimaryOutline}
                onClick={handleBtnClose || handleClosePopup}
              />
            )}
          </div>
        )}
        {showFAQ && (
          <Fragment>
            <div className="common-popup__line" />
            <div className="common-popup__faqs" onClick={handleGoToFaq}>
              <img src={FaqIcon} alt="faq" />
              {t(...messages.faq())}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default CommonPopup;
