/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 *
 * Footer
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { messages } from './messages';

import Facebook from 'img/footer/Contact/icon/Facebook_white.svg';
import Twitter from 'img/footer/Contact/icon/Twitter_white.svg';
import Pinterest from 'img/footer/Contact/icon/Pinterest_white.svg';
import Instagram from 'img/footer/Contact/icon/Instagram_white.svg';
import Youtube from 'img/footer/Contact/icon/Youtube_white.svg';
import { Link } from 'react-router-dom';
import { getBuConfig } from 'helpers/BuHelper';

interface Props {}

export function Footer(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const {
    isShowTermAndCondition,
    mediaList,
    moreInfoList,
    buName,
  } = getBuConfig();

  const getMediaList = () => {
    const mediaImgList = [
      {
        name: 'Twitter',
        img: Twitter,
      },
      {
        name: 'Pinterest',
        img: Pinterest,
      },
      {
        name: 'Instagram',
        img: Instagram,
      },
      {
        name: 'Youtube',
        img: Youtube,
      },
      {
        name: 'Facebook',
        img: Facebook,
      },
    ];

    const list: any[] = [];
    mediaList.forEach(media => {
      const img = mediaImgList.find(mediaImg => mediaImg.name === media.name);
      if (img) {
        list.push({ ...media, img: img?.img });
      }
    });
    return list;
  };
  const getMoreInfoList = () => {
    const moreInfoTextList = [
      {
        name: 'generalConditions',
        text: t(...messages.generalConditions()),
      },
      {
        name: 'legalNotices',
        text: t(...messages.legalNotices()),
      },
      {
        name: 'faq',
        text: t(...messages.faq()),
      },
    ];

    return moreInfoList.map(moreInfo => {
      const text = moreInfoTextList.find(
        moreInfoText => moreInfoText.name === moreInfo.name,
      );
      return { ...moreInfo, text: text?.text };
    });
  };

  const contactMediaList = getMediaList();
  const moreInfo = getMoreInfoList();
  return (
    <footer id="footer">
      <div className="a-m-footer">
        <div className="d-flex flex-column">
          <div className="a-m-link-lg d-flex mx-auto">
            <Link to={'/'}>
              <img
                src={require('img/footer/icon/logo/white.svg').default}
                alt="Auchan"
              />
            </Link>
            <div>
              {moreInfo.map(moreInfoItem =>
                moreInfoItem.innerHTML ? (
                  <Link key={moreInfoItem.text} to={`/${moreInfoItem.href}`}>
                    {moreInfoItem.text}
                  </Link>
                ) : (
                  <a
                    key={moreInfoItem.text}
                    href={moreInfoItem.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {moreInfoItem.text}
                  </a>
                ),
              )}
            </div>
          </div>
          {/* <div className="a-m-link-sm d-flex d-md-none flex-column align-items-center">
             <a href="/">
               <img
                 src={require('img/footer/icon/logo/white.svg').default}
                 alt="Achuan"
               />
             </a>
             <div className="d-flex">
               <div className="list-group">
                 <li className="list-group-item">Title A</li>
                 <a href="#" className="list-group-item list-group-item-action">
                   {t(...messages.about())}
                 </a>
                 <a
                   href="https://carte-cadeau.auchan.fr/conditions-generales-de-vente.html"
                   className="list-group-item list-group-item-action"
                 >
                   {t(...messages.generalConditions())}
                 </a>
                 <a
                   href="https://www.auchan.fr/auchan-fr-et-vous-cgv/e-auchan-fr-et-vous-cgv#article18"
                   className="list-group-item list-group-item-action"
                 >
                   {t(...messages.personalData())}
                 </a>
               </div>
               <div className="list-group">
                 <li className="list-group-item">Title B</li>
                 <a
                   href="https://carte-cadeau.auchan.fr/mentions-legales.html"
                   className="list-group-item list-group-item-action"
                 >
                   {t(...messages.legalNotices())}
                 </a>
                 <a
                   href="https://carte-cadeau.auchan.fr/infos-livraison.html"
                   className="list-group-item list-group-item-action"
                 >
                   {t(...messages.deliveryInfo())}
                 </a>
                 <a href="#" className="list-group-item list-group-item-action">
                   Undefined
                 </a>
               </div>
             </div>
           </div> */}
          {/* <div className="a-m-link-md d-none d-md-flex d-lg-none flex-column align-items-center">
             <a href="/">
               <img
                 src={require('img/footer/icon/logo/white.svg').default}
                 alt="Achuan"
               />
             </a>
             <div className="col-12 d-flex justify-content-between">
               <div className="col-2 text-center">
                 <a href="#">{t(...messages.about())}</a>
               </div>
               <div className="col-2 text-center">
                 <a href="https://carte-cadeau.auchan.fr/conditions-generales-de-vente.html">
                   {t(...messages.generalConditions())}
                 </a>
               </div>
               <div className="col-2 text-center">
                 <a href="https://www.auchan.fr/auchan-fr-et-vous-cgv/e-auchan-fr-et-vous-cgv#article18">
                   {t(...messages.personalData())}
                 </a>
               </div>
               <div className="col-2 text-center">
                 <a href="https://carte-cadeau.auchan.fr/mentions-legales.html">
                   {t(...messages.legalNotices())}
                 </a>
               </div>
               <div className="col-2 text-center">
                 <a href="https://carte-cadeau.auchan.fr/infos-livraison.html">
                   {t(...messages.deliveryInfo())}
                 </a>
               </div>
             </div>
           </div> */}
          {contactMediaList.length > 0 && (
            <>
              <hr />
              <div className="a-m-contact d-flex mx-auto">
                {contactMediaList.map(media => (
                  <a
                    key={media.name}
                    href={media.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={media.img} alt={media.name} />
                  </a>
                ))}
              </div>
            </>
          )}
          <div className="a-m-copyright d-flex align-items-center">
            <span>{`©${new Date().getFullYear()} Ogloba`}</span>
            <span>
              {isShowTermAndCondition && (
                <>
                  <a
                    href="https://carrefour.fr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(...messages.privacyPolicy())}
                  </a>
                  －
                  <a
                    href="https://carrefour.fr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(...messages.termsConditions())}
                  </a>
                </>
              )}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
