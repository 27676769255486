import i18next from 'locales/i18n';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { globalActions } from 'app/pages/GlobalLayer/slice';
import { shoppingCartActions as actions } from '.';
import { fetchApplyCard } from 'app/APIs';
import { Coupon, NotificationType, OrderDetail } from 'types/common';
import { groupBy, min, reduce } from 'lodash';
import { RootState } from 'types';
import { PayloadAction } from '@reduxjs/toolkit';
import { ShoppingCartState } from './types';
import { calculateCoupon } from 'helpers';

const ERROR_CODE_SUBPAYMENT = 11018;

const shoppingCartSelector = (state: RootState) => state.shoppingCart;

function* deleteOrderSaga(action) {
  if (!action.payload.dontShowToast) {
    yield put(
      globalActions.openNotification({
        type: NotificationType.Success,
        title: i18next.t('CommonNotification.successTitle'),
        content: i18next.t('ShoppingCart.deleteSuccessContent'),
      }),
    );
  }
  yield put(actions.requerySubPaymentCouponList());
}

function* addNewOrderSaga(
  action: PayloadAction<{ orderId: string; data: OrderDetail }>,
) {
  if (action.payload.orderId && action.payload.data) {
    yield put(actions.requerySubPaymentCouponList());
  }
}

function* fetchRequerySubPaymentCardSaga(action) {
  const shoppingCart: ShoppingCartState = yield select(shoppingCartSelector);
  const { cartItems, couponList: previousCouponList } = shoppingCart;
  const copyPreviousCouponList = previousCouponList.slice();
  const cartList = cartItems.map(e => ({
    templateId: e.data.templateId,
    itemCode: e.data.itemCode,
  }));
  let currentCouponList: Coupon[] = [];
  try {
    let arrMessageError: string[] = [];
    let error: {
      code: number;
      cardNumber: string;
      message: string;
    }[] = []; // manually caught error if and throw it on success
    yield put(actions.resetCouponList());
    // Iterate through previous couponList
    for (const currentCoupon of copyPreviousCouponList) {
      const resp = yield call(fetchApplyCard, {
        cardNumber: currentCoupon.cardNumber,
        pinCode: '',
        cartList,
      });
      if (!resp.success) {
        console.warn(resp.data);
        error = [
          ...error,
          {
            code: resp.data?.error?.code ?? 0,
            cardNumber: currentCoupon.cardNumber,
            message: ' fetch query card fail',
          },
        ];
      } else if (resp.data?.error && resp.data?.error?.message) {
        let message = resp.data?.error?.message;
        // add error message for subPayment card
        if (resp.data.subPayDesc) {
          message += ` ${resp.data.subPayDesc}`;
        }

        error = [
          ...error,
          {
            code: resp.data.error.code,
            cardNumber: currentCoupon.cardNumber,
            message,
          },
        ];
      } else {
        const {
          cardBalance,
          cardNumber: couponCardNumber,
          isAllowPartialRedeem,
          subPayDesc, // retrieve sub-payment card data from api response
          suitedItemCode, // retrieve itemCode[] that is used to check applicable item with coupon
        } = resp.data;
        const coupon = {
          cardBalance,
          cardNumber: couponCardNumber,
          isAllowPartialRedeem,
          subPayDesc, // return sub-payment card data
          suitedItemCode,
        };
        currentCouponList = [...currentCouponList, coupon];
      }
    }
    const groupedError = groupBy(error, e => e.code);
    for (const code in groupedError) {
      const currError = groupedError[code];
      let keywordMessage = '';
      if (parseInt(code) === ERROR_CODE_SUBPAYMENT) {
        keywordMessage = 'Removed coupon: ';
      } else keywordMessage = 'fetch query card failed on coupon: ';
      const arrCardNumber = currError.map(e => e.cardNumber);
      arrMessageError = [
        ...arrMessageError,
        keywordMessage + arrCardNumber.join(', '),
      ];
    }
    if (error.length === previousCouponList.length) {
      throw new Error(arrMessageError.join('; '));
    } else {
      yield put(
        actions.requeryCouponListSuccess({
          couponList: currentCouponList,
          error: arrMessageError,
        }),
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(actions.getCouponListFailed(error.message));
    }
  }
}

function* fetchQueryCardSaga(action) {
  const {
    cardNumber,
    pinCode,
    totalAmount,
    successCallback,
    warnCallback,
    captchaToken,
  } = action.payload;
  const shoppingCart: ShoppingCartState = yield select(shoppingCartSelector);
  const { cartItems, couponList } = shoppingCart;
  const cartList = cartItems.map(e => ({
    templateId: e.data.templateId,
    itemCode: e.data.itemCode,
  }));
  try {
    const resp = yield call(fetchApplyCard, {
      cardNumber,
      pinCode,
      cartList,
      captchaToken,
    });
    if (!resp.success) {
      console.warn(resp.data);
      throw new Error(' fetch query card fail');
    }

    if (resp.data?.error && resp.data?.error?.message) {
      let message = resp.data?.error?.message;
      // handle error subPayment card
      if (resp.data.subPayDesc) {
        message += ` ${resp.data.subPayDesc}`;
      }
      throw new Error(message);
    }
    const {
      cardBalance,
      cardNumber: couponCardNumber,
      isAllowPartialRedeem,
      subPayDesc, // retrieve sub-payment card data from api response
      suitedItemCode, // retrieve itemCode[] that is used to check applicable item with coupon
    } = resp.data;
    const coupon = {
      cardBalance,
      cardNumber: couponCardNumber,
      isAllowPartialRedeem,
      subPayDesc, // return sub-payment card data
      suitedItemCode,
    };

    if (suitedItemCode) {
      let isCouponUsable = false;

      // make sure that if the new coupon has suitedItemCode which is
      // the same with the current coupon, the existing coupon will prioritize
      // to calculate suitedItemCode which is not overlapping with the new coupon
      const updatedCouponList = couponList.map(e => {
        if (e.suitedItemCode) {
          const filteredSuitedItemCode = e.suitedItemCode
            .slice()
            .filter(
              a => suitedItemCode.findIndex(b => b.itemCode === a.itemCode) < 0,
            );

          const sortedSuitedItemCode = [...e.suitedItemCode];
          sortedSuitedItemCode.sort((a, b) => {
            const isItemCodeFound_A = filteredSuitedItemCode.findIndex(
              e => e.itemCode === a.itemCode,
            );
            const isItemCodeFound_B = filteredSuitedItemCode.findIndex(
              e => e.itemCode === b.itemCode,
            );
            if (isItemCodeFound_A === isItemCodeFound_B) {
              return 0;
            } else {
              return -1;
            }
          });

          return {
            ...e,
            suitedItemCode: sortedSuitedItemCode,
          };
        }

        return e;
      });

      const { cartBalance } = calculateCoupon(cartItems, updatedCouponList);

      suitedItemCode.map(e => {
        const currentItemBalance = cartBalance[e.itemCode];
        if (currentItemBalance && currentItemBalance.amountLeft > 0) {
          isCouponUsable = true;
          return;
        }
      });

      if (!isCouponUsable) {
        yield put(
          actions.getCouponListFailed(i18next.t('ImportCoupon.notUsableError')),
        );
        return;
      }
    }

    if (cardBalance > 0) {
      yield put(
        actions.getCouponListSuccess({ coupon, warnCallback, totalAmount }),
      );
      successCallback();
    } else {
      yield put(
        actions.getCouponListFailed(i18next.t('ImportCoupon.balanceError')),
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(actions.getCouponListFailed(error.message));
    }
  }
}

export function* shoppingCartSaga() {
  yield takeLatest(actions.deleteDraftOrder.type, deleteOrderSaga);
  yield takeLatest(actions.deleteOrder.type, deleteOrderSaga);
  yield takeLatest(actions.getCouponList.type, fetchQueryCardSaga);
  yield takeLatest(
    actions.requerySubPaymentCouponList.type,
    fetchRequerySubPaymentCardSaga,
  );
  yield takeLatest(actions.addNewOrder.type, addNewOrderSaga);
}
