/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/**
 *
 * Navigation
 *
 */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  NavLink,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { find, isEmpty, map, get, reduce, toNumber, findIndex } from 'lodash';
import { Dropdown, Modal } from 'react-bootstrap';

import { DeliverType, OrderDraft, LetterType, Coupon } from 'types/common';
import {
  checkDiscountAmount,
  backendDiscountAmount,
  discountAmount,
  useGuestModeCheck,
  useParameter,
  useSSOLogin,
  floatAdd,
  floatMultiply,
  useCountLetterAmount,
  getHomePage,
  useCountCouponAmount,
  useLocalStorage,
  useSessionStorage,
} from 'helpers';
import { messages } from './messages';
import { useAccountSlice } from 'app/pages/AccountPage/slice';
import { selectAuthUser } from 'app/pages/AuthPage/slice/selectors';
import { selectIsAllowMultipleProduct } from 'app/pages/HomePage/slice/selectors';
import { CompanyInfo, CustType } from 'app/pages/AuthPage/slice/types';
import { useOrderSlice } from 'app/pages/Order/slice';
import { buLanguageList } from 'app/APIs/apiUrl';
import { selectPayProcessing } from 'app/pages/Order/slice/selectors';
import flagEN from 'img/navigation/icon/contact/flags/EN.svg';
import flagFR from 'img/navigation/icon/contact/flags/FR.svg';
import flagES from 'img/navigation/icon/contact/flags/ES.svg';
import flagAR from 'img/navigation/icon/contact/flags/AR.svg';
import MobileMenuIcon from 'img/navigation/icon/menu.svg';
import MobileMenuCloseIcon from 'img/navigation/icon/cross.svg';
import modalCloseIcon from 'img/account/icon/modalCloseIcon.svg';
import Logout from 'img/account/icon/logout.svg';
import { MemberType } from 'app/pages/AccountPage/slice/types';
import LinkedIn from 'img/navigation/icon/socialMedia/LinkedIn.svg';
import Facebook from 'img/navigation/icon/socialMedia/FB.svg';
import Twitter from 'img/navigation/icon/socialMedia/Twitter.svg';
import Pinterest from 'img/navigation/icon/socialMedia/Pinterest.svg';
import Instagram from 'img/navigation/icon/socialMedia/IG.svg';
import Youtube from 'img/navigation/icon/socialMedia/youtube.svg';
import {
  selectIsFetching,
  selectWallet,
} from 'app/pages/AccountPage/slice/selectors';
import { MobileNavigationMenu } from './MobileMenu';
import { LoadingProgress } from '../LoadingProgress';
import { getBuConfig } from 'helpers/BuHelper';
import { paymentBu } from 'app/APIs/apiUrl';
import { P } from '../NotFoundPage/P';

interface Props {
  isMobile: boolean;
  cartList: OrderDraft[];
  walletBalance: number;
  member: {
    firstName: string;
    lastName: string;
    memberType: string;
    companyInfo: CompanyInfo;
    [key: string]: any;
  };
  user: {
    accessToken: string;
    custName: string;
    custId: string;
    custType: CustType;
    [key: string]: any;
  };
  letterTypeList: LetterType[];
  isAccountFetching: boolean;
  clearShoppingCart: () => void;
  couponList: Coupon[];
}

export function Navigation(props: Props) {
  const { t, i18n } = useTranslation();
  const {
    getAsset,
    supportPhoneNumber,
    supportEmail,
    defaultLang,
    isNeedB2b,
    mediaList,
    isNeedWallet,
    isNeedQuery,
    isShowNavFaq,
    locationUrl,
    isEnableHomePageTelephone,
    isEnableHomePageEmail,
    isEnableNavigationMap,
  } = getBuConfig();
  const {
    NavCartHoverIcon,
    NavCartIcon,
    NavCartMobileIcon,
    NavEmailIcon,
    NavHelpHoverIcon,
    NavHelpIcon,
    NavHelpMobileIcon,
    NavMainLogo,
    NavMainLogoMobile,
    NavMapHoverIcon,
    NavMapIcon,
    NavMapMobileIcon,
    NavPopUpCartIcon,
    NavPopUpWalletIcon,
    NavTelephoneIcon,
    NavWalletHoverIcon,
    NavWalletIcon,
    NavPopUpTopupIcon,
  } = getAsset();

  const dispatch = useDispatch();
  const { actions } = useAccountSlice();
  const authUser = useSelector(selectAuthUser);
  const { actions: orderActions } = useOrderSlice();
  const isWalletFetching = useSelector(selectIsFetching);
  const isAllowMultipleProduct = useSelector(selectIsAllowMultipleProduct);
  const {
    cartList,
    walletBalance,
    member,
    user,
    letterTypeList,
    isAccountFetching,
    clearShoppingCart,
    couponList,
    isMobile,
  } = props;
  let location = useLocation();
  const { setItemSs, getItemSs } = useSessionStorage();
  const memberWallet = useSelector(selectWallet);
  const { loginWithOAuthAndRedirectUrl, loginWithOAuth } = useSSOLogin();
  const { isOnlySSO, checkAuthBeforeCheckout } = useGuestModeCheck();
  const matchAuth = useRouteMatch('/auth');
  const matchFaqPage = useRouteMatch('/faq');
  const matchScrollPage = useRouteMatch([
    '/buying-friend',
    '/buying-self',
    '/b2b/buying-self',
    '/b2b/buying',
    '/salesman/buying-self',
    '/salesman/buying/',
    '/query',
    '/account',
  ]);
  const {
    custType,
    currency,
    amountFormate,
    payment: { isShowMyWallet },
  } = useParameter();
  const matchOrder = useRouteMatch({
    path: [
      '/buying-friend',
      '/buying-self',
      '/b2b/buying-self',
      '/b2b/buying/',
      '/salesman/buying-self',
      '/salesman/buying/',
    ],
    exact: true,
  });

  const matchB2BSelfCheckOut =
    isAllowMultipleProduct == 0 && false /*暫時拿掉*/
      ? ['/b2b/buying-self/checkout', '/salesman/buying-self/checkout']
      : [];
  const matchB2BCheckOut = useRouteMatch([
    ...matchB2BSelfCheckOut,
    '/b2b/buying/checkout',
    '/salesman/buying/checkout',
  ]);
  const { couponAmountApplied } = useCountCouponAmount(couponList, cartList);
  const couponAmount = couponAmountApplied;

  const history = useHistory();
  const { getItemLs, removeItemLs, clearLocalStorage } = useLocalStorage();
  const { totalLetterAmount } = useCountLetterAmount(cartList, letterTypeList);
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const [isCardArea, setIsCardArea] = useState<boolean>(false);
  const [mapHover, setMapHover] = useState<boolean>(false);
  const [cartHover, setCartHover] = useState<boolean>(false);
  const [faqHover, setFaqHover] = useState<boolean>(false);
  const [walletHover, setWalletHover] = useState<boolean>(false);
  const [isShowLogin, setShowLogin] = useState<boolean>(false);
  const [isShowWarn, setIsShowWarn] = useState<boolean>(false);
  const [isHideShadow, setIsHideShadow] = useState<boolean>(false);
  const [isMobileMenu, setIsMobileMenu] = useState<boolean>(false);
  const [path, setPath] = useState<string>('');
  const { language } = i18n;
  const modalRef = useRef(null);
  const [modal, setModal] = useState<any>();
  const isPayProcessing = useSelector(selectPayProcessing);

  const isWidgetFormOpen = getItemLs('isWidgetFormOpen') === '1';

  const getDeliverText = type => {
    switch (type) {
      case DeliverType.Email:
        return t(...messages.email());
      case DeliverType.SMS:
        return t(...messages.SMS());
      case DeliverType.Physical:
        return t(...messages.physical());
      case DeliverType.BOTH:
        return `${t(...messages.email())} & ${t(...messages.SMS())}`;
      default:
        return '';
    }
  };

  useEffect(() => {
    const currntURLPath = window.location.pathname;
    if (currntURLPath.includes('buying-friend')) {
      setIsHideShadow(true);
    } else {
      setIsHideShadow(false);
    }
  });

  const handleScroll = () => {
    const offset = window.scrollY;
    const offsetDefaultValueMobile = window.innerWidth < 768 ? 170 : 330;

    if (
      offset >= offsetDefaultValueMobile &&
      offset <= offsetDefaultValueMobile + 60
    ) {
      setIsHideShadow(true);
    } else {
      setIsHideShadow(false);
    }

    if (offset > 80 && window.innerWidth > 991) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }

    if (offset > 600) {
      setIsCardArea(true);
    } else {
      setIsCardArea(false);
    }
  };

  const isRender = () => {
    if (matchAuth) {
      return true;
    }
    return true;
  };

  const handleBeforeGoToNextPath = nextPath => {
    if (nextPath === '/auth/login' && isOnlySSO) {
      loginWithOAuth();
      return;
    }
    history.push(nextPath);
  };

  const hashScrollWithOffset = (el, yOffset) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'auto' });
  };

  const handleLocaleChange = useCallback(lang => {
    if (lang === 'ar') {
      document.body.className = 'arabic';
    } else {
      document.body.className = '';
    }
    i18n.changeLanguage(lang);
  }, []);

  const saveLanguage = useCallback(lang => {
    setItemSs('lang', lang);
  }, []);

  const getLanguageList = () => {
    const languageList = [
      {
        key: 'en',
        title: 'EN',
        longTitle: 'English',
        flagSrc: flagEN,
      },
      {
        key: 'fr',
        title: 'FR',
        longTitle: 'Français',
        flagSrc: flagFR,
      },
      {
        key: 'es',
        title: 'ES',
        longTitle: 'Español',
        flagSrc: flagES,
      },
      {
        key: 'ar',
        title: 'AR',
        longTitle: 'عربي',
        flagSrc: flagAR,
      },
    ];
    const list = languageList.filter(language =>
      buLanguageList.includes(language.key),
    );
    return list;
  };

  const getMediaList = () => {
    const mediaImgList = [
      {
        name: 'Twitter',
        img: Twitter,
      },
      {
        name: 'Pinterest',
        img: Pinterest,
      },
      {
        name: 'Instagram',
        img: Instagram,
      },
      {
        name: 'Youtube',
        img: Youtube,
      },
      {
        name: 'Facebook',
        img: Facebook,
      },
      {
        name: 'LinkedIn',
        img: LinkedIn,
      },
    ];

    const list: any[] = [];
    mediaList.forEach(media => {
      const img = mediaImgList.find(mediaImg => mediaImg.name === media.name);
      if (img) {
        list.push({ ...media, img: img?.img });
      }
    });
    return list;
  };

  const LanguageList = getLanguageList();
  const currentLang = find(LanguageList, ['key', language]);
  const currentSaveLang = getItemSs('lang');
  const contactMediaList = getMediaList();

  useEffect(() => {
    if (isMobileMenu) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isMobileMenu]);

  useEffect(() => {
    removeItemLs('isWidgetFormOpen');
    const { Modal } = require('bootstrap');
    setModal(new Modal(modalRef.current));
  }, []);

  useEffect(() => {
    if (currentSaveLang && currentSaveLang !== currentLang?.key) {
      handleLocaleChange(currentSaveLang);
    }
  }, [currentSaveLang, currentLang, handleLocaleChange]);

  useEffect(() => {
    const navigationLanguage = getItemSs('lang') || defaultLang;
    const languageKeyList = LanguageList.map(language => language.key);
    if (languageKeyList.includes(navigationLanguage)) {
      handleLocaleChange(navigationLanguage);
      saveLanguage(navigationLanguage);
    }
  }, [handleLocaleChange, saveLanguage]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!isRender()) {
    return null;
  }

  const totalAmount = (letterAmount, excludeNoDiscountAmount?) => {
    const totalAmount = reduce(
      cartList,
      (acc, order) => {
        const basicAmount =
          order.data.uploadFileList && order.data.uploadFileList.length > 0
            ? backendDiscountAmount(
                get(order, 'data.amount', 0),
                get(order, 'data.salesmanSetDiscount', null) === null
                  ? get(order, 'data.productDiscount', 0)
                  : get(order, 'data.salesmanSetDiscount', 0),
              )
            : checkDiscountAmount(
                get(order, 'data.amount', 0),
                get(order, 'data.salesmanSetDiscount', null) === null
                  ? get(order, 'data.productDiscount', 0)
                  : get(order, 'data.salesmanSetDiscount', 0),
                get(order, 'data.qty', 0),
              );

        return floatAdd(
          acc,
          floatAdd(
            basicAmount,
            floatAdd(
              excludeNoDiscountAmount
                ? 0
                : floatMultiply(
                    get(order, 'data.extraProductAmount', 0),
                    get(order, 'data.qty', 0),
                  ),
              excludeNoDiscountAmount
                ? 0
                : floatMultiply(
                    get(order, 'data.cardMadeFee', 0),
                    get(order, 'data.qty', 0),
                  ),
            ),
          ),
        );
      },
      0,
    );

    const total = excludeNoDiscountAmount
      ? totalAmount
      : floatAdd(totalAmount, letterAmount) - couponAmount;

    return total < 0 ? 0 : total;
  };

  const handleChangePath: (
    redirectPath: string,
    event?: React.MouseEvent<HTMLElement> | any,
  ) => void = (redirectPath, event) => {
    if (matchOrder || matchB2BCheckOut || isPayProcessing) {
      event && event.preventDefault();
      setPath(redirectPath);
      handleBeforeGoToNextPath(redirectPath);
    }
  };

  const salesmanLogout = () => {
    clearShoppingCart();
    clearLocalStorage();
    dispatch(actions.logout());
    history.replace('/');
    window.location.reload();
  };

  const findNextLanguageIndex = () => {
    if (currentLang) {
      let selectedLanguageIndex = findIndex(LanguageList, [
        'key',
        currentLang.key,
      ]);

      if (selectedLanguageIndex === LanguageList.length - 1) {
        selectedLanguageIndex = 0;
      } else {
        selectedLanguageIndex++;
      }

      return selectedLanguageIndex;
    } else return 0;
  };

  useEffect(() => {
    setIsMobileMenu(false);
  }, [location]);

  const handleOpenWallet = () => {
    // Find Dropdown parent, check if its displaying & Create a delay to let Dropdown element displayed
    setTimeout(() => {
      const isPopupWallet =
        document
          .getElementById('navbar_popUp_wallet_container')
          ?.className.includes('show') ?? false;

      if (
        isPopupWallet &&
        authUser &&
        user?.accessToken &&
        member &&
        custType.isB2B &&
        isNeedWallet &&
        isShowMyWallet
      ) {
        const { custId, accessToken } = authUser;
        dispatch(actions.fetchMemberWallet({ custId, accessToken }));
      }
    }, 0);
  };

  return (
    <>
      <header id="navigation">
        <div
          className={`a-n-nav fixed-top
        ${matchScrollPage && isScroll ? 'a-n-scrollup' : 'a-n-scrolldown'}`}
        >
          {!isMobile && paymentBu !== 'UAE' && (
            <nav
              id="navInfoBar"
              className={`d-none d-lg-flex justify-content-between align-items-center ${
                isScroll ? 'a-n-infoBar-scrollup' : 'a-n-scrolldown'
              } ${isMobile ? 'navbar-logo-mobile' : ''}`}
            >
              <div
                id="navInfo_socialMediaSection"
                className="d-flex align-items-center"
              >
                {contactMediaList.map(media => (
                  <a
                    key={media.name}
                    className="d-flex align-items-center justify-content-center"
                    href={media.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={media.img} alt={media.name} />
                  </a>
                ))}
              </div>
              <div
                id="navInfo_contactSection"
                className="d-flex align-items-center"
              >
                {isEnableHomePageTelephone && (
                  <div
                    id="navInfo_telephone"
                    className="d-flex align-items-center"
                  >
                    <img
                      src={require('img/navigation/icon/telephone.svg').default}
                      alt="Tel"
                    />
                    <a href={`tel:${supportPhoneNumber.replaceAll(' ', '')}`}>
                      {supportPhoneNumber}
                    </a>
                  </div>
                )}
                {isEnableHomePageEmail && (
                  <div id="navInfo_email" className="d-flex align-items-center">
                    <img
                      src={require('img/navigation/icon/email.svg').default}
                      alt="Email"
                    />
                    <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                  </div>
                )}
                <div id="navInfoBar_popUp_language" className="dropdown">
                  <button
                    type="button"
                    id="navInfo_language"
                    className="d-flex align-items-center justify-content-center btn"
                    data-bs-toggle="dropdown"
                  >
                    {currentLang && (
                      <>
                        <img
                          src={currentLang.flagSrc}
                          alt={`current-${currentLang.key}`}
                        />
                        <h5>{currentLang.title}</h5>
                      </>
                    )}
                  </button>
                  <ul
                    id="navInfoBar_popUp_language_dropdownMenu"
                    aria-labelledby="navInfo_language"
                    className="navBar_popUp dropdown-menu dropdown-menu-end"
                  >
                    {LanguageList.map(lang =>
                      lang.key !== i18n.language ? (
                        <li
                          key={lang.key}
                          className="d-flex align-items-center justify-content-center"
                          onClick={() => {
                            handleLocaleChange(lang.key);
                            saveLanguage(lang.key);
                          }}
                        >
                          <a
                            className="dropdown-item d-flex align-items-center justify-content-center"
                            href="#"
                          >
                            <img src={lang.flagSrc} alt={lang.key} />
                            <h5>{lang.title}</h5>
                          </a>
                        </li>
                      ) : null,
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          )}
          <nav
            id="navBar"
            className={`navbar navbar-expand-lg navbar-light bg-light ${
              isScroll && paymentBu !== 'UAE'
                ? 'a-n-infoBar-scrollup'
                : 'a-n-scrolldown'
            } ${isMobile ? 'mobile-padding' : 'p-0'}`}
          >
            <div className="container-fluid px-3 px-lg-0 d-flex align-items-center">
              <div
                id="navBar_leftSection"
                className="d-flex align-items-start align-items-lg-center"
              >
                <Link
                  id="navLogo"
                  className={`d-flex navbar-brand${isMobile ? '-mobile' : ''}`}
                  onClick={e => {
                    handleChangePath(getHomePage(user?.custType), e);
                  }}
                  to={getHomePage(user?.custType)}
                >
                  <img
                    src={isMobile ? NavMainLogoMobile : NavMainLogo}
                    alt="navLogo"
                  />
                </Link>
                {!isMobile && custType.isB2C && isNeedB2b && (
                  <NavLink
                    activeClassName="a-n-buyeGiftcards-scroll"
                    id="navBar_forCustomer"
                    className={`nav-link text-nowrap d-none d-lg-block ${
                      custType.isB2B ? 'navBar_disable' : ''
                    }`}
                    to="/"
                    strict={false}
                    isActive={(match, location) => {
                      return !(
                        location.pathname.includes('/b2b') ||
                        location.pathname.includes('/salesman')
                      );
                    }}
                    onClick={e => {
                      if (custType.isB2B) {
                        e.preventDefault();
                        return;
                      }
                      handleChangePath('/', e);
                    }}
                  >
                    <div className="custom-border-bottom-container">
                      {t(...messages.forCustomer())}
                      <div className="custom-border-bottom" />
                    </div>
                  </NavLink>
                )}
                {!isMobile &&
                  (!custType.isLogin || custType.isDefinitelyB2B) &&
                  isNeedB2b && (
                    <NavLink
                      activeClassName="a-n-buyeGiftcards-scroll"
                      id="navBar_forBusiness"
                      className={`nav-link text-nowrap d-none d-lg-block ${
                        custType.isB2CUser ? 'navBar_disable' : ''
                      }`}
                      to={
                        custType.isB2C
                          ? '/b2b/info'
                          : custType.isB2B
                          ? '/b2b'
                          : '/salesman'
                      }
                      strict={false}
                      isActive={(match, location) => {
                        return location.pathname.includes('/b2b');
                      }}
                      onClick={e => {
                        if (custType.isB2CUser) {
                          e.preventDefault();
                          return;
                        }
                        handleChangePath(
                          custType.isB2C
                            ? '/b2b/info'
                            : custType.isB2B
                            ? '/b2b'
                            : '/salesman',
                        );
                      }}
                    >
                      <div className="custom-border-bottom-container">
                        {t(...messages.forBusiness())}
                        <div className="custom-border-bottom" />
                      </div>
                    </NavLink>
                  )}
              </div>
              {!isMobile && paymentBu !== 'UAE' && (
                <ul
                  id="navBar_rightSection"
                  className="navbar-nav ms-auto d-none d-xl-flex align-items-center"
                >
                  {!(
                    custType.isSalesman ||
                    location.pathname.includes('salesman')
                  ) &&
                    isNeedQuery && (
                      <li id="navBar_queryCard" className="nav-item d-flex">
                        <NavLink
                          activeClassName="a-n-query-card"
                          id="navBar_query"
                          to={'/query'}
                          className="nav-link d-flex justify-content-center align-items-center"
                          onClick={e => handleChangePath('/query', e)}
                        >
                          {t(...messages.queryCard())}
                        </NavLink>
                      </li>
                    )}
                  {isEnableNavigationMap && (
                    <li
                      id="navBar_map"
                      className="nav-item d-flex flex-column align-items-center"
                      onMouseEnter={() => setMapHover(true)}
                      onMouseLeave={() => setMapHover(false)}
                    >
                      <a
                        className="nav-link d-flex justify-content-center align-items-center"
                        href={locationUrl}
                        target="blank"
                      >
                        <img
                          src={mapHover ? NavMapHoverIcon : NavMapIcon}
                          alt="map"
                        />
                        <span
                          className={
                            mapHover ? 'narBar-text-hover' : 'narBar-text'
                          }
                        >
                          {t(...messages.map())}
                        </span>
                      </a>
                    </li>
                  )}
                  <li
                    id="navBar_cart"
                    className="nav-item d-flex flex-column align-items-center"
                    onMouseEnter={() => setCartHover(true)}
                    onMouseLeave={() => setCartHover(false)}
                  >
                    <Dropdown>
                      <Dropdown.Toggle
                        id="navBar_popUp_cart"
                        className="nav-link btn btn-light d-flex justify-content-center align-items-center dropdown-toggle"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span>
                          <img
                            src={cartHover ? NavCartHoverIcon : NavCartIcon}
                            alt="cart"
                          />
                        </span>
                        <span
                          className={
                            cartHover ? 'narBar-text-hover' : 'narBar-text'
                          }
                        >
                          {t(...messages.cart())}
                        </span>
                        {!isEmpty(cartList) && cartList.length > 0 && (
                          <mark>{cartList.length}</mark>
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        // as="ul"
                        as="div"
                        aria-labelledby="navBar_popUp_cart"
                        id={
                          isEmpty(cartList)
                            ? 'navBar_popUp_cart_dropdownMenu_none'
                            : 'navBar_popUp_cart_dropdownMenu'
                        }
                        className="navBar_popUp"
                      >
                        {isEmpty(cartList) ? (
                          <div className="d-flex flex-column align-items-center">
                            <h4>{t(...messages.noCartTitle())}</h4>
                            <p>{t(...messages.noCartContent())}</p>
                            <Dropdown.Item
                              href="/#cards"
                              scroll={el => hashScrollWithOffset(el, -60)}
                              onClick={e => handleChangePath('/#cards', e)}
                            >
                              {t(...messages.noCartCTA())}
                            </Dropdown.Item>
                            <hr />
                            <Dropdown.Item
                              className="btn-faq d-flex align-items-center justify-content-center"
                              href="/faq"
                            >
                              <img src={NavHelpIcon} alt="faq" />
                              {t(...messages.faqs())}
                            </Dropdown.Item>
                          </div>
                        ) : (
                          <div className="d-flex flex-column cart-list">
                            <main className="d-flex flex-column align-items-center">
                              {map(cartList, (order, index) => (
                                <section
                                  key={order.id}
                                  className="d-flex align-items-center"
                                >
                                  <div className="cart-detail">
                                    <span className="d-flex justify-content-center align-items-center">
                                      {index + 1}.
                                    </span>
                                    <img
                                      src={order.data.productImgUrl}
                                      alt={`cart-img-${order.id}`}
                                    />
                                    <div>
                                      <h6>
                                        {get(
                                          order,
                                          'data.productName',
                                          'no data',
                                        )}
                                      </h6>
                                      <h6>
                                        {`${t(...messages.to())} ${get(
                                          order,
                                          'data.receiverName',
                                          'no data',
                                        )}`}
                                      </h6>
                                      <h6>
                                        {`${t(
                                          ...messages.deliveryBy(),
                                        )} ${getDeliverText(
                                          order.data.deliverType,
                                        )}`}
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="cart-price">
                                    <h6>
                                      {amountFormate(
                                        get(order, 'data.amount', 0),
                                      )}
                                    </h6>
                                  </div>
                                </section>
                              ))}
                            </main>
                            <div className="d-flex justify-content-between align-items-center cart-total-price">
                              <h5>{t(...messages.cartTotalAmount())}</h5>
                              <h5>
                                {amountFormate(totalAmount(totalLetterAmount))}
                              </h5>
                            </div>
                            <hr />
                            <Dropdown.Item
                              className="dropdown-toggle d-flex justify-content-between align-items-center"
                              onClick={() => {
                                if (custType.isB2B) {
                                  history.push(
                                    `${custType.basePath}/buying-self/checkout`,
                                  );
                                } else {
                                  const nextStep = checkAuthBeforeCheckout();
                                  const checkoutUrl = '/buying-friend/checkout';
                                  if (nextStep === 'SSO') {
                                    loginWithOAuthAndRedirectUrl(checkoutUrl);
                                    return;
                                  }
                                  const nextPath =
                                    nextStep === 'checkout'
                                      ? checkoutUrl
                                      : '/auth/login?nextStep=checkout';
                                  history.push(nextPath);
                                }
                              }}
                            >
                              {t(...messages.cartProceedCheckout())}
                              <img src={NavPopUpCartIcon} alt="checkout" />
                            </Dropdown.Item>
                          </div>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  {isShowNavFaq && (
                    <li
                      id="navBar_FAQs"
                      className="nav-item d-flex flex-column align-items-center"
                      onMouseEnter={() => setFaqHover(true)}
                      onMouseLeave={() => setFaqHover(false)}
                      onClick={() => history.push('/faq')}
                    >
                      <div className="nav-link d-flex justify-content-center align-items-center">
                        <img
                          src={
                            faqHover || matchFaqPage
                              ? NavHelpHoverIcon
                              : NavHelpIcon
                          }
                          alt="faq"
                        />
                        <span
                          className={
                            faqHover || matchFaqPage
                              ? 'narBar-text-hover'
                              : 'narBar-text'
                          }
                        >
                          {t(...messages.faqs())}
                        </span>
                      </div>
                    </li>
                  )}
                  {user?.accessToken &&
                    member &&
                    custType.isB2B &&
                    !custType.isSalesman &&
                    isNeedWallet &&
                    isShowMyWallet && (
                      <li
                        id="navBar_wallet"
                        className="nav-item d-flex flex-column align-items-center"
                        onMouseEnter={() => setWalletHover(true)}
                        onMouseLeave={() => setWalletHover(false)}
                      >
                        <Dropdown id="navbar_popUp_wallet_container">
                          <Dropdown.Toggle
                            id="navBar_popUp_wallet"
                            className="nav-link btn btn-light dropdown-toggle"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <div onClick={() => handleOpenWallet()}>
                              <span className="d-flex justify-content-center align-items-center">
                                <img
                                  src={
                                    walletHover
                                      ? NavWalletHoverIcon
                                      : NavWalletIcon
                                  }
                                  alt="wallet"
                                />
                              </span>
                              <span
                                className={
                                  walletHover
                                    ? 'narBar-text-hover'
                                    : 'narBar-text'
                                }
                              >
                                {t(...messages.wallet())}
                              </span>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            as="ul"
                            // as="div"
                            id="navBar_popUp_wallet_dropdownMenu"
                            className="navBar_popUp dropdown-menu dropdown-menu-end"
                            aria-labelledby="navBar_popUp_wallet"
                            onClick={() => {
                              return;
                            }}
                          >
                            {isWalletFetching ? (
                              <LoadingProgress />
                            ) : (
                              <>
                                <section className="d-flex justify-content-between align-items-center">
                                  <img
                                    src={NavPopUpWalletIcon}
                                    alt="wallet-popup"
                                  />
                                  <div className="d-flex flex-column align-items-end">
                                    <h6>
                                      {t(...messages.walletAvailableCredit())}
                                    </h6>
                                    <h4>{amountFormate(walletBalance)}</h4>
                                  </div>
                                </section>
                                {memberWallet?.isReloadable && (
                                  <Dropdown.Item
                                    className="dropdown-toggle"
                                    onClick={() => {
                                      if (matchOrder || matchB2BCheckOut) {
                                        handleChangePath('/account/wallet');
                                      } else {
                                        history.push('/account/wallet');
                                      }
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn d-flex justify-content-between align-items-center"
                                    >
                                      {t(...messages.walletTopUp())}
                                      <img
                                        className="m-0"
                                        src={NavPopUpTopupIcon}
                                        alt="wallet-top-up"
                                      />
                                    </button>
                                  </Dropdown.Item>
                                )}
                                <hr />
                                <a
                                  className="d-flex align-items-center"
                                  href="/faq"
                                >
                                  <img src={NavHelpIcon} alt="faq" />
                                  {t(...messages.faqs())}
                                </a>
                              </>
                            )}
                            {/* <span className={walletHover ? 'narBar-text' : ''}>
                          {t(...messages.wallet())}
                        </span> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    )}
                  {user?.accessToken && member ? (
                    <li id="navBar_greeting" className="nav-item">
                      <Link
                        to="/account"
                        onClick={e => handleChangePath('/account', e)}
                      >
                        <span>
                          {t(...messages.helloName(), {
                            name:
                              member.memberType === MemberType.B2B
                                ? member.companyInfo.companyName
                                : `${get(member, 'firstName', '')}`,
                          })}
                        </span>
                      </Link>
                    </li>
                  ) : (
                    !isAccountFetching && (
                      <li id="navBar_login" className="nav-item d-flex">
                        <div className="dropdown">
                          <button
                            className="btn btn-light dropdown-toggle"
                            type="button"
                            id="navBar_popUp_login"
                            // data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                              const isB2B = location.pathname.includes('b2b');
                              const nextPath = isB2B
                                ? '/auth/b2b/login'
                                : '/auth/login';
                              handleBeforeGoToNextPath(nextPath);
                              // setShowLogin(preIsShowLogin => !preIsShowLogin)
                            }}
                          >
                            {t(...messages.login())}
                          </button>
                          <ul
                            id="navBar_popUp_login_dropdownMenu"
                            className={`navBar_popUp dropdown-menu ${
                              isShowLogin && 'show'
                            }`}
                            aria-labelledby="navBar_popUp_login"
                          >
                            <Link
                              to="/auth/login"
                              onClick={e => handleChangePath('/auth/login', e)}
                            >
                              <li className="dropdown-item">
                                {t(...messages.individualAccount())}
                              </li>
                            </Link>
                            {isNeedB2b && (
                              <Link
                                to="/auth/b2b/login"
                                onClick={e =>
                                  handleChangePath('/auth/b2b/login', e)
                                }
                              >
                                <li className="dropdown-item">
                                  {t(...messages.businessAccount())}
                                </li>
                              </Link>
                            )}
                            <Link
                              to="/query"
                              onClick={e => handleChangePath('/query', e)}
                            >
                              <li className="dropdown-item">
                                {t(...messages.queryBalance())}
                              </li>
                            </Link>
                          </ul>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              )}
              <div
                className={`a-n-nav-mobile d-flex ${
                  !isMobile && paymentBu !== 'UAE' && ' d-xl-none'
                }`}
              >
                {paymentBu === 'UAE' && !isMobile && (
                  <div className="MAF-nav-query">
                    <Link
                      to="/query"
                      onClick={e => handleChangePath('/query', e)}
                    >
                      {t(...messages.queryBalance())}
                    </Link>
                  </div>
                )}
                <a
                  className="btn btn-light"
                  role="button"
                  // data-bs-toggle="modal"
                  // data-bs-target="#cartModal"
                  onClick={() => {
                    const nextStep = checkAuthBeforeCheckout();
                    const checkoutUrl = (cartList[0]?.data.draftPath || '/buying-friend') + '/checkout';
                    if (nextStep === 'SSO') {
                      loginWithOAuthAndRedirectUrl(checkoutUrl);
                      return;
                    }
                    const nextPath =
                      nextStep === 'checkout'
                        ? checkoutUrl
                        : '/auth/login?nextStep=checkout';
                    if (nextStep === 'checkout') {
                      ReactGA.event('view_cart', {
                        currency,
                        value: totalAmount(totalLetterAmount),
                        items: cartList.map(item => ({
                          item_id: item.data.itemCode,
                          item_name: item.data.productName,
                          item_category: item.data.categoryId,
                          price: item.data.amount,
                          quantity: item.data.qty,
                        })),
                      });
                    }
                    history.push(nextPath);
                  }}
                >
                  <img src={NavCartMobileIcon} alt="cart" />
                  {!isEmpty(cartList) && cartList.length > 0 && (
                    <mark className="cart_mark_mobile">{cartList.length}</mark>
                  )}
                </a>
                {isMobile ? (
                  <a
                    className="btn btn-light"
                    role="button"
                    id="mobile-navigation-toggle" // for easier reference on DOM
                    onClick={() => setIsMobileMenu(prev => !prev)}
                  >
                    <img
                      src={!isMobileMenu ? MobileMenuIcon : MobileMenuCloseIcon}
                      alt="menu"
                    />
                  </a>
                ) : paymentBu !== 'UAE' ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      id="menu_modal"
                      className="btn btn-light"
                      aria-expanded="false"
                      as="a"
                    >
                      <img src={MobileMenuIcon} alt="menu" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      as="ul"
                      id="menu_modal_dropdownMenu"
                      className="_dropdown__menu"
                      aria-labelledby="menu_modal"
                    >
                      {user?.accessToken && member ? (
                        <p className="_dropdown__item _dropdown__text">
                          {t(...messages.hello())}, <br />
                          {member.memberType === MemberType.B2B
                            ? member.companyInfo.companyName
                            : `${get(member, 'firstName', '')} ${get(
                                member,
                                'lastName',
                                '',
                              )}`}
                        </p>
                      ) : (
                        <p className="_dropdown__item _dropdown__text">
                          {t(...messages.loginAs())}
                        </p>
                      )}
                      {user?.accessToken && member ? (
                        <Dropdown.Item
                          onClick={() => history.push('/account')}
                          className="menu-btn menu-btn-last"
                        >
                          {t(...messages.account())}
                        </Dropdown.Item>
                      ) : (
                        <>
                          <Dropdown.Item
                            className="menu-btn"
                            onClick={() => {
                              handleBeforeGoToNextPath('/auth/login');
                            }}
                          >
                            {t(...messages.individual())}
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="menu-btn menu-btn-last"
                            onClick={() => history.push('/auth/b2b/login')}
                          >
                            {t(...messages.business())}
                          </Dropdown.Item>
                        </>
                      )}
                      <hr className="_dropdown_hr" />
                      {!(
                        custType.isSalesman ||
                        location.pathname.includes('salesman')
                      ) && (
                        <Dropdown.Item
                          className="menu-btn-2"
                          onClick={() => history.push('/query')}
                        >
                          {t(...messages.queryCard())}
                        </Dropdown.Item>
                      )}
                      <hr className="_dropdown_hr" />
                      <Dropdown.Item
                        className="menu-btn-3"
                        href="https://www.google.com.tw/maps/"
                        target="blank"
                      >
                        <img src={NavMapMobileIcon} alt="location" />
                        {t(...messages.location())}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="menu-btn-3"
                        onClick={() => history.push('/faq')}
                      >
                        <img src={NavHelpMobileIcon} alt="help" />
                        {t(...messages.help())}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
                <div className="MAF-nav-faq">
                  <Link to="/faq" onClick={e => handleChangePath('/faq', e)}>
                    <img src={NavHelpMobileIcon} alt="help" />
                  </Link>
                </div>
                {/* {paymentBu === "UAE" && !isMobile && (
                  <>
                    <div className='MAF-nav-faq'>
                      <Link
                        to="/faq"
                        onClick={e => handleChangePath('/faq', e)}
                      >
                        <img src={NavHelpMobileIcon} alt="help" />
                      </Link>
                    </div>

                    <div id="navInfoBar_popUp_language" className="dropdown">
                    <button
                      type="button"
                      id="navInfo_language"
                      className="d-flex align-items-center justify-content-center btn"
                      data-bs-toggle="dropdown"
                    >
                      {currentLang && (
                        <>
                          <img
                            src={currentLang.flagSrc}
                            alt={`current-${currentLang.key}`}
                          />
                          <h5>{currentLang.title}</h5>
                        </>
                      )}
                    </button>
                    <ul
                      id="navInfoBar_popUp_language_dropdownMenu"
                      aria-labelledby="navInfo_language"
                      className="navBar_popUp dropdown-menu dropdown-menu-end"
                    >
                      {LanguageList.map(lang =>
                        lang.key !== i18n.language ? (
                          <li
                            key={lang.key}
                            className="d-flex align-items-center justify-content-center"
                            onClick={() => {
                              handleLocaleChange(lang.key);
                              saveLanguage(lang.key);
                            }}
                          >
                            <a
                              className="dropdown-item d-flex align-items-center justify-content-center"
                              href="#"
                            >
                              <img src={lang.flagSrc} alt={lang.key} />
                              <h5>{lang.title}</h5>
                            </a>
                          </li>
                        ) : null,
                      )}
                    </ul>
                    </div>
                  </>
                )} */}
              </div>
            </div>
          </nav>
        </div>
        {/* <!-- menu modal --> */}
        <div
          ref={modalRef}
          className="modal"
          id="menuModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              {user?.accessToken && member ? (
                <p className="menu-text">
                  {t(...messages.hello())}, <br />
                  {member.memberType === MemberType.B2B
                    ? member.companyInfo.companyName
                    : `${get(member, 'firstName', '')} ${get(
                        member,
                        'lastName',
                        '',
                      )}`}
                </p>
              ) : (
                <p className="menu-text">{t(...messages.loginAs())}</p>
              )}
              {/* {!(
                custType.isSalesman || location.pathname.includes('salesman')
              ) && <a href="/query">{t(...messages.queryCard())}</a>} */}
              {/* <a href="#">{t(...messages.dowloadApp())}</a> */}
              {/* <a href="/faq">{t(...messages.faqs())}</a> */}
              {user?.accessToken && member ? (
                <Link
                  to="/account"
                  className="menu-btn menu-btn-last"
                  onClick={() => modal.hide()}
                >
                  {t(...messages.account())}
                </Link>
              ) : (
                <>
                  <Link
                    to="/auth/login"
                    className="menu-btn"
                    onClick={() => modal.hide()}
                  >
                    {t(...messages.login())}
                  </Link>
                  <a
                    href="/auth/b2b/login"
                    className="menu-btn menu-btn-last"
                    onClick={() => modal.hide()}
                  >
                    {t(...messages.forBusiness())}
                  </a>
                </>
              )}
              <hr className="dropdown-divider" />
              {!(
                custType.isSalesman || location.pathname.includes('salesman')
              ) && (
                <a
                  href="/query"
                  className="menu-btn-2"
                  onClick={() => modal.hide()}
                >
                  {t(...messages.queryCard())}
                </a>
              )}
              <hr className="dropdown-divider" />
              <a
                href="https://www.google.com.tw/maps/"
                target="blank"
                onClick={() => modal.hide()}
              >
                <img src={NavMapMobileIcon} alt="location" />
                {t(...messages.location())}
              </a>
              <a
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#helpModal"
                data-bs-dismiss="modal"
              >
                <img src={NavHelpMobileIcon} alt="help" />
                {t(...messages.help())}
              </a>
            </div>
          </div>
        </div>

        {/* <!-- first cart modal --> */}
        <div
          className="modal"
          id="cartModal"
          aria-hidden="true"
          aria-labelledby="cartModalLabel"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content mx-auto">
              <div className="d-flex flex-column align-items-center">
                <h4>{t(...messages.noCartTitle())}</h4>
                <p>{t(...messages.noCartContent())}</p>
                <a href="mainPage.html">{t(...messages.noCartCTA())}</a>
                <hr />
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#helpModal"
                  data-bs-dismiss="modal"
                >
                  <img src={NavHelpMobileIcon} alt="help" />
                  {t(...messages.faqs())}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- second help modal --> */}
        <div
          className="modal"
          id="helpModal"
          aria-hidden="true"
          aria-labelledby="helpModalLabel"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content mx-auto">
              <div className="d-flex flex-column align-items-center">
                <h4>
                  <img src={NavHelpMobileIcon} alt="help" />
                  {t(...messages.help())}
                </h4>
                <p>
                  {t(...messages.helpSubtitle1())}
                  <br />
                  {t(...messages.helpSubtitle2())}{' '}
                  <Link to="/faq">{t(...messages.faqs())}</Link>{' '}
                  {t(...messages.helpSubtitle3())}
                </p>
                <hr />
                <a href="tel:+1234567890">
                  <h5>
                    {t(...messages.tel())}:<strong>XXXXXXXXXXXX</strong>
                  </h5>
                </a>
                <a href="mailto:XXX@ogloba.com">
                  <h5>
                    {t(...messages.email())}:<strong>XXX@ogloba.com</strong>
                  </h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Modal
        show={isShowWarn}
        onHide={() => setIsShowWarn(false)}
        animation={false}
        dialogClassName="warnModal"
        aria-labelledby="warnModalLabel"
        centered
      >
        <div className="d-flex flex-column align-items-center modal-container">
          <div className="modal-close" onClick={() => setIsShowWarn(false)}>
            <img src={modalCloseIcon} alt="close modal" />
          </div>
          <h3>{t(...messages.warnModalTitle())}</h3>
          <h5>{t(...messages.warnModalContentOne())}</h5>
          <h5>{t(...messages.warnModalContentTwo())}</h5>
          <button
            type="button"
            className="btn btn-light a-qc-block-yes-btn"
            onClick={() => {
              console.log('TEST');
              if (matchB2BCheckOut) {
                clearShoppingCart();
              }
              setIsShowWarn(false);
              console.log('path');
              dispatch(orderActions.paymentCancel());
              console.log(path);
              handleBeforeGoToNextPath(path);
              if (isWidgetFormOpen) {
                window.location.reload();
              }
            }}
          >
            {t(...messages.warnModalConfirm())}
          </button>
          <button
            type="button"
            className="btn btn-light a-qc-block-no-btn"
            onClick={() => setIsShowWarn(false)}
          >
            {t(...messages.warnModalCancel())}
          </button>
        </div>
      </Modal>

      {isMobileMenu && (
        <MobileNavigationMenu
          handleBeforeGoToNextPath={handleBeforeGoToNextPath}
          member={member}
          user={user}
          closeMenu={() => setIsMobileMenu(false)}
          updateLanguage={e => {
            if (currentLang) {
              // const index = findNextLanguageIndex();
              handleLocaleChange(e.key);
              saveLanguage(e.key);
            }
          }}
          currentLanguage={currentLang ?? LanguageList[0]}
          nextLanguage={LanguageList[findNextLanguageIndex()]}
          LanguageList={LanguageList}
        />
      )}
    </>
  );
}
