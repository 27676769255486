import { globalActions } from 'app/pages/GlobalLayer/slice';

export const popupMiddleware = () => next => action => {
  if (action?.payload?.popup) {
    next(globalActions.openPopup(action?.payload?.popup));
  }

  if (action?.payload?.notification) {
    next(globalActions.openNotification(action?.payload?.notification));
  }

  return next(action);
};
