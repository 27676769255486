import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    scroll-behavior: auto !important;
  }
  
  body {
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
  }

  #root {
    overflow: hidden;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }
  h1, h2, h3, h4, h5, h6{
    margin-bottom: 0 !important;
  }

  .grecaptcha-badge{
    display:none;
  }
`;
