/**
 *
 * GlobalLayer
 *
 */
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { useGlobalSlice } from 'app/pages/GlobalLayer/slice';
import {
  selectIsFetching,
  selectNotification,
  selectPopup,
} from 'app/pages/GlobalLayer/slice/selectors';

import CommonNotification from '../CommonNotification';
import CommonPopup from '../CommonPopup';
import { LoadingProgress } from '../LoadingProgress';

interface Props {}

export function GlobalLayer(props: Props) {
  const dispatch = useDispatch();
  const { actions: globalActions } = useGlobalSlice();
  const isFetching = useSelector(selectIsFetching);
  const { isOpen, content, handleOkClick, ...popupData } = useSelector(
    selectPopup,
  );
  const notificationData = useSelector(selectNotification);
  const handleClosePopup = () => {
    dispatch(globalActions.closePopup());
  };

  const handleCloseNotification = () => {
    dispatch(globalActions.closeNotification());
  };

  const handleBtnOK = () => {
    handleOkClick && handleOkClick();
    dispatch(globalActions.closePopup());
  };
  return (
    <>
      <CommonPopup
        showPopup={isOpen}
        {...popupData}
        handleBtnOK={handleBtnOK}
        handleClosePopup={handleClosePopup}
      >
        {content}
      </CommonPopup>
      <CommonNotification
        {...notificationData}
        handleOnClose={handleCloseNotification}
      />
      {isFetching && <LoadingProgress isFullScreen />}
    </>
  );
}
