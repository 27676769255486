import ReactGA from 'react-ga4';
import axios from 'axios';
import { jwtEncode, jwtDecode } from 'helpers/crypto';
import { VERSION, BASE_URL, PAYMENT_URL, locationUrl } from './apiUrl';
import { v4 } from 'uuid';
import { encryptedCallApi } from './apiHelper';
export interface ApiResponse {
  success: boolean;
  status: string;
  data: any;
}

const forceLogoutFunction = () => {
  // eslint-disable-next-line no-restricted-globals
  window.location.href = `/force-logout?failed=${location.href.includes(
    'failed',
  )}`;
};

export const fetchTimestamp = timestampBase64 => {
  return axios
    .get(`${BASE_URL}/${VERSION}/others/${timestampBase64}`)
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const uploadFile = ({
  b2bOrderFile,
  custId,
  fileExtension,
  fileName,
  fileType,
  itemCode,
  uploadFileList,
}) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/files/uploadFile`, {
      b2bOrderFile: b2bOrderFile || '',
      custId,
      itemCode,
      fileExtension,
      fileType,
      fileName,
      uploadFileList,
    })
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const getProductsDiscount = ({ custId, itemCode, accessToken }) => {
  if (custId !== '') {
    return axios
      .post(
        `${BASE_URL}/${VERSION}/member/getProductsDiscount`,
        {
          token: jwtEncode({
            custId,
            itemCode,
            web: {
              browser: '',
              device: '',
              os: '',
            },
          }),
        },
        {
          auth: {
            username: accessToken,
            password: '',
          },
        },
      )
      .then(resp => {
        if (resp.status !== 200) {
          return {
            success: false,
            status: resp.status,
            data: resp,
          };
        }

        return {
          success: true,
          status: resp.status,
          data: jwtDecode(resp.data.token).object,
        };
      })
      .catch(error => {
        console.warn(error);
        if (error.response) {
          if (error.response.status === 401) {
            forceLogoutFunction();
          }
        }
        return {
          success: false,
          status: null,
          data: error,
        };
      });
  }
};

export const fetchSampleFile = ({ fileType, extension, language = 'en' }) => {
  return axios
    .post(
      `${BASE_URL}/${VERSION}/files/sampleFile`,
      {
        fileType,
        extension,
      },
      {
        headers: {
          'accept-language': language,
        },
        responseType: 'blob',
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchFileInvoice = ({ orderNo }) => {
  return axios
    .post(
      `${BASE_URL}/${VERSION}/files/invoice`,
      {
        orderNo,
      },
      {
        responseType: 'blob',
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const getMemberWallet = ({ custId, accessToken }) => {
  return axios
    .post(
      `${BASE_URL}/${VERSION}/member/getMemberWallet`,
      {
        token: jwtEncode({
          custId,
          web: {
            browser: '',
            device: '',
            os: '',
          },
        }),
      },
      {
        auth: {
          username: accessToken,
          password: '',
        },
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      if (error.response) {
        if (error.response.status === 401) {
          forceLogoutFunction();
        }
      }
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const getMemberProducts = ({
  custId,
  accessToken,
  productName,
  categoryId,
}) => {
  return axios
    .post(
      `${BASE_URL}/${VERSION}/member/getMemberProducts`,
      {
        token: jwtEncode({
          custId,
          productName,
          categoryId,
          web: {
            browser: '',
            device: '',
            os: '',
          },
        }),
      },
      {
        auth: {
          username: accessToken,
          password: '',
        },
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      if (error.response) {
        if (error.response.status === 401) {
          forceLogoutFunction();
        }
      }
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const getMemberGiftCardsWithProduct = ({
  custId,
  accessToken,
  itemCode,
}) => {
  return axios
    .post(
      `${BASE_URL}/${VERSION}/member/getMemberGiftCardsWithProduct`,
      {
        token: jwtEncode({
          custId,
          itemCode,
          web: {
            browser: '',
            device: '',
            os: '',
          },
        }),
      },
      {
        auth: {
          username: accessToken,
          password: '',
        },
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      if (error.response) {
        if (error.response.status === 401) {
          forceLogoutFunction();
        }
      }
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const getMemberHistory = ({
  custId,
  accessToken,
  historyType,
  page,
  cardNumber,
  dateBegin,
  dateEnd,
  status,
  productName,
}) => {
  return axios
    .post(
      `${BASE_URL}/${VERSION}/member/getMemberHistory`,
      {
        token: jwtEncode({
          custId,
          historyType,
          page,
          cardNumber,
          dateBegin,
          dateEnd,
          status,
          productName,
          web: {
            browser: '',
            device: '',
            os: '',
          },
        }),
      },
      {
        auth: {
          username: accessToken,
          password: '',
        },
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      if (error.response) {
        if (error.response.status === 401) {
          forceLogoutFunction();
        }
      }
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const getMemberInbox = ({ custId, accessToken }) => {
  return axios
    .post(
      `${BASE_URL}/${VERSION}/member/getMemberInbox`,
      {
        token: jwtEncode({
          custId,
        }),
      },
      {
        auth: {
          username: accessToken,
          password: '',
        },
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      if (error.response) {
        if (error.response.status === 401) {
          forceLogoutFunction();
        }
      }
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const getMemberPreviousFile = ({ custId, accessToken }) => {
  return axios
    .post(
      `${BASE_URL}/${VERSION}/member/getMemberPreviousFile`,
      {
        token: jwtEncode({
          custId,
        }),
      },
      {
        auth: {
          username: accessToken,
          password: '',
        },
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      if (error.response) {
        if (error.response.status === 401) {
          forceLogoutFunction();
        }
      }
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const updateMemberProfile = ({
  accessToken,
  memberUpdate,
  memberType,
  password,
}) => {
  return axios
    .post(
      `${BASE_URL}/${VERSION}/member/updateMemberProfile`,
      {
        token: jwtEncode({
          memberUpdate,
          memberType,
          password,
          web: {
            browser: '',
            device: '',
            os: '',
          },
        }),
      },
      {
        auth: {
          username: accessToken,
          password: '',
        },
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      if (error.response) {
        if (error.response.status === 401) {
          forceLogoutFunction();
        }
      }
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchMemberProfile = ({ custId, accessToken }) => {
  return axios
    .post(
      `${BASE_URL}/${VERSION}/member/getMemberProfile`,
      {
        token: jwtEncode({
          custId,
          web: {
            browser: '',
            device: '',
            os: '',
          },
        }),
      },
      {
        auth: {
          username: accessToken,
          password: '',
        },
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      if (error.response) {
        if (error.response.status === 401) {
          forceLogoutFunction();
        }
      }
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const registerConfirm = ({
  aggregatorBuId,
  accountId,
  registerType,
  loginIp,
  verifyToken,
}) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/register/confirmation`, {
      token: jwtEncode({
        aggregatorBuId,
        accountId,
        registerType,
        loginIp,
        verifyToken,
      }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const register = ({
  aggregatorBuId,
  accessToken,
  email,
  firstName,
  lastName,
  password,
  registerType,
  documentType,
  idCardNo,
  mobileNo = '',
}) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/register`, {
      token: jwtEncode({
        aggregatorBuId,
        accessToken,
        email,
        firstName,
        lastName,
        password,
        registerType,
        documentType,
        idCardNo,
        mobileNo,
      }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      ReactGA.event('sign_up', { method: registerType });

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchOAuth = locationUrl => {
  return axios
    .post(`${BASE_URL}/oAuth/getOauthLoginInfo`, { redirectUri: locationUrl })
    .then(response => {
      if (response.status !== 200) {
        return {
          success: false,
          status: response.status,
          data: response,
        };
      } else {
        return {
          success: true,
          status: response.status,
          data: jwtDecode(response.data.token).object,
        };
      }
    });
};

export const fetchLetterType = () => {
  return axios.get(`${BASE_URL}/${VERSION}/product/letterType`).then(resp => {
    if (resp.status !== 200) {
      return {
        success: false,
        status: resp.status,
        data: resp,
      };
    }

    return {
      success: true,
      status: resp.status,
      data: resp.data,
    };
  });
};

export const fetchAdditionalProduct = aggregatorBuId => {
  return axios
    .get(`${BASE_URL}/${VERSION}/product/additionalProduct`, {
      params: {
        aggregatorBuId,
      },
    })
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const queryTransaction = (cardNumber, pageNo) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/transaction`, {
      cardNumber,
      pageNo,
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp.data,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const updateCard = (cardNumber, action, captchaToken?) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/card/updateCard`, {
      cardNumber,
      action,
      captchaToken,
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp.data,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const queryCard = (cardNumber, pinCode, captchaToken) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/card/queryCard`, {
      cardNumber,
      pinCode,
      captchaToken,
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp.data,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const mediaUpload = source => {
  return axios
    .post(`${BASE_URL}/${VERSION}/resource/upload`, {
      resourceContent: source.replace(/^data:image\/[a-z]+;base64,/, ''),
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp.data,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const orderUnlock = async orderInfo => {
  return axios
    .post(`${BASE_URL}/${VERSION}/order/unlock`, {
      token: jwtEncode({ ...orderInfo }),
    })
    .then(resp => {
      console.log(jwtDecode(resp.data.token).object);
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp.data,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const orderCancel = orderInfo => {
  return axios
    .post(`${BASE_URL}/${VERSION}/order/cancel`, {
      token: jwtEncode({ ...orderInfo }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp.data,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const orderConfirm = (orderInfo, aggregatorBuId) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/order/confirm`, {
      token: jwtEncode({ ...orderInfo, aggregatorBuId }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp.data,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const orderCreate = ({
  aggregatorBuId,
  captchaToken,
  custId = '',
  documentType,
  idCardNo,
  order,
  orderImageUrl = '',
  orderAmount = 0,
  freeItem = null,
  freeItemQty = null,
  payment = [],
  rewardType = null,
  tempId = null,
  salesmanId = '',
}: any) => {
  return encryptedCallApi({
    url: `${BASE_URL}/${VERSION}/order/creation`,
    payload: {
      aggregatorBuId,
      captchaToken,
      custId,
      documentType,
      idCardNo,
      order,
      orderImageUrl,
      orderAmount,
      freeItem,
      freeItemQty,
      payment,
      rewardType,
      tempId,
      salesmanId,
    },
  });
};

export const orderQuotate = ({
  aggregatorBuId,
  custId = '',
  email,
  order,
  orderAmount = 0,
  freeItem = null,
  freeItemQty = null,
  rewardType = null,
  tempId = null,
  salesmanId = '',
}: any) => {
  return encryptedCallApi({
    url: `${BASE_URL}/${VERSION}/order/quotation`,
    payload: {
      aggregatorBuId,
      custId,
      email,
      order,
      orderAmount,
      freeItem,
      freeItemQty,
      rewardType,
      tempId,
      salesmanId,
    },
  });
};

export const authLogin = ({
  aggregatorBuId,
  account,
  password,
  accessToken,
  mobileNo = '',
  loginType,
  redirectUrl = '',
}) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/login`, {
      token: jwtEncode({
        aggregatorBuId,
        account,
        password,
        accessToken,
        mobileNo,
        loginType,
        web: {
          browser: '',
          device: '',
          os: '',
        },
        redirectUrl,
      }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      ReactGA.event('login', { method: loginType });

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const authLogout = ({ refreshToken, accessToken }) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/logout`, {
      token: jwtEncode({
        refreshToken,
        redirectUrl: locationUrl,
      }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }
      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const forgotPassword = ({
  email,
  aggregatorBuId,
}: {
  email: string;
  aggregatorBuId: string;
}) => {
  const path = window.location.href;
  let accountType = 'C';
  if (path.includes('b2b')) {
    accountType = 'B';
  } else if (path.includes('salesman')) {
    accountType = 'S';
  }
  return axios.post(`${BASE_URL}/${VERSION}/member/resetPassword`, {
    token: jwtEncode({ email, accountType, aggregatorBuId }),
  });
};

export const verifyResetTokenApi = ({ tokenId }: { tokenId: string }) => {
  console.log(tokenId);
  return axios.post(`${BASE_URL}/${VERSION}/member/confirmToken`, {
    token: jwtEncode({ tokenId }),
  });
};

export const confirmReset = ({
  aggregatorBuId,
  password,
  accountId,
  tokenId,
  memberType,
}: {
  aggregatorBuId: string;
  password: string;
  accountId: string;
  tokenId: string;
  memberType: string;
}) => {
  return axios.post(`${BASE_URL}/${VERSION}/member/confirmReset`, {
    token: jwtEncode({
      aggregatorBuId,
      newPassword: password,
      accountId,
      token: tokenId,
      memberType,
    }),
  });
};

export const authB2BLogin = ({
  aggregatorBuId,
  account,
  password,
  accessToken,
  loginType,
}) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/b2bLogin`, {
      token: jwtEncode({
        aggregatorBuId,
        account,
        password,
        accessToken,
        loginType,
        web: {
          browser: '',
          device: '',
          os: '',
        },
      }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp.data,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const salesmanLogin = ({
  aggregatorBuId,
  account,
  password,
  accessToken,
  loginType,
}) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/salesmanLogin`, {
      token: jwtEncode({
        aggregatorBuId,
        account,
        password,
        accessToken,
        loginType,
        web: {
          browser: '',
          device: '',
          os: '',
        },
      }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }
      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchTemplates = (categoryId, itemCode?) => {
  return axios
    .get(`${BASE_URL}/${VERSION}/template/templates`, {
      params: {
        categoryId,
        itemCode,
      },
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp.data,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchTemplateCategory = (aggregatorBuId, itemCode?) => {
  return axios
    .get(`${BASE_URL}/${VERSION}/template/categories`, {
      params: {
        aggregatorBuId,
        itemCode,
      },
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp.data,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchCategory = (aggregatorBuId: string, custId: string) => {
  return axios
    .get(`${BASE_URL}/${VERSION}/product/category`, {
      params: {
        aggregatorBuId,
        custId,
      },
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchProduct = (categoryId, page, params) => {
  return axios
    .get(`${BASE_URL}/${VERSION}/product/products/${categoryId}/${page}`, {
      params,
    })
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchProductDetail = (itemCode, aggregatorBuId, custId = '') => {
  return axios
    .get(`${BASE_URL}/${VERSION}/product/detail/${itemCode}`, {
      params: {
        aggregatorBuId,
        custId,
      },
    })
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchCountryList = () => {
  return axios
    .get(`${BASE_URL}/${VERSION}/others/getCountryCode`)
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchBuParameter = (buId: string) => {
  return axios
    .get(`${BASE_URL}/${VERSION}/others/getBuParameter/${buId}`)
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const getCustomerList = (salesmanId: string) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/others/getCustomerList`, {
      salesmanId: salesmanId,
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchInit = () => {
  return axios
    .get(`${BASE_URL}/${VERSION}/others/init`)
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchTransactionTopUp = (
  paymentInfo,
  accessToken,
  aggregatorBuId,
) => {
  return axios
    .post(
      `${BASE_URL}/${VERSION}/transaction/topup`,
      {
        token: jwtEncode({ ...paymentInfo, aggregatorBuId }),
      },
      {
        auth: {
          username: accessToken,
          password: '',
        },
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      if (error.response) {
        if (error.response.status === 401) {
          forceLogoutFunction();
        }
      }
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchCardTransfer = transferInfo => {
  return axios
    .post(`${BASE_URL}/${VERSION}/card/cardTransfer`, {
      token: jwtEncode({ ...transferInfo }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchCardPrint = (cardNumber, captchaToken) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/card/cardPrint`, { cardNumber, captchaToken })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchCardResend = (cardNumber, captchaToken) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/card/cardResend`, {
      cardNumber,
      captchaToken,
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      if (resp.data.error !== null) {
        return {
          success: false,
          status: resp.status,
          data: resp.data,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchImportCard = (
  accessToken,
  email,
  memberBu,
  mobileNo,
  token,
  pinCode,
  importType,
  captchaToken,
) => {
  return axios
    .post(
      `${BASE_URL}/${VERSION}/card/importCard`,
      {
        email,
        memberBu,
        mobileNo,
        token,
        pinCode,
        importType,
        captchaToken,
      },
      {
        auth: {
          username: accessToken,
          password: '',
        },
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }
      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      if (error.response) {
        if (error.response.status === 401) {
          forceLogoutFunction();
        }
      }
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchCardValidation = (
  cardNumber: string,
  captchaToken: string,
) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/card/cardValidation`, {
      token: jwtEncode({ cardNumber, captchaToken }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        return {
          success: false,
          status: resp.status,
          data: resp.data,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchApplyCard = cardInfo => {
  return axios
    .post(`${BASE_URL}/${VERSION}/card/applyCard`, cardInfo)
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchQueryCompany = searchInfo => {
  return axios
    .post(`${BASE_URL}/${VERSION}/others/queryCompany`, searchInfo)
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchSNameFetcher = ({ mID }) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/others/sNameFetcher`, {
      mID,
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchB2bRegister = b2bRegisterInfo => {
  return axios
    .post(`${BASE_URL}/${VERSION}/register/b2bRegister`, {
      token: jwtEncode({ ...b2bRegisterInfo }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchB2bRegisterAccount = b2bRegisterInfo => {
  return axios
    .post(`${BASE_URL}/${VERSION}/register/b2bRegisterAccount`, {
      token: jwtEncode({ ...b2bRegisterInfo }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchB2bRegisterConfirmToken = b2bRegisterInfo => {
  return axios
    .post(`${BASE_URL}/${VERSION}/register/b2bConfirmToken`, {
      token: jwtEncode({ ...b2bRegisterInfo }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }
      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchB2bConfirmRegister = b2bRegisterInfo => {
  return axios
    .post(`${BASE_URL}/${VERSION}/register/b2bConfirmRegister`, {
      token: jwtEncode({ ...b2bRegisterInfo }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchPspInitToken = () => {
  return axios
    .get(`${PAYMENT_URL}/initToken`)
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchPspInit = paymentData => {
  return axios
    .post(`${PAYMENT_URL}/init`, paymentData)
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchSendOtp = otpData => {
  return axios
    .post(`${BASE_URL}/${VERSION}/user/sendOTP`, otpData)
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchConfirmOtp = otpData => {
  return axios
    .post(`${BASE_URL}/${VERSION}/user/confirmOTP`, otpData)
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchQueryAddressInfo = zipCode => {
  return axios
    .post(`${BASE_URL}/${VERSION}/others/queryAddressInfo`, {
      zipCode,
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchToken = (amount, paymentName, buId) => {
  return axios
    .post(
      `${PAYMENT_URL}/tokenized`,
      {
        sessionId: v4(),
        amount: amount,
        // batchNo: 'Baxbe1zyKA8q3MXoJ8+ikA==',
        paymentName,
        buId,
      },
      { withCredentials: true },
    )
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchPspCreate = (csrfToken, callBackUrl, pspId, locale?) => {
  return axios
    .post(
      `${PAYMENT_URL}/createPayment`,
      {
        csrfToken,
        callBackUrl,
        pspId,
        locale,
        // txnAmount: 100,
        // buId: 'MAPAN',
        // email: 'jey@ogloba.com',
        txnCurrency: 'EUR',
        // batchNo: 'Baxbe1zyKA8q3MXoJ8+ikA==',
      },
      { withCredentials: true },
    )
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchPspInitV2 = (url, orderDetails) => {
  return axios
    .post(url, orderDetails, {
      withCredentials: true,
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchPspConfirm = (csrfToken, pspId, idTokenize, callBackUrl) => {
  return axios
    .post(
      `${PAYMENT_URL}/confirmPayment`,
      {
        // hostName: '',
        csrfToken,
        pspId,
        callBackUrl,
        idTokenize,
        // txnAmount: 100,
        // buId: 'MAPAN',
        // email: 'jey@ogloba.com',
        // txnCurrency: 'EUR',
        // batchNo: 'Baxbe1zyKA8q3MXoJ8+ikA==',
      },
      { withCredentials: true },
    )
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchPspConfirmV2 = (url, orderDetails) => {
  return axios
    .post(url, orderDetails, {
      withCredentials: true,
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchPspIngenico = (paymentLogId, buId) => {
  return axios
    .get(
      `${PAYMENT_URL}/ingenico/3ds/confirmation?orderNo=${paymentLogId}&buId=${buId}`,
      {
        withCredentials: true,
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }
      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchPspMarketPayCapture = (paymentLogId, buId) => {
  return axios
    .get(
      `${PAYMENT_URL}/marketPayCapture?paymentLogId=${paymentLogId}&buId=${buId}`,
      {
        withCredentials: true,
      },
    )
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }
      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchUnlockOrder = orderInfo => {
  return axios
    .post(`${BASE_URL}/${VERSION}/order/unlock`, {
      token: jwtEncode({ ...orderInfo }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }
      return {
        success: true,
        status: resp.status,
        data: jwtDecode(resp.data.token).object,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const fetchB2BResendToken = (accountId, buId) => {
  return axios
    .post(`${BASE_URL}/${VERSION}/register/b2bResendToken`, {
      token: jwtEncode({
        buId,
        accountId,
      }),
    })
    .then(resp => {
      if (resp.status !== 200) {
        console.warn(resp);
        return {
          success: false,
          status: resp.status,
          data: resp,
        };
      }

      return {
        success: true,
        status: resp.status,
        data: resp.data,
      };
    })
    .catch(error => {
      console.warn(error);
      return {
        success: false,
        status: null,
        data: error,
      };
    });
};

export const authCheck = accessToken => {
  return axios
    .get(`${BASE_URL}/${VERSION}/others/validateToken`, {
      auth: {
        username: accessToken,
        password: '',
      },
    })
    .then(resp => {
      console.log('auth OK!');
    })
    .catch(error => {
      console.warn(error);
      if (error.response) {
        if (error.response.status === 401) {
          forceLogoutFunction();
        }
      }
    });
};

const API = {
  fetchCategory,
  fetchProduct,
  fetchProductDetail,
};

export default API;
