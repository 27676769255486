import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { unlockSaga } from './saga';
import { UnlockState } from './types';

export const initialState: UnlockState = {
  isFetching: true,
  cardQty: 0,
  orderNo: '',
  error: {
    code: 0,
    message: '',
  },
};

const slice = createSlice({
  name: 'unlock',
  initialState,
  reducers: {
    fetchUnlockOrder(
      state,
      action: PayloadAction<{ orderNo: string; captchaToken: string }>,
    ) {
      state.isFetching = true;
    },
    fetchUnlockOrderSuccess(state, action) {
      const { error, cardQty, orderNo } = action.payload;
      state.error = { ...error };
      state.cardQty = cardQty || 0;
      state.orderNo = orderNo || 0;
      state.isFetching = false;
    },
    fetchUnlockOrderFailed(state, action) {
      state.isFetching = false;
    },
  },
});

export const { actions: unlockActions } = slice;

export const useUnlockSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: unlockSaga });
  return { actions: slice.actions };
};
