import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { orderSaga } from './saga';
import { OrderState } from './types';

export const initialState: OrderState = {
  isFetching: false,
  orderNo: '',
  isConfirm: null,
  isCanceled: false,
  orderAmtTot: 0,
  isPayProcessing: false,
  isUnlock: null,
  unlockOrders: [],
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    orderCreate(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    orderCreateSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.orderNo = action.payload;
    },
    orderCreateFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    orderConfirm(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    orderConfirmSuccess(
      state,
      action: PayloadAction<{
        orderAmtTot: number;
        orderNo: string;
      }>,
    ) {
      const { orderAmtTot, orderNo } = action.payload;
      state.isFetching = false;
      state.isConfirm = true;
      state.isCanceled = false;
      state.orderAmtTot = orderAmtTot;
      state.orderNo = orderNo;
    },
    orderConfirmFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.isConfirm = false;
    },
    orderCancel(
      state,
      action: PayloadAction<{ orderNo: string; captchaToken: string }>,
    ) {
      state.isFetching = true;
    },
    orderCancelSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.orderNo = '';
      state.isConfirm = false;
      state.isCanceled = true;
    },
    orderCancelFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    orderUnlock(
      state,
      action: PayloadAction<{ orderNo: string; captchaToken: string }>,
    ) {
      state.isFetching = true;
    },
    orderUnlockSuccess(state, action: PayloadAction<any>) {
      const { orderNo } = action.payload;
      state.isFetching = false;
      state.unlockOrders = [...state.unlockOrders, orderNo];
      state.isUnlock = true;
    },
    orderUnlockFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.isUnlock = false;
    },
    clearUnlockState(state) {
      state.isFetching = false;
      state.isUnlock = null;
    },
    orderQuotate(state, action: PayloadAction<any>) {
      state.isFetching = true;
    },
    orderQuotateSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    orderQuotateFailure(state, action: PayloadAction<any>) {
      state.isFetching = false;
    },
    checkout(state, action: PayloadAction<any>) {},
    checkouSuccess(state, action: PayloadAction<any>) {},
    checkoutFailure(state, action: PayloadAction<any>) {},
    clearState(state) {
      state.isFetching = false;
      state.isCanceled = false;
      state.isConfirm = null;
    },
    clearOrderNo(state) {
      state.orderNo = '';
    },
    updateOrderInfo(
      state,
      action: PayloadAction<{ orderAmount: number; orderNo: string }>,
    ) {
      const { orderAmount, orderNo } = action.payload;
      state.orderAmtTot = orderAmount;
      state.orderNo = orderNo;
    },
    paymentProcessing(state) {
      state.isPayProcessing = true;
    },
    paymentCancel(state) {
      state.isPayProcessing = false;
    },
  },
});

export const { actions: orderActions } = slice;

export const useOrderSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: orderSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useOrderSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
