import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.mainPage || initialState;

export const selectMainPage = createSelector([selectSlice], state => state);

export const selectCategory = createSelector(
  [selectSlice],
  state => state.category,
);

export const selectProduct = createSelector(
  [selectSlice],
  state => state.products,
);

export const selectIsWaitings = createSelector([selectSlice], state => ({
  isWaitingCategory: state.isWaitingCategory,
  isWaitingProducts: state.isWaitingProducts,
  isWaitingProductDetail: state.isWaitingProductDetail,
}));

export const selectTotalCount = createSelector(
  [selectSlice],
  state => state.totalCount,
);

export const selectPage = createSelector([selectSlice], state => state.page);

export const selectProductDetail = createSelector(
  [selectSlice],
  state => state.productDetail,
);

export const selectIsFetching = createSelector(
  [selectSlice],
  state => state.isFetching,
);

export const selectTemplateCategory = createSelector(
  [selectSlice],
  state => state.templateCategory,
);

export const selectTemplates = createSelector(
  [selectSlice],
  state => state.templates,
);

export const selectLetteryTypeList = createSelector(
  [selectSlice],
  state => state.letterTypeList,
);

export const selectCountryList = createSelector(
  [selectSlice],
  state => state.countryList,
);

export const selectAdditionalProducts = createSelector(
  [selectSlice],
  state => state.additionalProducts,
);

export const selectCurrency = createSelector(
  [selectSlice],
  state => state.currency,
);

export const selectMemberShip = createSelector(
  [selectSlice],
  state => state.memberShip,
);

export const selectDiscountType = createSelector(
  [selectSlice],
  state => state.discountType,
);

export const selectPaymentType = createSelector(
  [selectSlice],
  state => state.paymentType,
);

export const selectCardMadeTaxRate = createSelector(
  [selectSlice],
  state => state.cardMadeTaxRate,
);

export const selectShippingTaxRate = createSelector(
  [selectSlice],
  state => state.shippingTaxRate,
);

export const selectIsAllowMultipleProduct = createSelector(
  [selectSlice],
  state => state.isAllowMultipleProduct,
);

export const selectIsEnableGuestMode = createSelector(
  [selectSlice],
  state => state.isEnableGuestMode,
);

export const selectScodeList = createSelector(
  [selectSlice],
  state => state.scodeList,
);

export const selectB2BMaxQty = createSelector(
  [selectSlice],
  state => state.b2bMaxQty,
);

export const selectB2CMaxQty = createSelector(
  [selectSlice],
  state => state.b2cMaxQty,
);

export const selectDecimalDigits = createSelector(
  [selectSlice],
  state => state.decimalDigits,
);

export const selectPspProvider = createSelector(
  [selectSlice],
  state => state.pspProvider,
);

export const selectError = createSelector([selectSlice], state => state.error);
