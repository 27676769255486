/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, {
  useEffect,
  useCallback,
  useState,
  useLayoutEffect,
} from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

import { HomePage, MobileHomePage } from './pages/HomePage/Loadable';
import { ForceLogoutPage } from 'app/pages/ForceLogoutPage';
import { B2BPage } from 'app/pages/B2BPage';
import { B2BBuyingPage } from 'app/pages/B2BBuyingPage/Loadable';
import { B2BBuyingSelfPage } from 'app/pages/B2BBuyingSelfPage/Loadable';
import { SalesmanBuyingPage } from 'app/pages/SalesmanBuyingPage/Loadable';
import { SalesmanBuyingSelfPage } from 'app/pages/SalesmanBuyingSelfPage/Loadable';
import { Navigation } from 'app/components/Navigation';
import { Footer } from 'app/components/Footer';
import { AuthPage, MobileAuthPage } from 'app/pages/AuthPage/Loadable';
import { GuestPage, MobileGuestPage } from 'app/pages/GuestPage/Loadable';
import { Order, MobileOrder } from 'app/pages/Order/Loadable';
import { FaqCard, MobileFaqCard } from 'app/components/FaqCard/Loadable';
import { PspLoading } from 'app/components/PspLoading';
import { SelfBuyingPage } from 'app/pages/SelfBuyingPage/Loadable';
import { QueryPage, MobileQueryPage } from 'app/pages/QueryPage/Loadable';
import { AccountPage, MobileAccountPage } from 'app/pages/AccountPage/Loadable';
import { Banner } from 'app/components/Banner';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { ScrollToTop } from 'app/components/ScrollToTop';
import { useAuthSlice } from 'app/pages/AuthPage/slice';
import { selectAuthUser } from 'app/pages/AuthPage/slice/selectors';
import { LoginType } from 'app/pages/AuthPage/slice/types';
import { useAccountSlice } from 'app/pages/AccountPage/slice';
import { useMainPageSlice } from 'app/pages/HomePage/slice';
import {
  selectLetteryTypeList,
  selectMainPage,
} from 'app/pages/HomePage/slice/selectors';
import { useShoppingCartSlice } from 'app/pages/ShoppingCart/slice';
import {
  selectShoppingCartItems,
  selectedCouponList,
} from 'app/pages/ShoppingCart/slice/selectors';
import {
  selectMember,
  selectWallet,
  selectIsFetching,
} from 'app/pages/AccountPage/slice/selectors';
import { selectCurrency } from 'app/pages/HomePage/slice/selectors';
import {
  useCookie,
  useParameter,
  useCountDownWithDate,
  useLocalStorage,
  useSessionStorage,
  getBuIdFromUrl,
} from 'helpers';
import { fetchInit } from 'app/APIs';
import { messages } from './messages';
import {
  locationUrl,
  pspConfig,
  redirectUrlIfNotLogin,
  paymentBu,
} from 'app/APIs/apiUrl';
import {
  Switch,
  Route,
  BrowserRouter,
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom';
import { TemplateKind, PopupMode, PopupImgType } from 'types/common';
import { GlobalStyle } from 'styles/global-styles';
import 'styles/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { decryptionDES, setJwtKey } from 'helpers/crypto';
import { GlobalLayer } from 'app/components/GlobalLayer';

import FooterPagesGeneralBg from './components/FooterPagesGeneralBg';
import Unlock from './pages/Unlock';
import useWindowSize from 'utils/hooks/useWindowSize';
import { version } from '../config/config';
import CommonPopup from './components/CommonPopup';
import { LoadingProgress } from './components/LoadingProgress';
import { getBuConfig } from 'helpers/BuHelper';
import { buList } from 'helpers/buList';

// hidden continue button if android virtual keyboard be called
const wHeight =
  document.documentElement.clientHeight || document.body.clientHeight;
window.addEventListener('resize', () => {
  const nowHeight =
    document.documentElement.clientHeight || document.body.clientHeight;
  const test = document.getElementsByClassName('a-bf-mobile-progress-btn')[0];
  if (!test) return;
  if (wHeight > nowHeight) {
    test.setAttribute('style', 'display: none;');
  } else {
    test.removeAttribute('style');
  }
});

const Setting = {
  color: {
    theme: '#d6180b',
  },
};

function B2BRoute({ children, ...rest }) {
  const { custType } = useParameter();
  let user = useSelector(selectAuthUser);
  const buId = getBuIdFromUrl(true);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user && custType.isB2C ? (
          <Redirect
            to={{
              pathname: '/' + buId || '',
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

function PrivateRoute({ children, ...rest }) {
  let user = useSelector(selectAuthUser);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user && user.custId ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export function App() {
  const TIME_LIMIT = 60 * 60;
  const buId = getBuIdFromUrl(true);
  const { t, i18n } = useTranslation();
  useAuthSlice();
  const { actions: accountActions } = useAccountSlice();
  const { actions: authActions } = useAuthSlice();
  const { actions: mainActions } = useMainPageSlice();
  const { actions: cartActions } = useShoppingCartSlice();

  const { isFetchingBuParam } = useSelector(selectMainPage);
  const {
    isShowFooterBanner,
    isNeedFreshDesk,
    ZENDESK_SETTING_COLOR,
    isNeedQuery,
    moreInfoList,
    defaultLang,
    browserTitle,
    getAsset,
    Footer: FooterBu,
  } = getBuConfig();
  const { AccountUpdateProfileErrorIcon } = getAsset();
  const dispatch = useDispatch();
  const {
    clearLocalStorage,
    removeItemLs,
    getItemLs,
    setItemLs,
    migrateOldFormatLs,
  } = useLocalStorage();

  const { migrateOldFormatSs } = useSessionStorage();
  const { custType, pspProvider } = useParameter();
  const shoppingCartList = useSelector(selectShoppingCartItems);
  const authUser = useSelector(selectAuthUser);
  const memberWallet = useSelector(selectWallet);
  const member = useSelector(selectMember);
  const letterTypeList = useSelector(selectLetteryTypeList);
  const isAccountFetching = useSelector(selectIsFetching);
  const currency = useSelector(selectCurrency);
  const couponList = useSelector(selectedCouponList);
  const { getCookie, setCookie, migrateOldFormatCookie } = useCookie();
  const {
    countDown,
    isStart,
    setCountDownWithDate,
    toggleIsStart,
  } = useCountDownWithDate(TIME_LIMIT);
  const initToken = getCookie('initToken', false) || '';
  const initTokenLimit = getItemLs('initTokeLimit') || '0';
  const [isFetchToken, setIsFetchToken] = useState<boolean>(false);
  const [isShowWarn, setIsShowWarn] = useState<boolean>(false);
  const [saveInitToken, setSaveInitToken] = useState<string>('');
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isShowTimeout, setIsShowTimeout] = useState<boolean>(false);
  let idleInterval;
  let idleTime = 0;
  let idleLimit = 30 * 60;

  const { width } = useWindowSize();
  useEffect(() => {
    ReactGA.initialize('G-HQ3XGDV0DD');
  }, []);

  useEffect(() => {
    if (redirectUrlIfNotLogin) {
      const url = window.location.href;
      if (
        (!url.includes('autoLogin') || !url.includes('token')) &&
        !custType.isLogin
      ) {
        window.location.href = redirectUrlIfNotLogin;
      }
    }
    setIsMobile(width < 992);
  }, []);

  const getUrlCodeParam = (url: string) => {
    const searchParams = new URLSearchParams(url.split('?')[1]);
    if (url.includes('code')) {
      const code = searchParams.get('code');
      return code;
    }
  };

  const getUrlLoginTypeParam = (url: string) => {
    const searchParams = new URLSearchParams(url.split('?')[1]);
    if (url.includes('loginType')) {
      const loginType = searchParams.get('loginType');
      return loginType;
    }
  };

  const getNewUrl = (url: string) => {
    const index: number = url.indexOf('?');
    return url.substring(0, index);
  };

  const clearShoppingCart: () => void = () => {
    dispatch(cartActions.clearAll());
  };

  const getTokenInit = useCallback(async () => {
    try {
      setIsFetchToken(true);
      const response = await fetchInit();
      if (!response.status) {
        throw response.data;
      }
      setCookie('initToken', response.data.init, 1, false);
      setSaveInitToken(response.data.init);
      const limitTime = new Date().getTime() + 60 * 60 * 1000;
      setItemLs('initTokeLimit', limitTime.toString());
      setCookie('initTokeLimit', limitTime.toString(), 1, false);
      setIsFetchToken(false);
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const getPrivateKey = () => {
    dispatch(
      mainActions.fetchTimestamp({
        callback: result => {
          const newResult = !result;
          setIsShowWarn(newResult);
        },
      }),
    );
  };

  const logout = path => {
    const oauthToken = getItemLs('refreshToken');
    if (oauthToken) {
      dispatch(
        accountActions.getOauthLogout({
          refreshToken: oauthToken,
          accessToken: authUser?.accessToken,
        }),
      );
    }
    clearShoppingCart();
    clearLocalStorage();
    dispatch(accountActions.logout());
    const buIdPath = '/' + (buId || '');
    window.location.href = path === '/' ? buIdPath : buIdPath + path;
  };

  const handleCloseTimeoutPopup = () => {
    const path = custType.isSalesman
      ? '/auth/salesman/login'
      : custType.isDefinitelyB2B
      ? '/auth/b2b/login'
      : '/auth/login';
    logout(path);
  };

  const checkAuthUser = () => {
    return (
      authUser &&
      authUser.custId &&
      !window.location.href.includes('force-logout')
    );
  };

  //Run SessionStorage, LocalStorage, Cookie Migration
  useEffect(() => {
    migrateOldFormatLs();
    migrateOldFormatSs();
    migrateOldFormatCookie();

    window.document.documentElement.setAttribute('data-buid', buId);
  }, []);

  useLayoutEffect(() => {
    const request = getItemLs('timeRequest') || '';
    const timestamp = atob(request);
    const data = getItemLs('timeResponse') || '';
    if (timestamp && data) {
      const privateKey = decryptionDES(data, timestamp);
      if (privateKey) {
        setJwtKey(privateKey, timestamp);
        return;
      }
    }
    getPrivateKey();
  }, []);

  useEffect(() => {
    const currentDate = new Date().getTime();
    const numberInitTokenLimit = Number(initTokenLimit) || 0;
    if (
      (!saveInitToken ||
        countDown === 0 ||
        currentDate > numberInitTokenLimit) &&
      !isFetchToken
    ) {
      const limitTime = new Date().getTime() + TIME_LIMIT * 1000;
      setCookie('initTokeLimit', limitTime.toString(), 1, false);
      setItemLs('initTokeLimit', limitTime.toString());
      setCountDownWithDate(limitTime);
      toggleIsStart();
      getTokenInit();
    }

    if (initToken && !saveInitToken) {
      setSaveInitToken(initToken);
    }
  }, [
    initToken,
    getTokenInit,
    countDown,
    isFetchToken,
    initTokenLimit,
    saveInitToken,
  ]);

  useEffect(() => {
    const currentDate = new Date().getTime();
    const numberInitTokenLimit = Number(initTokenLimit) || 0;
    if (initTokenLimit && numberInitTokenLimit > currentDate && !isStart) {
      setCountDownWithDate(numberInitTokenLimit);
      toggleIsStart();
    }
  }, [initTokenLimit, isStart]);

  useEffect(() => {
    if (!currency && saveInitToken) {
      dispatch(mainActions.getBuParameter({ buId: buId }));
    }
    setItemLs('currency', currency);
  }, [currency, saveInitToken]);

  useEffect(() => {
    if (shoppingCartList.length === 0 && couponList.length > 0) {
      dispatch(cartActions.resetCouponList());
    }
  }, [shoppingCartList, couponList]);

  useEffect(() => {
    if (
      checkAuthUser() &&
      !custType.isSalesman &&
      memberWallet === null &&
      saveInitToken
    ) {
      const { custId, accessToken } = authUser;
      dispatch(accountActions.fetchMemberWallet({ custId, accessToken }));
    }
  }, [accountActions, authUser, dispatch, memberWallet, saveInitToken]);

  useEffect(() => {
    if (checkAuthUser() && initToken) {
      const { custId, accessToken } = authUser;
      dispatch(
        accountActions.fetchMemberProfile({
          custId,
          accessToken,
        }),
      );
    }

    if (custType.isB2B && initToken) {
      dispatch(mainActions.fetchSNameFetcher());
    }
  }, [accountActions, authUser, dispatch, initToken]);

  useEffect(() => {
    if (member.custId === 'error') {
      logout('/');
    }
  }, [member]);

  useEffect(() => {
    if (custType.isSalesman) {
      dispatch(accountActions.getCustomerList({ custId: authUser.custId }));
    }
  }, [accountActions, authUser, dispatch, saveInitToken]);

  useEffect(() => {
    const locationUrl = window.location.href;
    const code = getUrlCodeParam(locationUrl);
    const loginType = getUrlLoginTypeParam(locationUrl);
    if (code) {
      window.location.href = getNewUrl(locationUrl);
      setItemLs('oauthCode', code);
      setItemLs('ssoLoginType', loginType || '');
    }
    const oauthToken = getItemLs('oauthCode');
    const redirectUrl = getItemLs('redirectUrl');
    const ssoLoginType = getItemLs('ssoLoginType');
    if (oauthToken && !code && saveInitToken) {
      const isSalesmanType = ssoLoginType === LoginType.Employee;
      dispatch(
        authActions.login({
          account: '',
          password: '',
          accessToken: oauthToken,
          loginType: isSalesmanType ? LoginType.Employee : LoginType.B2C,
          redirectUrl: redirectUrl || '',
        }),
      );
      removeItemLs('oauthCode');
      removeItemLs('redirectUrl');
      removeItemLs('ssoLoginType');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveInitToken]);

  useEffect(() => {
    if (
      shoppingCartList.length > 0 &&
      letterTypeList === null &&
      shoppingCartList[0].data.kind !== TemplateKind.Digital &&
      saveInitToken
    ) {
      dispatch(mainActions.fetchLetterType());
    }
  }, [shoppingCartList, letterTypeList, dispatch, mainActions, saveInitToken]);

  // in discussion
  // useEffect(() => {
  //   if (!custType.isLogin) {
  //     return;
  //   }
  //   const listener = () => {
  //     idleTime = 0;
  //   };
  //   window.addEventListener('mousemove', listener, false);
  //   window.addEventListener('touchstart', listener, false);
  //   idleInterval = setInterval(() => {
  //     idleTime++;
  //     if (idleTime > idleLimit) {
  //       window.removeEventListener('mousemove', listener, false);
  //       window.removeEventListener('touchstart', listener, false);
  //       clearInterval(idleInterval);
  //       setIsShowTimeout(true);
  //     }
  //   }, 1000);

  //   return () => {
  //     window.removeEventListener('mousemove', listener, false);
  //     window.removeEventListener('touchstart', listener, false);
  //     clearInterval(idleInterval);
  //   };
  // }, [authUser]);

  const ZENDESK_KEY = '71739763-76a6-42ca-af8e-36916d75aee8';
  const ZENDESK_SETTING = {
    color: {
      theme: ZENDESK_SETTING_COLOR, // use zendesk color theme to also replace Send Button
      button: ZENDESK_SETTING_COLOR,
      header: ZENDESK_SETTING_COLOR,
      launcher: ZENDESK_SETTING_COLOR,
    },
  };
  const isHeaderAndFooterHidden = window.location.pathname === '/unlock';
  const RWDPage = {
    HOME: isMobile ? MobileHomePage : HomePage,
    GUEST: isMobile ? MobileGuestPage : GuestPage,
    ORDER_RESULT: isMobile ? MobileOrder : Order,
    FAQ: isMobile ? MobileFaqCard : FaqCard,
    QUERY_PAGE: isMobile ? MobileQueryPage : QueryPage,
    AUTH_PAGE: isMobile ? MobileAuthPage : AuthPage,
  };

  const currentUrlPathBuId = () => {
    const firstSubDirectory = window.location.pathname.split('/')?.[1] || '';
    const buIdFound = buList.find(
      e => e.buId === firstSubDirectory?.toUpperCase(),
    );

    return !!buIdFound ? buIdFound.buId : '';
  };

  return !isFetchingBuParam ? (
    <BrowserRouter basename={'/' + currentUrlPathBuId()}>
      <Helmet
        titleTemplate={`${browserTitle} - %s`}
        defaultTitle={browserTitle}
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
        <meta name="lm-login-client-id" content="EO17WeW9cVvZi1YLNIIF" />
        <meta name="lm-login-auth-url" content={locationUrl} />
        <meta name="lm-login-redirect-integration" content="true" />
        <link
          href="https://fonts.googleapis.com/css2?family=Ubuntu&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-giJF6kkoqNQ00vy+HMDP7azOuL0xtbfIcaT9wjKHr8RbDVddVHyTfAAsrekwKmP1"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://widget.upstreampay.com/2.6.0/configs/default.css"
        />
        <link rel="icon" href={`/${buId}_favicon.ico`} />
        {pspProvider === 'UpstreamPay' && (
          <script src="https://widget.upstreampay.com/2.14.0/UpStreamPay.js"></script>
        )}
        {pspConfig.ingenicoPay && pspProvider === 'ingenico' && (
          <>
            <script src={`${pspConfig.ingenicoPay?.paymentBaseUrl}`}></script>
            <script
              type="text/javascript"
              src="https://storage.googleapis.com/sp-frlm-run-nprd-cdp-static-web/sso/uat/lm-login-3.0.11.js"
            ></script>
          </>
        )}
        {pspConfig.marketPay && pspProvider === 'marketPay' && (
          <script
            data-main="payment-js"
            src={`${pspConfig.marketPay?.paymentBaseUrl}`}
          ></script>
        )}
      </Helmet>
      {!isHeaderAndFooterHidden && (
        <Navigation
          cartList={shoppingCartList}
          letterTypeList={letterTypeList ? letterTypeList : []}
          member={member}
          user={authUser}
          walletBalance={get(memberWallet, 'balance', 0)}
          isAccountFetching={isAccountFetching}
          clearShoppingCart={clearShoppingCart}
          couponList={couponList}
          isMobile={isMobile}
        />
      )}
      <ScrollToTop>
        {!isNeedFreshDesk && buId !== 'AUCHAN' && (
          <Zendesk
            className="zendesk-layout"
            defer
            zendeskKey={ZENDESK_KEY}
            onLoaded={() => {
              console.log('is loaded');
              ZendeskAPI('webWidget', 'setLocale', defaultLang);
            }}
            {...ZENDESK_SETTING}
          />
        )}
        <Switch>
          <Route path={`/auth`} component={RWDPage.AUTH_PAGE} />
          <Route path={`/force-logout`} component={ForceLogoutPage} />
          <PrivateRoute path={`/account`}>
            {isMobile ? <MobileAccountPage /> : <AccountPage />}
          </PrivateRoute>
          <Route exact path={`/`} component={RWDPage.HOME} />
          <B2BRoute exact path={`/b2b`}>
            <HomePage />
          </B2BRoute>
          <Route exact path={`/salesman`} component={HomePage} />
          <Route path={`/b2b/info`} component={B2BPage} />
          <Route path={`/b2b/buying`} component={B2BBuyingPage} />
          <Route path={`/b2b/buying-self`} component={B2BBuyingSelfPage} />
          <Route path={`/salesman/buying`} component={SalesmanBuyingPage} />
          <Route
            path={`/salesman/buying-self`}
            component={SalesmanBuyingSelfPage}
          />
          <Route path={`/buying-friend`} component={RWDPage.GUEST} />
          <Route path={`/buying-self`} component={SelfBuyingPage} />
          <Route path={`/order`} component={RWDPage.ORDER_RESULT} />
          {isNeedQuery && (
            <Route path={`/query`} component={RWDPage.QUERY_PAGE} />
          )}
          <Route path={`/faq`} component={RWDPage.FAQ} />
          <Route path={`/loading/:type`} component={PspLoading} />
          <Route path={`/unlock`} component={Unlock} />
          {moreInfoList.map(item => {
            const { innerHTML } = item;
            return (
              !!innerHTML && (
                <Route
                  key={item.name}
                  path={`/${item.href}`}
                  render={() => <FooterPagesGeneralBg __HTML={innerHTML} />}
                />
              )
            );
          })}
          <Route component={NotFoundPage} />
        </Switch>
      </ScrollToTop>
      {isShowFooterBanner &&
        !isHeaderAndFooterHidden &&
        paymentBu !== 'UAE' && <Banner />}
      {!isHeaderAndFooterHidden &&
        paymentBu !== 'UAE' &&
        (!!FooterBu ? <FooterBu /> : <Footer />)}
      {paymentBu !== 'UAE' && <p className="footer_version">Ver.{version}</p>}
      <GlobalLayer />
      <GlobalStyle />
      {isShowWarn && (
        <div className="modal-warn">
          <div
            className="modal-content d-flex flex-column align-items-center modal-container"
            onClick={e => e.stopPropagation()}
          >
            <img src={AccountUpdateProfileErrorIcon} alt="success" />
            <h3>{t(...messages.warnTitle())}</h3>
            <h5>{t(...messages.warnContent())}</h5>
            <button
              type="button"
              className="btn btn-light a-qc-block-yes-btn"
              onClick={getPrivateKey}
            >
              {t(...messages.warnConfirm())}
            </button>
            <button type="button" style={{ display: 'none' }} />
          </div>
        </div>
      )}
      {isShowTimeout && (
        <CommonPopup
          title={t(...messages.warnTitle())}
          children={
            <div className="text-center">{t(...messages.warnTimeout())}</div>
          }
          handleClosePopup={handleCloseTimeoutPopup}
          handleBtnOK={handleCloseTimeoutPopup}
          showPopup={isShowTimeout}
          imgType={PopupImgType.Error}
          mode={PopupMode.Alert}
          isError={false}
        />
      )}
    </BrowserRouter>
  ) : (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        height: '100vh',
        width: '100%',
      }}
    >
      <LoadingProgress />
    </div>
  );
}
