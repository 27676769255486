/**
 *
 * ScrollToTop
 *
 */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: any;
}

export function ScrollToTop(props: Props) {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0); // 有時候會滑不到頂 但我也不知道該怎麼辦 像是b2b upload file會只滑到一半 delay救世界
    }, 100);
  }, [pathname]);

  return <>{props.children}</>;
}
