/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  titleSuccess: () => _t(translations.Unlock.titleSuccess, 'Success, enjoy :)'),
  titleAlready: () => _t(translations.Unlock.titleAlready, 'Already unlock :)'),
  titleOops: () => _t(translations.Unlock.titleOops, 'Oops, still lock :('),
  successStatement1: () =>
    _t(
      translations.Unlock.successStatement1,
      'Your order {{orderNo}} with {{cardQty}} gift card(s) have been unlocked successfully.',
    ),
  successStatement2: () =>
    _t(
      translations.Unlock.successStatement2,
      'Beneficiaries(s) can now use his/their gift cards according terms and conditions.',
    ),
  alreadyStatement1: () =>
    _t(
      translations.Unlock.alreadyStatement1,
      'Your order {{orderNo}} with {{cardQty}} gift card(s) is already unlocked.',
    ),
  oopsStatement1: () =>
    _t(
      translations.Unlock.oopsStatement1,
      'Your order {{orderNo}} with {{cardQty}} gift card(s) cannot be unlocked.',
    ),
  oopsStatement2: () =>
    _t(
      translations.Unlock.oopsStatement2,
      'Please contact customer service to unlock your order.',
    ),
  contactUs: () => _t(translations.Unlock.contactUs, 'Contact us'),
  homePage: () => _t(translations.Unlock.homePage, 'Go to Home page'),
  confirmationTitle: () =>
    _t(
      translations.Unlock.confirmationTitle,
      'Are you sure to unlock order now?',
    ),
  confirmationText1: () =>
    _t(
      translations.Unlock.confirmationText1,
      'You are about to release your order.',
    ),
  confirmationText2: () =>
    _t(
      translations.Unlock.confirmationText2,
      'Once unlocked, your cards will be redeemable at sore or online.',
    ),
  confirmationYes: () =>
    _t(translations.Unlock.confirmationYes, 'Yes, unlock my order'),
  confirmationNo: () =>
    _t(translations.Unlock.confirmationNo, 'No, keep it locked'),
};
