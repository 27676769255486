import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.shoppingCart || initialState;

export const selectShoppingCart = createSelector([selectSlice], state => state);

export const selectShoppingCartItems = createSelector(
  [selectSlice],
  state => state.cartItems,
);

export const selectedCouponList = createSelector(
  [selectSlice],
  state => state.couponList,
);

export const selectedIsFetching = createSelector(
  [selectSlice],
  state => state.isFetching,
);

export const selectedError = createSelector(
  [selectSlice],
  state => state.error,
);

export const selectedSalesmanSetDiscount = createSelector(
  [selectSlice],
  state => state.salesmanSetDiscount,
);
