import { globalActions } from 'app/pages/GlobalLayer/slice';

const responseRegExp = /Success|Failure/;

const GLOBAL_FETCHING_ACTION = ['order/orderQuotate', 'auth/login'];

export const startFetchingMiddleware = store => next => action => {
  const actionType = action.type;
  const isAsyncAction = GLOBAL_FETCHING_ACTION.includes(actionType);
  if (isAsyncAction) {
    next(globalActions.setIsFetching(true));
  }
  return next(action);
};

export const stopFetchingMiddleware = store => next => action => {
  const actionType = action.type;
  const sourceActionType = actionType.replace(responseRegExp, '');
  const isApiResponse = responseRegExp.test(actionType);
  const isAsyncAction = GLOBAL_FETCHING_ACTION.includes(sourceActionType);
  if (isApiResponse && isAsyncAction) {
    next(globalActions.setIsFetching(false));
  }
  return next(action);
};
