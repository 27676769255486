/**
 *
 * FaqCard
 *
 */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useOrderSlice } from 'app/pages/Order/slice';
import { getBuIdFromUrl, useCookie, useLocalStorage } from 'helpers';
import {
  fetchPspConfirm,
  fetchPspConfirmV2,
  fetchPspIngenico,
  fetchPspMarketPayCapture,
} from 'app/APIs/index';
import { LoadingProgress } from 'app/components/LoadingProgress';
import { stringify } from 'qs';
import { hostname } from 'os';
import { selectAuthUser } from 'app/pages/AuthPage/slice/selectors';
import { useSelector } from 'react-redux';
interface Props {}

interface ParamTypes {
  type: string;
}
export function PspLoading(props: Props) {
  const dispatch = useDispatch();
  const { getItemLs } = useLocalStorage();
  const { actions: orderActions } = useOrderSlice();
  const [state, setState] = useState('');
  const { getCookie } = useCookie();
  const { type } = useParams<ParamTypes>();
  let history = useHistory();
  const itemCode = getItemLs('itemCode');
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search.split('?')[1]);
  const pspId = params.get('pspId');
  useEffect(() => {
    dispatch(orderActions.paymentProcessing());
    if (pspId === 'UpstreamPay') {
      setTimeout(async () => {
        const callBackUrl = `${window.location.protocol}//${
          window.location.host
        }${getBuIdFromUrl(false) ? '/' + getBuIdFromUrl(false) : ''}/loading/`;
        await fetchPspConfirm(
          getCookie('csrfToken', false),
          'UpstreamPay',
          '',
          callBackUrl,
        ).then(async fetchPGConfirmRes => {
          const token = getCookie('csrfToken', false);
          await fetchPspConfirmV2(fetchPGConfirmRes.data.redirectUrl, {
            token,
            callBackUrl,
            buId: getBuIdFromUrl(true),
          }).then(async fetchPspConfirmRes => {
            if (type === 'order') {
              history.replace({
                pathname: `/order/${fetchPspConfirmRes.data.orderNo}`,
                search: stringify({
                  paymentId:
                    fetchPspConfirmRes?.data?.pspResult?.paymentId || 'failed',
                  paymentMean:
                    fetchPspConfirmRes?.data?.pspResult?.paymentMethod ===
                    'creditcard'
                      ? 'C04'
                      : 'PP',
                }),
              });
            } else if (type === 'topup') {
              history.replace({
                pathname: `/account/cards/${itemCode}`,
                search: `?cardNumberMasked=&cardToken=${fetchPspConfirmRes?.data?.pspResult?.cardToken}&expireDate=&paymentId=${fetchPspConfirmRes?.data?.pspResult?.paymentId}&paymentType=&cardHolderName=`,
              });
            } else if (type === 'topupWallet') {
              history.replace({
                pathname: `/account/wallet`,
                search: `?cardNumberMasked=&cardToken=${fetchPspConfirmRes?.data?.pspResult?.cardToken}&expireDate=&paymentId=${fetchPspConfirmRes?.data?.pspResult?.paymentId}&paymentType=&cardHolderName=`,
              });
            }
          });
        });
      }, []);
    } else if (pspId === 'ingenico') {
      setTimeout(async () => {
        const paymentLogId = getCookie('paymentLogId', false);
        await fetchPspIngenico(paymentLogId, getBuIdFromUrl(true)).then(
          fetchPspIngenicoRes => {
            if (type === 'order') {
              history.replace({
                pathname: `/order/${fetchPspIngenicoRes?.data?.orderNo}`,
                search: stringify({
                  paymentId:
                    fetchPspIngenicoRes?.data?.pspResult?.paymentId || 'failed',
                  paymentMean: 'C04',
                }),
              });
            } else if (type === 'topup') {
              history.replace({
                pathname: `/account/cards/${itemCode}`,
                search: `?cardNumberMasked=&cardToken=${fetchPspIngenicoRes?.data?.pspResult?.paymentId}&expireDate=&paymentId=${fetchPspIngenicoRes?.data?.pspResult?.paymentId}&paymentType=&cardHolderName=`,
              });
            } else if (type === 'topupWallet') {
              history.replace({
                pathname: `/account/wallet`,
                search: `?cardNumberMasked=&cardToken=${fetchPspIngenicoRes?.data?.pspResult?.paymentId}&expireDate=&paymentId=${fetchPspIngenicoRes?.data?.pspResult?.paymentId}&paymentType=&cardHolderName=`,
              });
            }
          },
        );
      }, 0);
    } else if (pspId === 'marketPay') {
      const paymentLogId = getCookie('paymentLogId', false);
      fetchPspMarketPayCapture(paymentLogId, getBuIdFromUrl(true)).then(
        fetchPspMarketPayCaptureRes => {
          history.replace({
            pathname: `/order/${fetchPspMarketPayCaptureRes?.data?.orderNo}`,
            search: stringify({
              paymentId:
                fetchPspMarketPayCaptureRes?.data?.pspResult?.paymentId ||
                'failed',
              paymentMean: 'C04',
            }),
          });
        },
      );
    }
  }, []);
  return (
    <div className="a-gs-main d-flex flex-column align-items-center">
      <LoadingProgress isFullScreen={true} />
    </div>
  );
}
