/**
 *
 * CommonNotification
 *
 */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationType } from 'types/common';
import useCountdown from 'utils/hooks/useCountdown';
import imgError from 'img/commonNotification/imgError.svg';
import imgSuccess from 'img/commonNotification/imgSuccess.svg';
import { messages } from './messages';
import { paymentBu } from 'app/APIs/apiUrl';

const ICON_MAP = {
  [NotificationType.Success]: imgSuccess,
  [NotificationType.Error]: imgError,
};

const DEFAULT_TITLE = {
  [NotificationType.Success]: messages.successTitle,
  [NotificationType.Error]: messages.errorTitle,
};

const DEFAULT_CONTENT = {
  [NotificationType.Success]: messages.successContent,
  [NotificationType.Error]: messages.errorContent,
};

interface Props {
  isOpen?: boolean;
  type: NotificationType;
  title?: string;
  content?: string;
  handleOnClose?: () => void;
}

function CommonNotification(props: Props) {
  const { t } = useTranslation();
  const { isOpen = false, type, title, content, handleOnClose } = props;
  const { show, startCountdown, setMaxSecond, setOneSecond } = useCountdown({
    onCountdownToZero: handleOnClose,
  });
  const titleText = title ?? t(...DEFAULT_TITLE[type]());
  const contentText = content ?? t(...DEFAULT_CONTENT[type]());
  const notificationClassName = [
    'common-notification',
    show ? '' : 'common-notification--hide',
  ].join(' ');

  useEffect(() => {
    if (isOpen) {
      startCountdown(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div
      className={
        `${notificationClassName}
        ${type === NotificationType.Error &&
          paymentBu === "UAE" &&
            'common-notification-error'}`
      }
      onMouseOver={setMaxSecond}
      onMouseLeave={setOneSecond}
    >
      <img src={ICON_MAP[type]} alt="icon" />
      <div className="common-notification__text-block">
        <div className="common-notification__title">{titleText}</div>
        <div className="common-notification__content">{contentText}</div>
      </div>
    </div>
  );
}

export default CommonNotification;
