import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.salesmanBuyingSelf || initialState;

export const selectCustomerType = createSelector(
  [selectSlice],
  state => state.customerType,
);

export const selectDraftOrder = createSelector(
  [selectSlice],
  state => state.draftOrder,
);

export const selectDiscountList = createSelector(
  [selectSlice],
  state => state.discountList,
);

export const selectSalesmanBuyingSelf = createSelector(
  [selectSlice],
  state => state,
);

export const selectProduct = createSelector(
  [selectSlice],
  state => state.product,
);

export const selectIsFetching = createSelector(
  [selectSlice],
  state => state.isFetching,
);
