/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  title: () => _t(translations.B2BPage.title, 'Welcome to {{buName}} Pro'),
  section1Title: () => _t(translations.B2BPage.section1Title, 'Bienvenue chez'),
  section1SubTitle: () =>
    _t(
      translations.B2BPage.section1SubTitle,
      `It’s much more than 300+ Brands including Malls, Fashion,
  Leisure, Spas, Restaurants etc.`,
    ),
  sectionCTA: () => _t(translations.B2BPage.sectionCTA, 'Let’s start'),
  section1Link: () =>
    _t(translations.B2BPage.section1Link, 'Click to preview/download'),
  section2Title: () =>
    _t(translations.B2BPage.section2Title, 'Encore plus de choix'),
  sixThStreetTitleStart: () => _t(translations.B2BPage.sixThStreetTitleStart),
  sixThStreetTitleEnd: () => _t(translations.B2BPage.sixThStreetTitleEnd),
  sixThStreetTitleBu: () => _t(translations.B2BPage.sixThStreetTitleBu),
  section2SubTitle1: () =>
    _t(translations.B2BPage.section2SubTitle1, 'Best Discount you ever have'),
  section2Content1: () =>
    _t(
      translations.B2BPage.section2Content1,
      `Vous avez accès à des milliers de produits, pour toutes vos envies, même si ce n'est pas pour vous !`,
    ),
  section2SubTitle2: () =>
    _t(
      translations.B2BPage.section2SubTitle2,
      'Exonération des charges sociales',
    ),
  section2Content2: () =>
    _t(
      translations.B2BPage.section2Content2,
      `Un cadeau à vos employés et collaborateurs sans dépenser plus que le montant de la carte choisis (dans la limite de 171 € d'achat par employé)`,
    ),
  section2SubTitle3: () =>
    _t(
      translations.B2BPage.section2SubTitle3,
      'Valable partout, de 1 à 12 mois',
    ),
  section2Content3: () =>
    _t(
      translations.B2BPage.section2Content3,
      `Elle est utilisable en Hypermarché, Supermarché, Drive Auchan France et auchan.fr, et cela en 1 ou plusieurs fois. Le choix de la durée de validité de votre carte vous revient (mais pas plus d'un an)`,
    ),
  section2SubTitle4: () =>
    _t(translations.B2BPage.section2SubTitle4, 'Une carte sécurisée'),
  section2Content4: () =>
    _t(
      translations.B2BPage.section2Content4,
      `Votre carte est numérotée et sécurisée. Elle est aussi activée à distance (gratuitement) et on peut y faire opposition de la même manière`,
    ),
  section2CTA: () => _t(translations.B2BPage.section2CTA, 'Download file'),
  section3Title1: () =>
    _t(translations.B2BPage.section3Title1, 'Best Discount'),
  section3Title2: () =>
    _t(translations.B2BPage.section3Title2, 'you ever have'),
  section3Content: () =>
    _t(
      translations.B2BPage.section3Content,
      `Easy way to recognize & reward Employees & Customers with eGift
    Cards. Delivered instantly by Email, SMS, Physical or Print.`,
    ),
  adTitle1: () => _t(translations.B2BPage.adTitle1, 'Digital Gift Cards'),
  adTitle2: () => _t(translations.B2BPage.adTitle2, 'for Business'),
  section3GapTitle1: () =>
    _t(
      translations.B2BPage.section3GapTitle1,
      'The Benefits for your business',
    ),
  section3GapSubTitle1: () =>
    _t(
      translations.B2BPage.section3GapSubTitle1,
      'Easier and faster, online or redeem in store',
    ),
  section3GapTitle2: () =>
    _t(translations.B2BPage.section3GapTitle2, 'Order Online'),
  section3GapSubTitle2: () =>
    _t(translations.B2BPage.section3GapSubTitle2, 'eGift Cards on demand'),
  section3GapTitle3: () =>
    _t(translations.B2BPage.section3GapTitle3, 'Deliver Instantly'),
  section3GapSubTitle3: () =>
    _t(
      translations.B2BPage.section3GapSubTitle3,
      'Email, SMS, Physical or Print',
    ),
  section3GapTitle4: () =>
    _t(translations.B2BPage.section3GapTitle4, 'Pay As You Go'),
  section3GapSubTitle4: () =>
    _t(translations.B2BPage.section3GapSubTitle4, 'Prepaid or Credit Card'),
  section4Title: () =>
    _t(translations.B2BPage.section4Title, 'The Benefits for your business'),
  section4CTA: () => _t(translations.B2BPage.section4CTA, 'Get started'),
  section4TabTitle1: () =>
    _t(translations.B2BPage.section4TabTitle1, 'Giftcard'),
  section4TabTitle2: () =>
    _t(translations.B2BPage.section4TabTitle2, 'Ticketing'),
  section6Title: () =>
    _t(translations.B2BPage.section6Title, 'Produits et services'),
  view: () => _t(translations.B2BPage.view, 'View'),
  downloadFile: () => _t(translations.B2BPage.downloadFile, 'Download file'),
  section7Title: () =>
    _t(translations.B2BPage.section7Title, 'Nos meilleures ventes'),
  pickThisCard: () => _t(translations.B2BPage.pickThisCard, 'Pick this card'),
};
